import {StopSequenceModelConflictCd} from '../';
import {EnumHelper} from './enum-helper';

export class StopSequenceModelConflictCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in StopSequenceModelConflictCd ) {
      this._values.push(StopSequenceModelConflictCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): StopSequenceModelConflictCd {

    for ( const obj in StopSequenceModelConflictCd ) {
      if (StopSequenceModelConflictCd[obj] === value){
        return StopSequenceModelConflictCd[obj] as StopSequenceModelConflictCd;
      }
    }
  }
}

const StopSequenceModelConflictCdHelper = new StopSequenceModelConflictCdHelperEnumHelperClass();
export default StopSequenceModelConflictCdHelper;
