import {DisputeShpIneligibilityRsnCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeShpIneligibilityRsnCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeShpIneligibilityRsnCd ) {
      this._values.push(DisputeShpIneligibilityRsnCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeShpIneligibilityRsnCd {

    for ( const obj in DisputeShpIneligibilityRsnCd ) {
      if (DisputeShpIneligibilityRsnCd[obj] === value){
        return DisputeShpIneligibilityRsnCd[obj] as DisputeShpIneligibilityRsnCd;
      }
    }
  }
}

const DisputeShpIneligibilityRsnCdHelper = new DisputeShpIneligibilityRsnCdHelperEnumHelperClass();
export default DisputeShpIneligibilityRsnCdHelper;
