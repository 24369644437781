import {MetricThresholdContextCd} from '../';
import {EnumHelper} from './enum-helper';

export class MetricThresholdContextCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MetricThresholdContextCd ) {
      this._values.push(MetricThresholdContextCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MetricThresholdContextCd {

    for ( const obj in MetricThresholdContextCd ) {
      if (MetricThresholdContextCd[obj] === value){
        return MetricThresholdContextCd[obj] as MetricThresholdContextCd;
      }
    }
  }
}

const MetricThresholdContextCdHelper = new MetricThresholdContextCdHelperEnumHelperClass();
export default MetricThresholdContextCdHelper;
