import {ExadataDatabaseCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExadataDatabaseCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExadataDatabaseCd ) {
      this._values.push(ExadataDatabaseCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExadataDatabaseCd {

    for ( const obj in ExadataDatabaseCd ) {
      if (ExadataDatabaseCd[obj] === value){
        return ExadataDatabaseCd[obj] as ExadataDatabaseCd;
      }
    }
  }
}

const ExadataDatabaseCdHelper = new ExadataDatabaseCdHelperEnumHelperClass();
export default ExadataDatabaseCdHelper;
