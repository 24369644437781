import {InvoiceEvalOperCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceEvalOperCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceEvalOperCd ) {
      this._values.push(InvoiceEvalOperCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceEvalOperCd {

    for ( const obj in InvoiceEvalOperCd ) {
      if (InvoiceEvalOperCd[obj] === value){
        return InvoiceEvalOperCd[obj] as InvoiceEvalOperCd;
      }
    }
  }
}

const InvoiceEvalOperCdHelper = new InvoiceEvalOperCdHelperEnumHelperClass();
export default InvoiceEvalOperCdHelper;
