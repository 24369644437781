import {ShipperCreditStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipperCreditStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipperCreditStatusCd ) {
      this._values.push(ShipperCreditStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipperCreditStatusCd {

    for ( const obj in ShipperCreditStatusCd ) {
      if (ShipperCreditStatusCd[obj] === value){
        return ShipperCreditStatusCd[obj] as ShipperCreditStatusCd;
      }
    }
  }
}

const ShipperCreditStatusCdHelper = new ShipperCreditStatusCdHelperEnumHelperClass();
export default ShipperCreditStatusCdHelper;
