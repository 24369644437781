import {InvoiceAttachmentCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceAttachmentCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceAttachmentCd ) {
      this._values.push(InvoiceAttachmentCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceAttachmentCd {

    for ( const obj in InvoiceAttachmentCd ) {
      if (InvoiceAttachmentCd[obj] === value){
        return InvoiceAttachmentCd[obj] as InvoiceAttachmentCd;
      }
    }
  }
}

const InvoiceAttachmentCdHelper = new InvoiceAttachmentCdHelperEnumHelperClass();
export default InvoiceAttachmentCdHelper;
