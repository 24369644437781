import {BillStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class BillStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BillStatusCd ) {
      this._values.push(BillStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BillStatusCd {

    for ( const obj in BillStatusCd ) {
      if (BillStatusCd[obj] === value){
        return BillStatusCd[obj] as BillStatusCd;
      }
    }
  }
}

const BillStatusCdHelper = new BillStatusCdHelperEnumHelperClass();
export default BillStatusCdHelper;
