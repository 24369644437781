import {RatingCurrencyCd} from '../';
import {EnumHelper} from './enum-helper';

export class RatingCurrencyCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RatingCurrencyCd ) {
      this._values.push(RatingCurrencyCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RatingCurrencyCd {

    for ( const obj in RatingCurrencyCd ) {
      if (RatingCurrencyCd[obj] === value){
        return RatingCurrencyCd[obj] as RatingCurrencyCd;
      }
    }
  }
}

const RatingCurrencyCdHelper = new RatingCurrencyCdHelperEnumHelperClass();
export default RatingCurrencyCdHelper;
