import {PayformPaidTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PayformPaidTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PayformPaidTypeCd ) {
      this._values.push(PayformPaidTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PayformPaidTypeCd {

    for ( const obj in PayformPaidTypeCd ) {
      if (PayformPaidTypeCd[obj] === value){
        return PayformPaidTypeCd[obj] as PayformPaidTypeCd;
      }
    }
  }
}

const PayformPaidTypeCdHelper = new PayformPaidTypeCdHelperEnumHelperClass();
export default PayformPaidTypeCdHelper;
