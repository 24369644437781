import {PaymentMethodCd} from '../';
import {EnumHelper} from './enum-helper';

export class PaymentMethodCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PaymentMethodCd ) {
      this._values.push(PaymentMethodCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PaymentMethodCd {

    for ( const obj in PaymentMethodCd ) {
      if (PaymentMethodCd[obj] === value){
        return PaymentMethodCd[obj] as PaymentMethodCd;
      }
    }
  }
}

const PaymentMethodCdHelper = new PaymentMethodCdHelperEnumHelperClass();
export default PaymentMethodCdHelper;
