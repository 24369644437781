import {DisputeDrpCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeDrpCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeDrpCategoryCd ) {
      this._values.push(DisputeDrpCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeDrpCategoryCd {

    for ( const obj in DisputeDrpCategoryCd ) {
      if (DisputeDrpCategoryCd[obj] === value){
        return DisputeDrpCategoryCd[obj] as DisputeDrpCategoryCd;
      }
    }
  }
}

const DisputeDrpCategoryCdHelper = new DisputeDrpCategoryCdHelperEnumHelperClass();
export default DisputeDrpCategoryCdHelper;
