import {CubicFeetProfileTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CubicFeetProfileTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CubicFeetProfileTypeCd ) {
      this._values.push(CubicFeetProfileTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CubicFeetProfileTypeCd {

    for ( const obj in CubicFeetProfileTypeCd ) {
      if (CubicFeetProfileTypeCd[obj] === value){
        return CubicFeetProfileTypeCd[obj] as CubicFeetProfileTypeCd;
      }
    }
  }
}

const CubicFeetProfileTypeCdHelper = new CubicFeetProfileTypeCdHelperEnumHelperClass();
export default CubicFeetProfileTypeCdHelper;
