import {AgendaOwnerTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class AgendaOwnerTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AgendaOwnerTypeCd ) {
      this._values.push(AgendaOwnerTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AgendaOwnerTypeCd {

    for ( const obj in AgendaOwnerTypeCd ) {
      if (AgendaOwnerTypeCd[obj] === value){
        return AgendaOwnerTypeCd[obj] as AgendaOwnerTypeCd;
      }
    }
  }
}

const AgendaOwnerTypeCdHelper = new AgendaOwnerTypeCdHelperEnumHelperClass();
export default AgendaOwnerTypeCdHelper;
