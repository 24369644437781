import {ReturnInstrRetainCheckCd} from '../';
import {EnumHelper} from './enum-helper';

export class ReturnInstrRetainCheckCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ReturnInstrRetainCheckCd ) {
      this._values.push(ReturnInstrRetainCheckCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ReturnInstrRetainCheckCd {

    for ( const obj in ReturnInstrRetainCheckCd ) {
      if (ReturnInstrRetainCheckCd[obj] === value){
        return ReturnInstrRetainCheckCd[obj] as ReturnInstrRetainCheckCd;
      }
    }
  }
}

const ReturnInstrRetainCheckCdHelper = new ReturnInstrRetainCheckCdHelperEnumHelperClass();
export default ReturnInstrRetainCheckCdHelper;
