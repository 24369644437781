import {CustomerMatchResultTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerMatchResultTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerMatchResultTypeCd ) {
      this._values.push(CustomerMatchResultTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerMatchResultTypeCd {

    for ( const obj in CustomerMatchResultTypeCd ) {
      if (CustomerMatchResultTypeCd[obj] === value){
        return CustomerMatchResultTypeCd[obj] as CustomerMatchResultTypeCd;
      }
    }
  }
}

const CustomerMatchResultTypeCdHelper = new CustomerMatchResultTypeCdHelperEnumHelperClass();
export default CustomerMatchResultTypeCdHelper;
