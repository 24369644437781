import {ZoneIndicatorCd} from '../';
import {EnumHelper} from './enum-helper';

export class ZoneIndicatorCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ZoneIndicatorCd ) {
      this._values.push(ZoneIndicatorCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ZoneIndicatorCd {

    for ( const obj in ZoneIndicatorCd ) {
      if (ZoneIndicatorCd[obj] === value){
        return ZoneIndicatorCd[obj] as ZoneIndicatorCd;
      }
    }
  }
}

const ZoneIndicatorCdHelper = new ZoneIndicatorCdHelperEnumHelperClass();
export default ZoneIndicatorCdHelper;
