import {EquipmentStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class EquipmentStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EquipmentStatusCd ) {
      this._values.push(EquipmentStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EquipmentStatusCd {

    for ( const obj in EquipmentStatusCd ) {
      if (EquipmentStatusCd[obj] === value){
        return EquipmentStatusCd[obj] as EquipmentStatusCd;
      }
    }
  }
}

const EquipmentStatusCdHelper = new EquipmentStatusCdHelperEnumHelperClass();
export default EquipmentStatusCdHelper;
