import {ExceptionSummaryCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExceptionSummaryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExceptionSummaryCd ) {
      this._values.push(ExceptionSummaryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExceptionSummaryCd {

    for ( const obj in ExceptionSummaryCd ) {
      if (ExceptionSummaryCd[obj] === value){
        return ExceptionSummaryCd[obj] as ExceptionSummaryCd;
      }
    }
  }
}

const ExceptionSummaryCdHelper = new ExceptionSummaryCdHelperEnumHelperClass();
export default ExceptionSummaryCdHelper;
