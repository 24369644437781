
export enum ShipmentSourceCd {
	EDI = 'Edi',
	FTP = 'FTP',
	MANL_BILL_ENTRY = 'ManlBillEntry',
	OCR = 'OCR',
	PKUP_REQUEST = 'PkupRequest',
	TCON_OR_LOAD = 'TconOrLoad',
	WEB = 'Web'}

