
export enum JSPLaborRollupTypeCd {
	COST = 'Cost',
	DSR_PICKUP_AND_DELIVERY = 'DSR pickup and delivery',
	DSR_PICKUP_AND_DELIVERY_FLEX = 'DSR pickup and delivery flex',
	FULL_TIME_DOCK_WORKER = 'Full time dock worker',
	FULL_TIME_DOCK_WORKER_FLEX = 'Full time dock worker flex',
	PART_TIME_DOCK_WORKER = 'Part time dock worker',
	PART_TIME_DOCK_WORKER_FLEX = 'Part time dock worker flex'}

