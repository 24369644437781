import {JobStateTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class JobStateTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in JobStateTypeCd ) {
      this._values.push(JobStateTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): JobStateTypeCd {

    for ( const obj in JobStateTypeCd ) {
      if (JobStateTypeCd[obj] === value){
        return JobStateTypeCd[obj] as JobStateTypeCd;
      }
    }
  }
}

const JobStateTypeCdHelper = new JobStateTypeCdHelperEnumHelperClass();
export default JobStateTypeCdHelper;
