
export enum MetricValueKeyCd {
	DELIVERY_COST = 'DeliveryCost',
	DELIVERY_COST_PER_CWT = 'DeliveryCostPerCwt',
	DELIVERY_COST_PER_TRIP = 'DeliveryCostPerTrip',
	DELIVERY_MILES = 'DeliveryMiles',
	DELIVERY_MILES_PER_STOP = 'DeliveryMilesPerStop',
	DELIVERY_MOTOR_MOVES = 'DeliveryMotorMoves',
	DELIVERY_PERCENT_BY_1400 = 'DeliveryPercentBy1400',
	DELIVERY_PERCENT_BY_NOON = 'DeliveryPercentByNoon',
	DELIVERY_PPMH = 'DeliveryPpmh',
	DELIVERY_SERVICE_RISK_SHIPMENTS = 'DeliveryServiceRiskShipments',
	DELIVERY_STOPS_PER_HOUR = 'DeliveryStopsPerHour',
	DELIVERY_STOPS_PER_TRIP = 'DeliveryStopsPerTrip',
	DELIVERY_WEIGHT_PER_TRIP = 'DeliveryWeightPerTrip',
	LOAD_AVERAGE = 'LoadAverage',
	TOTAL_BILLS = 'TotalBills',
	TOTAL_HOURS = 'TotalHours',
	TOTAL_ROUTES = 'TotalRoutes',
	TOTAL_STOPS = 'TotalStops'}

