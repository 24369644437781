import {CheckpointMasterActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class CheckpointMasterActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CheckpointMasterActionCd ) {
      this._values.push(CheckpointMasterActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CheckpointMasterActionCd {

    for ( const obj in CheckpointMasterActionCd ) {
      if (CheckpointMasterActionCd[obj] === value){
        return CheckpointMasterActionCd[obj] as CheckpointMasterActionCd;
      }
    }
  }
}

const CheckpointMasterActionCdHelper = new CheckpointMasterActionCdHelperEnumHelperClass();
export default CheckpointMasterActionCdHelper;
