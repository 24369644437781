import {DynamicPriceLTLBillStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class DynamicPriceLTLBillStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DynamicPriceLTLBillStatusCd ) {
      this._values.push(DynamicPriceLTLBillStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DynamicPriceLTLBillStatusCd {

    for ( const obj in DynamicPriceLTLBillStatusCd ) {
      if (DynamicPriceLTLBillStatusCd[obj] === value){
        return DynamicPriceLTLBillStatusCd[obj] as DynamicPriceLTLBillStatusCd;
      }
    }
  }
}

const DynamicPriceLTLBillStatusCdHelper = new DynamicPriceLTLBillStatusCdHelperEnumHelperClass();
export default DynamicPriceLTLBillStatusCdHelper;
