import {RequestedChangeItemActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class RequestedChangeItemActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RequestedChangeItemActionCd ) {
      this._values.push(RequestedChangeItemActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RequestedChangeItemActionCd {

    for ( const obj in RequestedChangeItemActionCd ) {
      if (RequestedChangeItemActionCd[obj] === value){
        return RequestedChangeItemActionCd[obj] as RequestedChangeItemActionCd;
      }
    }
  }
}

const RequestedChangeItemActionCdHelper = new RequestedChangeItemActionCdHelperEnumHelperClass();
export default RequestedChangeItemActionCdHelper;
