
export enum RebuttalInternalStatusCd {
	APPROVED = 'Approved',
	CEO_PENDING_APPROVAL = 'CeoPendingApproval',
	CUSTOMER_CANCELLED = 'CustomerCancelled',
	DECLINED = 'Declined',
	DIRECTOR_PENDING_APPROVAL = 'DirectorPendingApproval',
	EXAMINER_PENDING_APPROVAL = 'ExaminerPendingApproval',
	MANAGER_PENDING_APPROVAL = 'ManagerPendingApproval',
	PRESIDENT_PENDING_APPROVAL = 'PresidentPendingApproval',
	REBUTTAL_RECEIVED = 'RebuttalReceived',
	RVP_PENDING_APPROVAL = 'RVPPendingApproval',
	RVP_SALES_PENDING_APPROVAL = 'RVPSalesPendingApproval',
	SVP_OF_OPS_PENDING_APPROVAL = 'SVPOfOpsPendingApproval',
	SVP_SALES_PENDING_APPROVAL = 'SVPSalesPendingApproval',
	UNDER_REVIEW = 'UnderReview'}

