import {ModuleExecutorParmsArgTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ModuleExecutorParmsArgTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ModuleExecutorParmsArgTypeCd ) {
      this._values.push(ModuleExecutorParmsArgTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ModuleExecutorParmsArgTypeCd {

    for ( const obj in ModuleExecutorParmsArgTypeCd ) {
      if (ModuleExecutorParmsArgTypeCd[obj] === value){
        return ModuleExecutorParmsArgTypeCd[obj] as ModuleExecutorParmsArgTypeCd;
      }
    }
  }
}

const ModuleExecutorParmsArgTypeCdHelper = new ModuleExecutorParmsArgTypeCdHelperEnumHelperClass();
export default ModuleExecutorParmsArgTypeCdHelper;
