import {InvoiceTransmissionFormatCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceTransmissionFormatCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceTransmissionFormatCd ) {
      this._values.push(InvoiceTransmissionFormatCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceTransmissionFormatCd {

    for ( const obj in InvoiceTransmissionFormatCd ) {
      if (InvoiceTransmissionFormatCd[obj] === value){
        return InvoiceTransmissionFormatCd[obj] as InvoiceTransmissionFormatCd;
      }
    }
  }
}

const InvoiceTransmissionFormatCdHelper = new InvoiceTransmissionFormatCdHelperEnumHelperClass();
export default InvoiceTransmissionFormatCdHelper;
