import {PickupTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PickupTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PickupTypeCd ) {
      this._values.push(PickupTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PickupTypeCd {

    for ( const obj in PickupTypeCd ) {
      if (PickupTypeCd[obj] === value){
        return PickupTypeCd[obj] as PickupTypeCd;
      }
    }
  }
}

const PickupTypeCdHelper = new PickupTypeCdHelperEnumHelperClass();
export default PickupTypeCdHelper;
