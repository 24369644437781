
export enum ClaimInternalStatusCd {
	APPROVED = 'Approved',
	CEO_PENDING_APPROVAL = 'CeoPendingApproval',
	CUSTOMER_CANCELLED = 'CustomerCancelled',
	DECLINED = 'Declined',
	DIRECTOR_PENDING_APPROVAL = 'DirectorPendingApproval',
	MANAGER_PENDING_APPROVAL = 'ManagerPendingApproval',
	PRESIDENT_PENDING_APPROVAL = 'PresidentPendingApproval',
	RVP_PENDING_APPROVAL = 'RVPPendingApproval',
	RVP_SALES_PENDING_APPROVAL = 'RVPSalesPendingApproval',
	SUBMITTED = 'Submitted',
	SVP_OF_OPS_PENDING_APPROVAL = 'SVPOfOpsPendingApproval',
	SVP_SALES_PENDING_APPROVAL = 'SVPSalesPendingApproval',
	UNDER_REVIEW = 'UnderReview',
	UNSUBMITTED = 'Unsubmitted'}

