import {ShipmentDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentDetailCd ) {
      this._values.push(ShipmentDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentDetailCd {

    for ( const obj in ShipmentDetailCd ) {
      if (ShipmentDetailCd[obj] === value){
        return ShipmentDetailCd[obj] as ShipmentDetailCd;
      }
    }
  }
}

const ShipmentDetailCdHelper = new ShipmentDetailCdHelperEnumHelperClass();
export default ShipmentDetailCdHelper;
