import {ParkingLocationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ParkingLocationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ParkingLocationTypeCd ) {
      this._values.push(ParkingLocationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ParkingLocationTypeCd {

    for ( const obj in ParkingLocationTypeCd ) {
      if (ParkingLocationTypeCd[obj] === value){
        return ParkingLocationTypeCd[obj] as ParkingLocationTypeCd;
      }
    }
  }
}

const ParkingLocationTypeCdHelper = new ParkingLocationTypeCdHelperEnumHelperClass();
export default ParkingLocationTypeCdHelper;
