import {PayformActivityLocCd} from '../';
import {EnumHelper} from './enum-helper';

export class PayformActivityLocCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PayformActivityLocCd ) {
      this._values.push(PayformActivityLocCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PayformActivityLocCd {

    for ( const obj in PayformActivityLocCd ) {
      if (PayformActivityLocCd[obj] === value){
        return PayformActivityLocCd[obj] as PayformActivityLocCd;
      }
    }
  }
}

const PayformActivityLocCdHelper = new PayformActivityLocCdHelperEnumHelperClass();
export default PayformActivityLocCdHelper;
