import {StrandReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class StrandReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in StrandReasonCd ) {
      this._values.push(StrandReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): StrandReasonCd {

    for ( const obj in StrandReasonCd ) {
      if (StrandReasonCd[obj] === value){
        return StrandReasonCd[obj] as StrandReasonCd;
      }
    }
  }
}

const StrandReasonCdHelper = new StrandReasonCdHelperEnumHelperClass();
export default StrandReasonCdHelper;
