import {ExpectationOwnerTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExpectationOwnerTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExpectationOwnerTypeCd ) {
      this._values.push(ExpectationOwnerTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExpectationOwnerTypeCd {

    for ( const obj in ExpectationOwnerTypeCd ) {
      if (ExpectationOwnerTypeCd[obj] === value){
        return ExpectationOwnerTypeCd[obj] as ExpectationOwnerTypeCd;
      }
    }
  }
}

const ExpectationOwnerTypeCdHelper = new ExpectationOwnerTypeCdHelperEnumHelperClass();
export default ExpectationOwnerTypeCdHelper;
