import {OptimizerResultStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class OptimizerResultStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in OptimizerResultStatusCd ) {
      this._values.push(OptimizerResultStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): OptimizerResultStatusCd {

    for ( const obj in OptimizerResultStatusCd ) {
      if (OptimizerResultStatusCd[obj] === value){
        return OptimizerResultStatusCd[obj] as OptimizerResultStatusCd;
      }
    }
  }
}

const OptimizerResultStatusCdHelper = new OptimizerResultStatusCdHelperEnumHelperClass();
export default OptimizerResultStatusCdHelper;
