import {ProStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProStatusCd ) {
      this._values.push(ProStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProStatusCd {

    for ( const obj in ProStatusCd ) {
      if (ProStatusCd[obj] === value){
        return ProStatusCd[obj] as ProStatusCd;
      }
    }
  }
}

const ProStatusCdHelper = new ProStatusCdHelperEnumHelperClass();
export default ProStatusCdHelper;
