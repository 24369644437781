import {InstructionStatCd} from '../';
import {EnumHelper} from './enum-helper';

export class InstructionStatCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InstructionStatCd ) {
      this._values.push(InstructionStatCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InstructionStatCd {

    for ( const obj in InstructionStatCd ) {
      if (InstructionStatCd[obj] === value){
        return InstructionStatCd[obj] as InstructionStatCd;
      }
    }
  }
}

const InstructionStatCdHelper = new InstructionStatCdHelperEnumHelperClass();
export default InstructionStatCdHelper;
