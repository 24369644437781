import {PayformStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class PayformStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PayformStatusCd ) {
      this._values.push(PayformStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PayformStatusCd {

    for ( const obj in PayformStatusCd ) {
      if (PayformStatusCd[obj] === value){
        return PayformStatusCd[obj] as PayformStatusCd;
      }
    }
  }
}

const PayformStatusCdHelper = new PayformStatusCdHelperEnumHelperClass();
export default PayformStatusCdHelper;
