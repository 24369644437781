import {OptimizerResultReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class OptimizerResultReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in OptimizerResultReasonCd ) {
      this._values.push(OptimizerResultReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): OptimizerResultReasonCd {

    for ( const obj in OptimizerResultReasonCd ) {
      if (OptimizerResultReasonCd[obj] === value){
        return OptimizerResultReasonCd[obj] as OptimizerResultReasonCd;
      }
    }
  }
}

const OptimizerResultReasonCdHelper = new OptimizerResultReasonCdHelperEnumHelperClass();
export default OptimizerResultReasonCdHelper;
