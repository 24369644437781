import {DisputeEmailTemplateTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeEmailTemplateTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeEmailTemplateTypeCd ) {
      this._values.push(DisputeEmailTemplateTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeEmailTemplateTypeCd {

    for ( const obj in DisputeEmailTemplateTypeCd ) {
      if (DisputeEmailTemplateTypeCd[obj] === value){
        return DisputeEmailTemplateTypeCd[obj] as DisputeEmailTemplateTypeCd;
      }
    }
  }
}

const DisputeEmailTemplateTypeCdHelper = new DisputeEmailTemplateTypeCdHelperEnumHelperClass();
export default DisputeEmailTemplateTypeCdHelper;
