import {InvoicePreferenceCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoicePreferenceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoicePreferenceCd ) {
      this._values.push(InvoicePreferenceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoicePreferenceCd {

    for ( const obj in InvoicePreferenceCd ) {
      if (InvoicePreferenceCd[obj] === value){
        return InvoicePreferenceCd[obj] as InvoicePreferenceCd;
      }
    }
  }
}

const InvoicePreferenceCdHelper = new InvoicePreferenceCdHelperEnumHelperClass();
export default InvoicePreferenceCdHelper;
