import {DisputeShipmentCurrencyCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeShipmentCurrencyCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeShipmentCurrencyCd ) {
      this._values.push(DisputeShipmentCurrencyCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeShipmentCurrencyCd {

    for ( const obj in DisputeShipmentCurrencyCd ) {
      if (DisputeShipmentCurrencyCd[obj] === value){
        return DisputeShipmentCurrencyCd[obj] as DisputeShipmentCurrencyCd;
      }
    }
  }
}

const DisputeShipmentCurrencyCdHelper = new DisputeShipmentCurrencyCdHelperEnumHelperClass();
export default DisputeShipmentCurrencyCdHelper;
