import {OptimizerStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class OptimizerStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in OptimizerStatusCd ) {
      this._values.push(OptimizerStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): OptimizerStatusCd {

    for ( const obj in OptimizerStatusCd ) {
      if (OptimizerStatusCd[obj] === value){
        return OptimizerStatusCd[obj] as OptimizerStatusCd;
      }
    }
  }
}

const OptimizerStatusCdHelper = new OptimizerStatusCdHelperEnumHelperClass();
export default OptimizerStatusCdHelper;
