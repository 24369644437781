import {MiscLineItemPaymentMethodCd} from '../';
import {EnumHelper} from './enum-helper';

export class MiscLineItemPaymentMethodCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MiscLineItemPaymentMethodCd ) {
      this._values.push(MiscLineItemPaymentMethodCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MiscLineItemPaymentMethodCd {

    for ( const obj in MiscLineItemPaymentMethodCd ) {
      if (MiscLineItemPaymentMethodCd[obj] === value){
        return MiscLineItemPaymentMethodCd[obj] as MiscLineItemPaymentMethodCd;
      }
    }
  }
}

const MiscLineItemPaymentMethodCdHelper = new MiscLineItemPaymentMethodCdHelperEnumHelperClass();
export default MiscLineItemPaymentMethodCdHelper;
