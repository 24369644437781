/**
 * Common utility functions
 * Version: 1.0
 * Build: Manual
 */
import {
	HttpMethod,
	JobStateTypeCd,
	ActionCd,
	StopWindowCd,
	ShipmentSpecialServiceCd,
	PickupSourceCd,
	PickupStatusCd,
	ShipperCountryCd,
	ShipperPhoneCountryCd,
	SpecialEquipmentCd,
	EarlyCutoffCd,
	ShipperCreditStatusCd,
	PickupTypeCd,
	RschCnclReasonCd,
	StrandReasonCd,
	ChargeToCd,
	EquipmentTypeCd,
	InvoiceCurrencyCd,
	RateDiscountSourceCd,
	PricingRulesetOwnerCd,
	RateRequestTypeCd,
	RateRequestSubTypeCd,
	RatingCurrencyCd
} from './enum'
export class Utils {
	/**
	 * Reviver for JSON.parse
	 * - Converts dates & timestamps in API response to Date type
	 * - Converts true/false to boolean
	 * Usage: shipment = JSON.parse(apiResponse, Utils.reviver);
	 */
    public static reviver(key, value): any {
        if (typeof key === "string") {
            if ((<string>key).match(".*Timestamp|.*Date")) {
                return new Date(parseInt(value.toString()));
            }
        }

        if (typeof value === "string") {
            if ((<string>value).toLowerCase().match("true")) {
                return true;
            } else if ((<string>value).toLowerCase().match("false")) {
                return false;
            }
        }
       return value;
    }
}

export class FileContents {
	contentType: undefined;
	fileName: undefined;
	contents: Uint8Array;
}

export class Envelope<T> {
	/**
	 * The HTTP code for this response. E.g. 200 for Ok, 500 for Internal server error.
	 */
	code:string = undefined;
	/**
	 * Contains the date and time of when this transaction occured. Pass this in the header of PUT and DELETE requests to enable concurrent update checks.
	 */
	transactionTimestamp:Date = undefined;
	/**
	 * Contains the data in this response.
	 */
	data:T = undefined;
	/**
	 * Contains file attachments.
	 */
	attachment:File[] = undefined;
	/**
	 * Contains the error details if there was any failure
	 */
	error:FaultResponse = undefined;
	/**
	 * Contains links to resources
	 */
	link:HyperMedia[] = undefined;
}


/**
 * Contains identifying information for a customer account
 */
export class AcctId {
	acctInstId:string = undefined;
	acctMadCd:string = undefined;
	/**
	 * The name of the customer at the detail account level
	 */
	acctName:string = undefined;
	/**
	 * roles for this account. p2D, billTo, corp, supplier, pay agent
	 */
	acctPartyRole:string = undefined;
	/**
	 * Indicates the current state of the customer's credit. It may include bad debt, credit, non-credit, PCO control, voluntary, cash only or non-rated
	 */
	creditStatCd:string = undefined;
	/**
	 * Indicates if the Account is a national account. True or false
	 */
	nationalAccountInd:boolean = undefined;
	/**
	 * unique id of the parent customer account
	 */
	parentAcctInstId:string = undefined;
}
/**
 * Contains identifying information for a customer account
 */
export class AcctIdBasic {
	acctInstId:string = undefined;
	acctMadCd:string = undefined;
	/**
	 * The name of the customer at the detail account level
	 */
	acctName:string = undefined;
}
export class Address {
	/**
	 * When there is a list of addresses, the type will be used to communicate what type of address it is - for example: home, work, headquarters, admin offices, etc., billing. If there is just one, then this field may be empty
	 */
	addressTypeCd:string = undefined;
	/**
	 * Primary name
	 */
	name:string = undefined;
	/**
	 * Applies to mailing address of a person, where the address is actually in another entity's name.
	 */
	careOfName:string = undefined;
	/**
	 * Address line that includes Street name, direction and optionally building number, unit# etc. 
	 * e.g. 1000 SW Broadway st
	 */
	addressLine1:string = undefined;
	/**
	 * Address line that includes Street name, direction and optionally building number, unit# etc. 
	 * e.g. 1000 SW Broadway st
	 */
	addressLine2:string = undefined;
	/**
	 * PO Box typically applies to a mailing address. This is the standard PO Box# of an address.
	 */
	postOfficeBox:string = undefined;
	/**
	 * City Name part of the address.
	 */
	cityName:string = undefined;
	/**
	 * Standard Postal abbreviation for state name. Example: OR for Oregon.
	 */
	stateCd:string = undefined;
	/**
	 * standard Postal abbreviation for a country. Example: CA for Canada.
	 */
	countryCd:string = undefined;
	/**
	 * Postal Code are aka Zip code in USA. US and Mexico use 5 digit postal codes. Canada uses 6 char postal codes.
	 */
	postalCd:string = undefined;
	/**
	 * This is a 4 digit extension to US 5 digit zip code.
	 */
	usZip4:string = undefined;
}
export class Area {
	area:number = undefined;
	areaUom:string = undefined;
}
/**
 * To be used as type for attaching files.
 */
export class Attachment {
	/**
	 * Name of the file.
	 */
	fileName:string = undefined;
	/**
	 * Mime type representing the contents of the file.
	 */
	contentType:string = undefined;
	/**
	 * Base 64 Formatted data.
	 */
	base64Data:string = undefined;
}
export class AuditInfo {
	createdById:string = undefined;
	createdTimestamp:Date = undefined;
	updateById:string = undefined;
	updatedTimestamp:Date = undefined;
	createByPgmId:string = undefined;
	updateByPgmId:string = undefined;
	correlationId:string = undefined;
}
export class Charge {
	/**
	 * linehaul, accessorial, tax, discount, cod, cash ppd, cash coll, part ppd, part coll
	 */
	chargeTypeCd:string = undefined;
	/**
	 * sequence of charge. This is used for accessorials and miscellaneous line items
	 */
	seq:number = undefined;
	/**
	 * a descriptive code identifying the accessorial, tax type, discount, etc. For chargeType codes that have sub codes, this will be used and include the description for them. For example - NotifyOnArrival.
	 */
	chargeCd:string = undefined;
	/**
	 * Expanded Name :  Charge Amount                
	 * 																																														
	 * Desc : The dollar amount of the charge for the
	 * commodity.
	 */
	chargeAmt:MonetaryAmount = undefined;
	/**
	 * Expanded Name : Minimum charge indicator           
	 * 																																																			
	 * Desc : Indicates if the charges associated with the
	 * line are the minimum charges for the commodity.
	 */
	minChargeInd:boolean = undefined;
	/**
	 * True if the charge associated with the accessorial line is the maximum charge
	 */
	maxChargeInd:boolean = undefined;
	/**
	 * The method to calculate the charges including exchangeRate, percentage, flat rate, flat fees, etc.
	 */
	calcMethod:PaymentCalcMethod = undefined;
	/**
	 * A code indicating which party or parties get the revenue from the accessorial - PayToAdvance, PayToPrimary, SplitBetweenAll, SplitAdvancePrimaryOnly, SplitPrimaryBeyondOnly
	 */
	responsibleParty:string = undefined;
	/**
	 * Textual description of charge other than a standard description represented by the description in ChargeTypeCd
	 */
	desc:string = undefined;
	reference:ReferenceNumber = undefined;
}
/**
 * Use this type to represent the delta list of fields that have been changed either as a before list or after list.
 */
export class Code {
	/**
	 * unique DB identifier of the Pricing Code
	 */
	pricingCodeId:string = undefined;
	/**
	 * highest grouping qualifier
	 */
	category:string = undefined;
	/**
	 * grouping wthin category
	 */
	subCategory:string = undefined;
	/**
	 * The string used to identify a code
	 */
	code:string = undefined;
	/**
	 * The description of the code
	 */
	name:string = undefined;
	/**
	 * Optionally a value for this code.
	 */
	value:string = undefined;
	/**
	 * An ordinal for displaying this code on a drop down list.
	 */
	displayOrder:number = undefined;
	/**
	 * effective date of the record
	 */
	effDate:Date = undefined;
	/**
	 * expiry date of the record
	 */
	expDate:Date = undefined;
}
export class Comment {
	/**
	 * Operational Alert, Special Handling Alert, Do not contact
	 */
	commentTypeCd:string = undefined;
	/**
	 * sub-category of comment
	 */
	commentSubTypeCd:string = undefined;
	/**
	 * The text of the comment.
	 */
	note:string = undefined;
	/**
	 * The identity of the entity that entered the comment. This could be an employee id, customer id or a name.
	 */
	enteredBy:string = undefined;
	/**
	 * The timestamp when the comment was created
	 */
	dateTime:Date = undefined;
}
export class CompanyLocation {
	locFullName:string = undefined;
	locAbbrName:string = undefined;
	/**
	 * Standard 3 character timezone - PST PDT, etc.
	 */
	locTimezone:string = undefined;
	locOpenTime:Date = undefined;
	locCloseTime:Date = undefined;
	/**
	 * Hours, minutes, days
	 */
	locationInfo:Location = undefined;
	locContactInfo:ContactInfo[] = undefined;
	locPhone:Phone[] = undefined;
}
/**
 * *** Needs Review The contact information including email, phone and preferences
 */
export class ContactInfo {
	/**
	 * In many cases, the ContactInfoType seems to be all that is needed within transactions. For customer contacts, however, it seems that the ContactType is needed which has more person information. This field will be populated when the ContactInfo type is used as the contact reference instead of the ContactType which has full person information. If the full ContactType is present, then this field need not be populated.
	 */
	contactFirstName:string = undefined;
	contactLastName:string = undefined;
	/**
	 * The title of the contact
	 */
	contactTitle:string = undefined;
	/**
	 * Email Address to communicate with the contact
	 */
	emailAddress:Email[] = undefined;
	/**
	 * Full telephone number with country code, extension etc. Used to communicate with the contact.
	 */
	telephone:Phone[] = undefined;
	/**
	 * IM addresses - Google chat, Microsoft, Chatter, etc.
	 */
	chatIMAddress:NameValue[] = undefined;
	/**
	 * ***Needs review - Does the preference need to have a way of saying - contact me this way for this purpose and this other way for another purpose...? ***The preferred contact method to use - for example email vs. phone. The name in the name value pair should hold the mode and the value, the specific instance within that mode. For example - email/work or phone/mobile or chat/Google
	 */
	contactModePreference:NameValue[] = undefined;
	/**
	 * Web URL. Typically applies to where a contact is a non-person entity such as business.
	 */
	contactWebSiteURL:NameValue = undefined;
}
/**
 * Basic Contact Information including name , email and phone
 */
export class ContactInfoBasic {
	/**
	 * Company Name of the Contact Person
	 */
	companyName:string = undefined;
	/**
	 * Contact email address
	 */
	email:Email = undefined;
	/**
	 * Full Name
	 */
	fullName:string = undefined;
	/**
	 * Contact Phone Number
	 */
	phone:Phone = undefined;
}
/**
 * Information for reporting record counts
 */
export class CountInfo {
	/**
	 * name for data to be counted
	 */
	name:string = undefined;
	/**
	 * description of the data
	 */
	description:string = undefined;
	/**
	 * count for the data
	 */
	count:number = undefined;
}
export class DataValidationError {
	/**
	 * Data validation error code
	 */
	errorCd:string = undefined;
	/**
	 * The message describing the validation error
	 */
	message:string = undefined;
	/**
	 * The name of the parameter or body field that is in error
	 */
	fieldName:string = undefined;
	/**
	 * The value of the parameter or body field that is in error
	 */
	fieldValue:string = undefined;
	/**
	 * Any additional information that will help in troubleshooting this fault. E.g. include any context information such as requesting application, user details, any identifying details from the API request such as customer number, PRO number etc.
	 */
	moreInfo:MoreInfo[] = undefined;
}
export class Density {
	density:number = undefined;
	densityUom:string = undefined;
}
/**
 * Information about the Transit time between origin and destination postal codes
 */
export class TransitTime {
	/**
	 * Destination State Code
	 */
	destStateCd:string = undefined;
	/**
	 * Destination Postal Code
	 */
	destPostalCd:string = undefined;
	/**
	 * Destination service center that is delivering the freight
	 */
	destSicCd:string = undefined;
	/**
	 * Delivery date derived from requested pickup date and transit days
	 */
	estdDlvrDate:Date = undefined;
	/**
	 * If true then guaranteed service is available in this lane
	 */
	garntInd:boolean = undefined;
	/**
	 * Latest date by which the freight should be picked up in order to meet the requested delivery date
	 */
	latestPkupDate:Date = undefined;
	/**
	 * Origin Postal Cd
	 */
	origPostalCd:string = undefined;
	/**
	 * Origin state code
	 */
	origStateCd:string = undefined;
	/**
	 * Origin service center that is picking up the freight
	 */
	origSicCd:string = undefined;
	/**
	 * Delivery Date as requested by customer
	 */
	requestedDlvrDate:Date = undefined;
	/**
	 * Customer requested pickup date. If not provided then defaults to todays date
	 */
	requestedPkupDate:Date = undefined;
	/**
	 * standard service transit (business) days(excluding weekend and holidays) to move from origin to destination.
	 */
	transitDays:number = undefined;
	/**
	 * Earliest date by which the freight should be picked up in order to meet the requested delivery date
	 */
	earliestPkupDate:Date = undefined;
	/**
	 * Notes about the transit time
	 */
	note:string = undefined;
	/**
	 * Set to True if the Requested pickup date is holiday or non-working day
	 */
	isPkupDateHoliday:boolean = undefined;
	/**
	 * Set to True if the Requested Delivery date is holiday or non-working day
	 */
	isrqstdDeliveryDateHoliday:boolean = undefined;
}
export class Dimensions {
	length:number = undefined;
	width:number = undefined;
	height:number = undefined;
	dimensionsUom:string = undefined;
}
export class Distance {
	distance:number = undefined;
	/**
	 * miles or kilometers
	 */
	distanceUOM:string = undefined;
}
/**
 * Information about the supporting documents
 */
export class Document {
	/**
	 * Indicates if the document is restricted or not
	 */
	restrictedInd:boolean = undefined;
	/**
	 * Type of Document
	 */
	docTypeCd:string = undefined;
	/**
	 * File Name of the document
	 */
	docFile:string = undefined;
	/**
	 * URL Of the document in the DMS
	 */
	docUrl:string = undefined;
	/**
	 * Document folder name in DMS
	 */
	docFolder:string = undefined;
	/**
	 * Name of the document
	 */
	docName:string = undefined;
	/**
	 * content type of the document - pdf, or text or csv, etc
	 */
	contentType:string = undefined;
	/**
	 * size of document stored in DMS
	 */
	docLength:number = undefined;
	/**
	 * create date of document in DMS
	 */
	createdDate:Date = undefined;
	/**
	 * update date of document in DMS
	 */
	modifiedDate:Date = undefined;
	/**
	 * name of the author of the document.
	 */
	docAuthor:string = undefined;
}
/**
 * Information about sending email messages
 */
export class ElectronicMessage {
	/**
	 * bcc'd list of recipients email addresses separated by ;
	 */
	bcc:string = undefined;
	/**
	 * cc'd list of recipients email addresses separated by ;
	 */
	cc:string = undefined;
	/**
	 * sender email address
	 */
	from:string = undefined;
	/**
	 * Name of the sender of the message
	 */
	fromName:string = undefined;
	/**
	 * Email message body
	 */
	messageBody:string = undefined;
	/**
	 * priority of the message
	 * Low, Medium, High
	 */
	priority:string = undefined;
	/**
	 * Timestamp when the message was sent.
	 */
	sentDateTime:Date = undefined;
	/**
	 * subject of email message
	 */
	subject:string = undefined;
	/**
	 * recipients email address separated by ;
	 */
	to:string = undefined;
}
export class Email {
	emailAddr:string = undefined;
	emailTypeCd:string = undefined;
}
/**
 * Identification info for equipment
 */
export class EquipmentId {
	equipmentInstId:string = undefined;
	/**
	 * Type of equipment - Tractor, Trailer, Dolly, Straight Truck
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * Prefix for the equipment number
	 */
	equipmentPrefix:string = undefined;
	/**
	 * Sequencing or serial part of an equipment unit's identifying number
	 */
	equipmentSuffix:string = undefined;
}
/**
 * Event Hdr Info
 */
export class EventHdr {
	/**
	 * Internal identifier for the event
	 */
	eventInstId:string = undefined;
	/**
	 * Sequence number of the event for a Pro
	 */
	seq:number = undefined;
	/**
	 * Identifies type of event that happened for a shipment
	 */
	eventTypeCd:string = undefined;
	/**
	 * Description  of the event that can be used for display or more information
	 */
	eventDesc:string = undefined;
	/**
	 * Time that the event occurred
	 */
	eventTmst:Date = undefined;
	/**
	 * This event has exception information
	 */
	isException:boolean = undefined;
}
export class ExchangeRateInfo {
	/**
	 * Currency Code in standard format e.g. USD. The exchange rate is to convert from this currentcy.
	 */
	fromCrncyCd:string = undefined;
	/**
	 * Currency Code in standard format e.g. USD. The exchange rate is to convert to this currentcy.
	 */
	toCrncyCd:string = undefined;
	/**
	 * Currency code that is the  basis for both the from and to conversions. For example - from SEK to USD and from USD to EUR
	 */
	baseCrncyCd:string = undefined;
	/**
	 * The exchange rate to convert amount given in fromCrncyCd to toCrncyCd.
	 */
	exchRate:number = undefined;
	/**
	 * The dates on which this exchange rate is effective.
	 */
	exchDate:Date = undefined;
	/**
	 * source of exchange rate
	 */
	src:string = undefined;
}
export class Fault {
	/**
	 * The HTTP error code for this fault. E.g. 404 for a Not Found condition
	 */
	code:string = undefined;
	/**
	 * Internal error code
	 */
	errorCd:string = undefined;
	/**
	 * The message describing the fault
	 */
	message:string = undefined;
	/**
	 * Program trace information to help troubleshoot this fault E.g. The Java Stack trace from the exception.
	 */
	trace:string = undefined;
	/**
	 * Any additional information that will help in troubleshooting this fault. E.g. include any context information such as requesting application, user details, any identifying details from the API request suchs customer number, PRO number etc.
	 */
	moreInfo:string = undefined;
}
/**
 * Information about a field for mapping records from file to DB columns
 */
export class FieldInfo {
	/**
	 * name of the field
	 */
	name:string = undefined;
	/**
	 * description of the field
	 */
	description:string = undefined;
	/**
	 * column number of the field
	 */
	columnNbr:number = undefined;
}
/**
 * Includes details about a field that was modified.
 */
export class FieldValueChange {
	/**
	 * The name of the field that was changed.
	 */
	fieldName:string = undefined;
	/**
	 * The previous value of the field.
	 */
	previousValue:string = undefined;
	/**
	 * The new value of the field.
	 */
	newValue:string = undefined;
	/**
	 * The timestamp for when the change occurred.
	 */
	modificationDateTime:Date = undefined;
	/**
	 * The username of the user who made the change.
	 */
	modifiedBy:string = undefined;
}
/**
 * To be used as type for uploading/downloading files.
 */
export class File {
	/**
	 * Name of the file.
	 */
	fileName:string = undefined;
	/**
	 * Mime type representing the contents of the file.
	 */
	contentType:string = undefined;
}
/**
 * 
 * 				Deprecated. Use GeoCoordinates so that elastic search geo is enabled
 * 			
 */
export class LatLong {
	/**
	 * positive or negative number representing geopoint
	 */
	latitude:number = undefined;
	/**
	 * positive or negative number representing geopoint
	 */
	longitude:number = undefined;
}
export class GeoCoordinates {
	/**
	 * positive or negative number representing geopoint
	 */
	lat:number = undefined;
	/**
	 * positive or negative number representing geopoint
	 */
	lon:number = undefined;
}
export class HyperMedia {
	/**
	 * A relative link to the URI
	 */
	rel:string = undefined;
	/**
	 * The URI to the resource
	 */
	uri:string = undefined;
	/**
	 * Http method: GET, PUT, POST, etc
	 */
	method:string = undefined;
}
/**
 * The list type to be included as parameters in all list APIs. Response types will need to include a field for the totalRowCount
 */
export class ListInfo {
	levelOfDetail:string = undefined;
	/**
	 * page size - number of rows
	 */
	numberOfRows:number = undefined;
	/**
	 * start at row number
	 */
	startAt:number = undefined;
	/**
	 * List of fields for sorting
	 */
	sortFields:SortField[] = undefined;
	/**
	 * List of fields to include in response - if not populated, this will return all fields specified in the response type
	 */
	fields:string[] = undefined;
	/**
	 * The first response will populate this with the total query row count
	 */
	totalRowCount:number = undefined;
	/**
	 * start at row number for ID
	 */
	startAtId:number = undefined;
}
export class Location {
	/**
	 * Consignee, Receiver, Sender, Shipper, Warehouse, DC, Hub, Transit Point,Spot, Drop, Yard, Dock, Port
	 */
	locationTypeCd:string = undefined;
	/**
	 * Primary and alias names and addresses
	 */
	address:Address[] = undefined;
	coordinate:LatLong = undefined;
	note:string[] = undefined;
	reference:ReferenceNumber[] = undefined;
}
/**
 * Basic GeoLocation information
 */
export class GeoLocation {
	/**
	 * City Name part of the address.
	 */
	cityName:string = undefined;
	/**
	 * Standard Postal abbreviation for state name. Example: OR for Oregon.
	 */
	stateCd:string = undefined;
	/**
	 * standard Postal abbreviation for a country. Example: CA for Canada.
	 */
	countryCd:string = undefined;
	/**
	 * Postal Code are aka Zip code in USA. US and Mexico use 5 digit postal codes. Canada uses 6 char postal codes.
	 */
	postalCd:string = undefined;
	/**
	 * Preferred City Name
	 */
	preferredCityName:string = undefined;
}
export class MappingInfo {
	columnIdx:number = undefined;
	columnName:string = undefined;
}
export class MonetaryAmount {
	amt:number = undefined;
	/**
	 * ISO 3 Letter Currency codes
	 */
	currencyCd:string = undefined;
}
export class MoreInfo {
	/**
	 * Internal error code
	 */
	errorCode:string = undefined;
	/**
	 * A message describing the fault in more detail
	 */
	message:string = undefined;
	/**
	 * Describes the location at which this fault occurred
	 */
	location:string = undefined;
	/**
	 * The index of the element being describer if it is part of an array
	 */
	itemNbr:number = undefined;
}
export class NameValue {
	name:string = undefined;
	value:string = undefined;
}
export class PaymentCalcMethod {
	/**
	 * spot quote, rate-based
	 */
	calcMethodTypeCd:string = undefined;
	/**
	 * whatever if being multiplied by unit rate - hours, weight, miles
	 */
	quantity:number = undefined;
	quantityUOM:string = undefined;
	perUnitRate:number = undefined;
	perUnitRateEffDate:Date = undefined;
	exchRateInfo:ExchangeRateInfo = undefined;
	percentageFactor:number = undefined;
}
export class Person {
	/**
	 * Full name is concatenation of  title, first, middle and last name. This is a denorm attribute.
	 */
	fullName:string = undefined;
	/**
	 * First name of the person.
	 */
	firstName:string = undefined;
	/**
	 * Either middle name of middle initials
	 */
	middleName:string = undefined;
	/**
	 * Last name or the Sir name of the person.
	 */
	lastName:string = undefined;
	/**
	 * Prefix used with the name such as Dr. Mr. Ms. etc.
	 */
	salutation:string = undefined;
	/**
	 * Title as appropriate including job title, professional title etc.
	 */
	title:string = undefined;
	/**
	 * Various addresses for the person including business, home etc.
	 */
	address:Address[] = undefined;
	/**
	 * email addresses, chat handles, phone numbers and preferences.
	 */
	contactInfo:ContactInfo = undefined;
	/**
	 * Preferered language that should be used to communicate with the person.
	 */
	preferredLanguage:string = undefined;
	/**
	 * Follows a person's full name and provides additional information about the person such as a position, educational degree, accreditation, office, or honor.
	 */
	suffix:string = undefined;
}
export class Phone {
	/**
	 * Daytime, Evening, Mobile, Home, Emergency
	 */
	phoneTypeCd:string = undefined;
	/**
	 * Country code of the full phone#. Example US country code is 1.
	 */
	countryCd:number = undefined;
	/**
	 * When a phone is on an internal company network, these are additional digits to reach a party after dialing the standard phone#.
	 */
	extension:number = undefined;
	/**
	 * This is phone number that might optionally include components such as country code, city code, area in addition to the standard within city phone#. Example: 450-6019 or 503-450-6019 or 1-503-450-6019. The phone# might include various delimiters such as - ( )
	 */
	phoneNbr:string = undefined;
}
/**
 * Contains identifying information for a pickup request.
 */
export class PickupRequestId {
	/**
	 * The unique identifier for the pickup request.
	 */
	pkupInstId:string = undefined;
	/**
	 * The sequence number associated with the pickup request item.
	 */
	pkupItemSeq:string = undefined;
}
/**
 * This type will be used for the management of postal code assignments to sic, network node or sales territory.
 */
export class PostalAssignment {
	/**
	 * US or Canadian postal code
	 */
	postalCode:string = undefined;
	/**
	 * Date assignment was or will be active
	 */
	effectiveDate:Date = undefined;
	/**
	 * Date assignment was or will be expired
	 */
	expirationDate:Date = undefined;
}
/**
 * The ReferenceType type is the type of all reference elements within
 * 								Conway schemas. This type provides a reference attribute, to reference an object
 * 								defined elsewhere.  It does not contain metadata,
 * 								as it does not itself establish the existence of an object; it relies on a definition
 * 								located elsewhere.
 * Data that is commonly understood, but often rendered with different edit masks will be preserved in original format as well as the Conway canonical format. This will be done by repeating the reference number type instance for both the canonical. This is only done for type where there is an industry standard and different ways of representing it, like phone number, etc...For anything where it is specific to domain/client, then the format/value will be preserved.
 * 
 */
export class ReferenceNumber {
	reference:string = undefined;
	referenceDescr:string = undefined;
	/**
	 * PO, Lading number, etc
	 */
	referenceTypeCd:string = undefined;
}
/**
 * This type will be used to return basic location information for service centers
 */
export class ServiceCenterLocation {
	/**
	 * company sic code
	 */
	sic:string = undefined;
	/**
	 * component that the sic is part of
	 */
	component:string = undefined;
	/**
	 * city for the sic
	 */
	cityName:string = undefined;
	/**
	 * 2 character state code
	 */
	stateCd:string = undefined;
	/**
	 * 2 character country code
	 */
	countryCd:string = undefined;
	/**
	 * A generated link that will display the service center location information on the XPO website.
	 */
	locationLink:string = undefined;
	/**
	 * The phone number of the service center
	 */
	phoneNbr:string = undefined;
	/**
	 * The postal code of the service center.
	 */
	postalCd:string = undefined;
	/**
	 * The name of the SIC.
	 */
	sicName:string = undefined;
}
/**
 * Contains Identifying information for a shipment
 */
export class ShipmentId {
	shipmentInstId:string = undefined;
	proNumber:string = undefined;
	pickupDate:Date = undefined;
}
/**
 * Information about Imaged Documents for a Pro Number
 */
export class ShipmentImagedDocument {
	/**
	 * unique business identifier of the shipment
	 */
	proNumber:string = undefined;
	/**
	 * type of image: BL (Bill of lading, DR(Delivery Receipt),CUST(Customs document),DRRR(Delivery Return Receipt), IR(Inspection Report), LOA(Letter of Authority),NCIC(NMFC Certificate),WI(Weight and Correction Certificate), WRFO(Weight and Research Photos)
	 */
	imageType:string = undefined;
	/**
	 * image format - pdf, jpg,png, tiff
	 */
	imageFormat:string = undefined;
	/**
	 * image files
	 */
	imageFiles:ShipmentImagedDocument_File[] = undefined;
	/**
	 * Date when the document was scanned and imaged
	 */
	scanDate:Date = undefined;
}
export class ShipmentImagedDocument_File {
	/**
	 * Base 64 Formatted image data.
	 */
	base64Data:string = undefined;
}
export class SortField {
	/**
	 * Name of the column for sort
	 */
	sortByFieldName:string = undefined;
	/**
	 * asc - ascending, desc - descending
	 */
	sortOrder:string = undefined;
}
export class Status {
	/**
	 * Examples: missed pickup, attempted delivery, damaged, disputed, paid short.
	 */
	statusCd:string = undefined;
	/**
	 * Examples: No answer, Office closed, Invalid address, Traffic Jam, Cow crossing, etc.
	 */
	statusReasonCd:string = undefined;
	reason:string = undefined;
	dateTime:Date = undefined;
	description:string = undefined;
	comment:Comment = undefined;
}
export class StatusException {
	/**
	 * Examples: missed pickup, attempted delivery, damaged.
	 */
	exceptionTypeCd:string = undefined;
	/**
	 * Examples: No answer, Office closed, Invalid address, Traffic Jam, Cow crossing, etc.
	 */
	exceptionReasonCd:string = undefined;
	reason:string = undefined;
	/**
	 * Resolution - New, Acknowledged, Resolved, Unresolved, Superseded, In-progress, Assigned, etc.
	 */
	rsltnCd:string = undefined;
	dateTime:Date = undefined;
	description:string = undefined;
	comment:Comment = undefined;
}
/**
 * The ReferenceType type is the type of all reference elements within
 * 								Conway schemas. This type provides a reference attribute, to reference an object
 * 								defined elsewhere.  It does not contain metadata,
 * 								as it does not itself establish the existence of an object; it relies on a definition
 * 								located elsewhere.
 * Data that is commonly understood, but often rendered with different edit masks will be preserved in original format as well as the Conway canonical format. This will be done by repeating the reference number type instance for both the canonical. This is only done for type where there is an industry standard and different ways of representing it, like phone number, etc...For anything where it is specific to domain/client, then the format/value will be preserved.
 * 
 */
export class SupplementalReference {
	/**
	 * PO, Lading number, etc
	 */
	suppRefTypeCd:string = undefined;
	/**
	 * sequence of supplemental reference on shipment
	 */
	seq:number = undefined;
	refValue:string = undefined;
	refDesc:string = undefined;
	sourceCd:string = undefined;
	printIncludeInd:boolean = undefined;
	mask:string = undefined;
	mandatoryInd:boolean = undefined;
}
/**
 * Basic information about the employee
 */
export class EmployeeId {
	/**
	 * User Employee Id
	 */
	employeeId:string = undefined;
	/**
	 * The given name of the Employee
	 */
	employeeName:string = undefined;
}
export class User {
	/**
	 * The ID of the user making this request
	 */
	userId:string = undefined;
	/**
	 * User Employee Id
	 */
	employeeId:string = undefined;
	/**
	 * RACF ID of the user
	 */
	racfId:string = undefined;
	/**
	 * Contain the instance id of the user prifile. Will be populated if user is an external registered user.
	 */
	profileInstId:string = undefined;
	/**
	 * The name to be displayed
	 */
	displayName:string = undefined;
	/**
	 * The given name of the user
	 */
	givenName:string = undefined;
	/**
	 * The last name of the user
	 */
	lastName:string = undefined;
	/**
	 * The job title of the user
	 */
	title:string = undefined;
	/**
	 * The email address of the user
	 */
	emailAddress:string = undefined;
	/**
	 * The mobile phone number of the user
	 */
	mobile:string = undefined;
	/**
	 * The telephone number of the user
	 */
	telephone:string = undefined;
	/**
	 * The address of the user
	 */
	streetAddress:string = undefined;
	/**
	 * Standard Postal abbreviation for state name. Example: OR for Oregon.
	 */
	stateCd:string = undefined;
	/**
	 * standard Postal abbreviation for a country. Example: CA for Canada.
	 */
	countryCd:string = undefined;
	/**
	 * The postal code for the street address
	 */
	postalCode:string = undefined;
	/**
	 * Department of the user
	 */
	department:string = undefined;
	/**
	 * Business unit of employee as defined in the HR system
	 */
	businessUnit:string = undefined;
	/**
	 * A description of the region. E.g. IT LTL Dev
	 */
	regionDescription:string = undefined;
	/**
	 * Employee Manager
	 */
	manager:string = undefined;
	/**
	 * Primary job role code
	 */
	jobRoleCode:string = undefined;
	/**
	 * A list of roles associated with this user
	 */
	roles:string[] = undefined;
	/**
	 * user location sic code
	 */
	sicCode:string = undefined;
}
export class Volume {
	volume:number = undefined;
	volumeUOM:string = undefined;
}
export class Weight {
	weight:number = undefined;
	weightUom:string = undefined;
}
export class GetHealthCheckResp {
	service:string = undefined;
	status:string = undefined;
	build:string = undefined;
}
export class Instruction {
	instructionTypeCd:string = undefined;
	value:string = undefined;
	role:string = undefined;
	notes:string = undefined;
	schedule:CustomerSchedule[] = undefined;
	suppRef:SupplementalReference[] = undefined;
	documentRequirement:NameValue[] = undefined;
	ruleParameter:ConfigParm[] = undefined;
}
export class CustomerSchedule {
	/**
	 * number of unit
	 */
	frequencyInterval:number = undefined;
	/**
	 * Hours, minutes, days
	 */
	frequencyUnit:string = undefined;
	scheduledTime:Date[] = undefined;
}
export class ConfigParm {
	ruleTypeCd:string = undefined;
	/**
	 * A grouping identifier for parms config parm sets that should be executed today
	 */
	groupID:string = undefined;
	parms:NameValue[] = undefined;
	desc:string = undefined;
	priority:number = undefined;
	seq:number = undefined;
	processContext:string = undefined;
	ruleFunctionName:string = undefined;
}
/**
 * Account IDs and address
 */
export class PartyBasic {
	/**
	 * Database key for customer
	 */
	acctInstId:string = undefined;
	/**
	 * Business key for customer
	 */
	acctMadCd:string = undefined;
	/**
	 * Address
	 */
	address:Address = undefined;
}
/**
 * Account IDs and address
 */
export class PartyAccountBasic {
	/**
	 * Database key for customer
	 */
	acctInstId:string = undefined;
	/**
	 * Business key for customer
	 */
	acctMadCd:string = undefined;
	/**
	 * Business key for customer
	 */
	acctName:string = undefined;
	/**
	 * Address
	 */
	address:Address = undefined;
}
/**
 * Account IDs and address
 */
export class PartyContactBasic {
	/**
	 * Database key for customer
	 */
	acctInstId:string = undefined;
	/**
	 * Business key for customer
	 */
	acctMadCd:string = undefined;
	/**
	 * Address
	 */
	address:Address = undefined;
	contactInfo:ContactInfoBasic = undefined;
}
export class InvoicingInstruction {
	/**
	 * This is to distinguish groups of instructions used for generating invoices for a customer. e.g. Prepaid invoices, Past Due invoices etc.
	 */
	invInstrTypeCd:string = undefined;
	/**
	 * Reference by customerAccount ID to the bill to location
	 */
	bil2Account:string = undefined;
	/**
	 * These are the instruction used by the invoicing process to generate invoices. The instructions include the schedule (when to generate invoice e.g. daily for this customer), additional documents that need to be attached, additional data such as Supplimentary references that must be available for the PRO before an invoice can be generated etc.
	 */
	instruction:Instruction[] = undefined;
	/**
	 * The method of transmission of invoices to the customer. Example electronic, EDI, email attachment, pape etc.
	 */
	transmissionMode:string = undefined;
	/**
	 * The format in which the invoice is generated. Example plain text, comma delimited, PDF, html etc. Most commonly used when the transmission mode is electronic. For paper invoices, this can store the name of the template.
	 */
	invoiceFormat:string = undefined;
	/**
	 * This may be Statement or Manifest. If not populated then the invoices are being rendered individually with no summary.
	 */
	consolidatedInvoiceFormat:string = undefined;
	/**
	 * ***What type? For this invoicing mode, use this contact. It will have the name, address or email address or phone. FTP addresses will be in address with an address type of FTP and the ID will be in reference in the AdressType
	 */
	sendTo:Person = undefined;
}
/**
 * Account Information in the CRM System
 */
export class CrmAccount {
	/**
	 * Database key for customer
	 */
	acctInstId:string = undefined;
	/**
	 * Unique Identifier of the Account in the CRM System
	 */
	accountId:string = undefined;
	/**
	 * Account Name in the CRM System
	 */
	accountName:string = undefined;
	/**
	 * Account Address in the CRM System
	 */
	accountAddress:Address = undefined;
}
/**
 * Account Owner Information in the CRM System
 */
export class CrmAccountOwner {
	/**
	 * Unique Identifier for the employee
	 */
	employeeId:string = undefined;
	/**
	 * First name of the employee
	 */
	firstName:string = undefined;
	/**
	 * Last name of the employee
	 */
	lastName:string = undefined;
	/**
	 * Full name of the employee
	 */
	fullName:string = undefined;
	/**
	 * Job title of the employee
	 */
	title:string = undefined;
	/**
	 * Job profile of the employee
	 */
	profile:string = undefined;
	/**
	 * Name of the role
	 */
	roleName:string = undefined;
}
/**
 * 
 */
export class AccountDetail {
	/**
	 * 
	 */
	acctInstId:number = undefined;
	/**
	 * 
	 */
	acctMadCd:string = undefined;
	/**
	 * 
	 */
	parentAcctInstId:number = undefined;
	/**
	 * 
	 */
	nationalAcctInd:boolean = undefined;
	/**
	 * 
	 */
	name1:string = undefined;
	/**
	 * 
	 */
	name2:string = undefined;
	/**
	 * 
	 */
	creditStatusCode:string = undefined;
	/**
	 * 
	 */
	locationTypeCode:string = undefined;
	/**
	 * 
	 */
	addressLine1:string = undefined;
	/**
	 * 
	 */
	addressLine2:string = undefined;
	/**
	 * 
	 */
	cityName:string = undefined;
	/**
	 * 
	 */
	stateCd:string = undefined;
	/**
	 * 
	 */
	postalCd:string = undefined;
	/**
	 * 
	 */
	countryCd:string = undefined;
	/**
	 * 
	 */
	deletedInd:boolean = undefined;
	/**
	 * 
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 */
	lastCreatedBy:string = undefined;
	/**
	 * 
	 */
	lastCreatedDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	geoCoordinates:LatLong = undefined;
	/**
	 * Geo coordinations
	 */
	geoCoordinatesGeo:GeoCoordinates = undefined;
	/**
	 * 
	 */
	zip4RestUs:string = undefined;
}
export class Equipment {
	equipmentBase:EquipmentBase = undefined;
	/**
	 * replaced, intact, broken, missing
	 */
	sealInfo:EquipmentSeal[] = undefined;
	/**
	 * The stackability factor indicates how many items of packaging material, such as pallets, can be stacked on top of one another. It is not a control and is therefore for information purposes only.
	 */
	stackabilityFactor:number = undefined;
	/**
	 * The filling level is the percentage to which a handling unit should be filled. It is not a control and is therefore for information purposes only.
	 */
	fillingLevel:number = undefined;
	/**
	 * In this field, you can enter a percentage tolerance limit, up to which the allowed packing weight of a handling unit can be exceeded.
	 */
	excessWeightTolerance:number = undefined;
	/**
	 * In this field, you can enter a percentage tolerance limit, up to which the allowed packing volume of a handling unit can be exceeded.
	 */
	excessVolumeTolerance:number = undefined;
	maxSpeed:Speed = undefined;
	/**
	 * Used for optimization
	 */
	averageSpeed:Speed = undefined;
	/**
	 * Serial or VIN number of the equipment
	 */
	equipmentSerialNbr:string = undefined;
	/**
	 * Transponder Number of the tractor
	 */
	equipmentTransponderNbr:string = undefined;
	/**
	 * The DOT number of the tractor For XPO LTL tractors it is 241829.  For XPO LTL Canada
	 * tractors, that is 838885.
	 */
	equipmentDOTNbr:string = undefined;
}
export class EquipmentBase {
	equipmentInstID:string = undefined;
	equipmentPrefix:string = undefined;
	/**
	 * Sequencing or serial part of an equipment unit's identifying number (pure numeric form for equipment number is preferred)
	 */
	equipmentSuffix:string = undefined;
	/**
	 * Prefix or alphabetic part of an equipment unit's identifying number
	 */
	ownerPfx:string = undefined;
	/**
	 * Name of equipment owner if not ltl.xpo
	 */
	ownerName:string = undefined;
	/**
	 * Company, Subservice, rental company,
	 */
	supplierTypeCd:string = undefined;
	/**
	 * Trip Leased,Seller owned(Returnable),Railroad Leased,Not customer owned or leased,Customer owned or leased
	 */
	leaseTypeCd:string = undefined;
	/**
	 * Container, Forklift, Dolly, Tractor, Skid, TrailerType -
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * LiftGate, PackingMaterials, StackingAppliance
	 */
	equipmentCharacteristics:EquipmentProperty[] = undefined;
	tareWeight:Weight = undefined;
	allowedGrossWeight:Weight = undefined;
	allowedGrossVolume:Volume = undefined;
	dimensions:Dimensions = undefined;
	dateOfManufacture:Date = undefined;
	/**
	 * License or Registration number of the Equipment
	 */
	equipmentLicNbr:string = undefined;
	/**
	 * State or Province Code where the license waas issued.
	 */
	equipmentLicLoc:string = undefined;
	/**
	 * Country where the license was issued
	 */
	equipmentLicCountryCd:string = undefined;
}
/**
 * Identification info for equipment
 */
export class EquipmentInfo {
	equipmentInstID:string = undefined;
	/**
	 * Type of the equipment - Tractor, Trailer, Dolly, Straight Truck
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * Prefix for the quipment number.
	 */
	equipmentPrefix:string = undefined;
	/**
	 * Sequencing or serial part of an equipment unit's identifying number (pure numeric form for equipment number is preferred)
	 */
	equipmentSuffix:string = undefined;
}
export class EquipmentProperty {
	eqpPropTypeCd:string = undefined;
	eqpPropDesc:string = undefined;
}
export class EquipmentSeal {
	/**
	 * for now it will be hardcoded value not a code lookup
	 */
	sealOwner:string = undefined;
	/**
	 * Unique number on seal used to close a shipment
	 */
	sealNbr:string[] = undefined;
	/**
	 * replaced, intact, broken, missing
	 */
	sealStatusCd:string[] = undefined;
}
export class Speed {
	speed:number = undefined;
	speedUOM:string = undefined;
}
export class FaultResponse {
	/**
	 * Internal error code
	 */
	errorCode:string = undefined;
	/**
	 * The message describing the fault
	 */
	message:string = undefined;
	/**
	 * A JSON message describing the fault
	 */
	jsonMessage:object = undefined;
	/**
	 * Program trace information to help troubleshoot this fault E.g. The Java Stack trace from the exception.
	 */
	trace:string = undefined;
	/**
	 * The ID of the application using this API
	 */
	srcApplicationId:string = undefined;
	/**
	 * Contains the date and time of when this transaction occurred.
	 */
	transactionTimestamp:Date = undefined;
	/**
	 * A unique ID for this transaction.
	 */
	correlationId:string = undefined;
	/**
	 * Any additional information that will help in troubleshooting this fault. E.g. include any context information such as requesting application, user details, any identifying details from the API request such as customer number, PRO number etc.
	 */
	moreInfo:MoreInfo[] = undefined;
	/**
	 * Fault Response from the root cause.
	 */
	rootCause:FaultResponse = undefined;
}
export class JobStatus {
	code:number = undefined;
	message:string = undefined;
	details:string[] = undefined;
}
export class JobRetryConfig {
	retryCount:number = undefined;
	maxRetryDuration:number = undefined;
	maxBackoffDuration:number = undefined;
	maxDoublings:number = undefined;
}
export class Attribute {
	name:string = undefined;
	value:string = undefined;
}
export class PubsubTarget {
	topicName:number = undefined;
	data:string = undefined;
	attributes:Attribute[] = undefined;
}
export class AppEngineRouting {
	service:string = undefined;
	version:string = undefined;
	instance:string = undefined;
	host:string = undefined;
}
export class AppEngineHttpTarget {
	httpMethod:HttpMethod = undefined;
	appEngineRouting:AppEngineRouting = undefined;
	maxBackoffDuration:number = undefined;
	relativeUri:string = undefined;
	headers:Attribute[] = undefined;
	body:string = undefined;
}
export class HttpTarget {
	uri:string = undefined;
	httpMethod:HttpMethod = undefined;
	headers:Attribute[] = undefined;
	body:string = undefined;
}
export class Job {
	name:string = undefined;
	description:string = undefined;
	schedule:string = undefined;
	timeZone:string = undefined;
	userUpdateTime:Date = undefined;
	state:JobStateTypeCd = undefined;
	status:JobStatus = undefined;
	scheduleTime:Date = undefined;
	lastAttemptTime:Date = undefined;
	retryConfig:JobRetryConfig = undefined;
	attemptDeadline:number = undefined;
	target:object = undefined;
}
/**
 * Added for compatibility
 */
export class KafkaMessageHeader {
	/**
	 * Name of the target elastic search index
	 */
	index:string = undefined;
	/**
	 * This is the envrionment within that division  e.g. CTSI, CTS2, CTSS, PROD
	 */
	env:string = undefined;
	/**
	 * Id of the document
	 */
	id:string = undefined;
	/**
	 * Type of document
	 */
	docType:string = undefined;
	/**
	 * Correlation id of the transaction
	 */
	correlationId:string = undefined;
	/**
	 * Id of the application generating this message
	 */
	srcApplicationId:string = undefined;
}
/**
 * Envelope for messages bound for a Elastic Search
 */
export class SearchRecordMessageHeader {
	/**
	 * Name of the target elastic search index
	 */
	index:string = undefined;
	/**
	 * This is the environment within that division  e.g. CTSI, CTS2, CTSS, PROD
	 */
	env:string = undefined;
	/**
	 * Id of the document
	 */
	id:string = undefined;
	/**
	 * Type of document
	 */
	docType:string = undefined;
	/**
	 * Correlation id of the transaction
	 */
	correlationId:string = undefined;
	/**
	 * Id of the application generating this message
	 */
	srcApplicationId:string = undefined;
	/**
	 * Type of document
	 */
	action:ActionCd = undefined;
}
/**
 * This is the root element for the LTL Canonical Msg
 */
export class LTLMessageHdr {
	hdr:object = undefined;
	body:Body[] = undefined;
}
export class Body {
	payload:PayloadType[] = undefined;
}
export class PayloadType {
}
/**
 * TransactionContext format. transaction type, action
 *  
 */
export class TranCntxt {
	/**
	 * Transaction event
	 */
	tranType:string = undefined;
	/**
	 * The action to be taken on this message --- is a business body of work.
	 */
	action:string = undefined;
	correlationId:number = undefined;
	contextId:object = undefined;
}
export class StopWindow {
	/**
	 * Indicates if the window is profile based or for a specific date/date range.
	 */
	stopWindowType:StopWindowCd = undefined;
	beginDate:string = undefined;
	/**
	 * The beginning local time of the service window
	 */
	beginTime:string = undefined;
	endDate:string = undefined;
	/**
	 * The ending local time of the service window
	 */
	endTime:string = undefined;
	note:string[] = undefined;
}
/**
 * 
 */
export class PickupRequest {
	/**
	 * 
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 */
	sourceCd:PickupSourceCd = undefined;
	/**
	 * 
	 */
	statusCd:PickupStatusCd = undefined;
	/**
	 * 
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 */
	readyTime:string = undefined;
	/**
	 * 
	 */
	closeTime:string = undefined;
	/**
	 * 
	 */
	ttlPalletsCount:number = undefined;
	/**
	 * 
	 */
	ttlLoosePiecesCount:number = undefined;
	/**
	 * 
	 */
	ttlWeightLbs:number = undefined;
	/**
	 * 
	 */
	pickupTerminalSicCd:string = undefined;
	/**
	 * 
	 */
	cadCallNbr:number = undefined;
	/**
	 * 
	 */
	shipperName1:string = undefined;
	/**
	 * 
	 */
	shipperName2:string = undefined;
	/**
	 * 
	 */
	shipperAddress:string = undefined;
	/**
	 * 
	 */
	shipperCity:string = undefined;
	/**
	 * 
	 */
	shipperState:string = undefined;
	/**
	 * 
	 */
	shipperCountryCd:ShipperCountryCd = undefined;
	/**
	 * 
	 */
	shipperZip6:string = undefined;
	/**
	 * 
	 */
	shipperZip4RestUs:string = undefined;
	/**
	 * 
	 */
	rqstrName:string = undefined;
	/**
	 * 
	 */
	rqstrCompanyName:string = undefined;
	/**
	 * 
	 */
	rqstrPhoneCountryCdNbr:string = undefined;
	/**
	 * 
	 */
	rqstrPhoneAreaCdNbr:string = undefined;
	/**
	 * 
	 */
	rqstrPhoneNbr:string = undefined;
	/**
	 * 
	 */
	rqstrPhoneExtensionNbr:string = undefined;
	/**
	 * 
	 */
	rqstrEMailId:string = undefined;
	/**
	 * 
	 */
	customerProfitabilityInstId:number = undefined;
	/**
	 * 
	 */
	contactName:string = undefined;
	/**
	 * 
	 */
	contactCompanyName:string = undefined;
	/**
	 * 
	 */
	contactPhoneCountryCdNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneAreaCdNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneExtensionNbr:string = undefined;
	/**
	 * 
	 */
	createdTimestamp:Date = undefined;
	/**
	 * 
	 */
	createdUserId:string = undefined;
	/**
	 * 
	 */
	remarks:string = undefined;
	/**
	 * 
	 */
	shipperPhoneCountryCd:ShipperPhoneCountryCd = undefined;
	/**
	 * 
	 */
	shipperPhoneExtension:string = undefined;
	/**
	 * 
	 */
	shipperPhoneAreaCd:string = undefined;
	/**
	 * 
	 */
	shipperPhone:string = undefined;
	/**
	 * 
	 */
	equipmentNbr:string = undefined;
	/**
	 * 
	 */
	strandInd:boolean = undefined;
	/**
	 * 
	 */
	callDate:string = undefined;
	/**
	 * 
	 */
	rqstrRoleCd:string = undefined;
	/**
	 * 
	 */
	callSequenceNbr:number = undefined;
	/**
	 * 
	 */
	cashDueInd:boolean = undefined;
	/**
	 * 
	 */
	specialEquipmentCd:SpecialEquipmentCd = undefined;
	/**
	 * 
	 */
	earlyCutoffInd:EarlyCutoffCd = undefined;
	/**
	 * 
	 */
	shipperAddr2:string = undefined;
	/**
	 * 
	 */
	shipperCreditedStatusCd:ShipperCreditStatusCd = undefined;
	/**
	 * 
	 */
	insidePickupInd:boolean = undefined;
	/**
	 * 
	 */
	pickupTypeCd:PickupTypeCd = undefined;
	/**
	 * 
	 */
	volumeInd:boolean = undefined;
	/**
	 * 
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 */
	dsrNote:string = undefined;
	/**
	 * 
	 */
	rschCnclReasonCd:RschCnclReasonCd = undefined;
	/**
	 * 
	 */
	rschCnclReason:string = undefined;
	/**
	 * 
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 */
	shipperSicCd:string = undefined;
	/**
	 * 
	 */
	csrOmsInd:boolean = undefined;
	/**
	 * 
	 */
	omsEmployeeUserid:string = undefined;
	/**
	 * 
	 */
	strandReasonCd:StrandReasonCd = undefined;
	/**
	 * 
	 */
	arrivalShiftDate:string = undefined;
	/**
	 * 
	 */
	arrivalShiftCd:string = undefined;
	/**
	 * 
	 */
	loadId:string = undefined;
	/**
	 * 
	 */
	rschCnclContactName:string = undefined;
	/**
	 * 
	 */
	rschCnclContactPhoneNbr:string = undefined;
	/**
	 * 
	 */
	lastUsedSequenceNbr:number = undefined;
	/**
	 * 
	 */
	possibleDuplicateOrderInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PickupRequestor {
	/**
	 * 
	 */
	rqstrInstId:number = undefined;
	/**
	 * 
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 */
	statusCd:string = undefined;
	/**
	 * 
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 */
	readyTime:string = undefined;
	/**
	 * 
	 */
	closeTime:string = undefined;
	/**
	 * 
	 */
	name:string = undefined;
	/**
	 * 
	 */
	companyName:string = undefined;
	/**
	 * 
	 */
	phoneCountryCdNbr:string = undefined;
	/**
	 * 
	 */
	phoneAreaCdNbr:string = undefined;
	/**
	 * 
	 */
	phoneNbr:string = undefined;
	/**
	 * 
	 */
	phoneExtensionNbr:string = undefined;
	/**
	 * 
	 */
	eMailId:string = undefined;
	/**
	 * 
	 */
	roleCd:string = undefined;
	/**
	 * 
	 */
	customerProfitabilityInstId:number = undefined;
	/**
	 * 
	 */
	contactName:string = undefined;
	/**
	 * 
	 */
	contactCompanyName:string = undefined;
	/**
	 * 
	 */
	contactPhoneCountryCdNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneAreaCdNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneExtensionNbr:string = undefined;
	/**
	 * 
	 */
	createdUserId:string = undefined;
	/**
	 * 
	 */
	updateById:string = undefined;
	/**
	 * 
	 */
	ediSenderId:string = undefined;
	/**
	 * 
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 */
	shipperName1:string = undefined;
	/**
	 * 
	 */
	shipperName2:string = undefined;
	/**
	 * 
	 */
	shipperAddress:string = undefined;
	/**
	 * 
	 */
	shipperCity:string = undefined;
	/**
	 * 
	 */
	shipperStateCd:string = undefined;
	/**
	 * 
	 */
	shipperCountryCd:string = undefined;
	/**
	 * 
	 */
	shipperZip6:string = undefined;
	/**
	 * 
	 */
	shipperZip4RestUs:string = undefined;
	/**
	 * 
	 */
	shipperPhoneCountryCd:string = undefined;
	/**
	 * 
	 */
	shipperPhoneAreaCd:string = undefined;
	/**
	 * 
	 */
	shipperPhone:string = undefined;
	/**
	 * 
	 */
	shipperPhoneExtension:string = undefined;
	/**
	 * 
	 */
	equipmentNbr:string = undefined;
	/**
	 * 
	 */
	specialEquipmentCd:string = undefined;
	/**
	 * 
	 */
	insidePickupInd:boolean = undefined;
	/**
	 * 
	 */
	remarks:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
export class PickupLineItemSearchFilter {
	q:string = undefined;
	hostOriginTerminalSicCd:XrtAttributeFilter = undefined;
	header_pickupTerminalSicCd:XrtAttributeFilter = undefined;
	header_pickupDate:XrtAttributeFilter = undefined;
	header_pickupTypeCd:XrtAttributeFilter = undefined;
	header_readyTime:XrtAttributeFilter = undefined;
	header_closeTime:XrtAttributeFilter = undefined;
	header_cadCallNbr:XrtAttributeFilter = undefined;
	guaranteedInd:XrtAttributeFilter = undefined;
	hazardousMtInd:XrtAttributeFilter = undefined;
	freezableInd:XrtAttributeFilter = undefined;
	proNbr:XrtAttributeFilter = undefined;
	shipper_geoCoordinates:XrtAttributeFilter = undefined;
	header_statusCd:XrtAttributeFilter = undefined;
	suppRef_suppRefTypeCd:XrtAttributeFilter = undefined;
	suppRef_refValue:XrtAttributeFilter = undefined;
	header_shipperName1:XrtAttributeFilter = undefined;
	header_shipperAddress:XrtAttributeFilter = undefined;
	header_shipperCity:XrtAttributeFilter = undefined;
	header_shipperState:XrtAttributeFilter = undefined;
	header_shipperZip6:XrtAttributeFilter = undefined;
	header_shipperCountryCd:XrtAttributeFilter = undefined;
	header_shipperZip4RestUs:XrtAttributeFilter = undefined;
	header_shipperPhoneCountryCd:XrtAttributeFilter = undefined;
	header_shipperPhoneAreaCd:XrtAttributeFilter = undefined;
	header_shipperPhone:XrtAttributeFilter = undefined;
	header_shipperPhoneExtension:XrtAttributeFilter = undefined;
	header_sourceCd:XrtAttributeFilter = undefined;
	specialServiceSummary_specialService:XrtAttributeFilter = undefined;
	header_callDate:XrtAttributeFilter = undefined;
	header_originTerminalSicCd:XrtAttributeFilter = undefined;
	destinationTerminalSicCd:XrtAttributeFilter = undefined;
	header_rqstrRoleCd:XrtAttributeFilter = undefined;
	header_rqstrCompanyName:XrtAttributeFilter = undefined;
	header_rqstrName:XrtAttributeFilter = undefined;
	header_rqstrPhoneCountryCdNbr:XrtAttributeFilter = undefined;
	header_rqstrPhoneAreaCdNbr:XrtAttributeFilter = undefined;
	header_rqstrPhoneNbr:XrtAttributeFilter = undefined;
	header_rqstrPhoneExtensionNbr:XrtAttributeFilter = undefined;
	header_rqstrEMailId:XrtAttributeFilter = undefined;
	shipper_acctMadCd:XrtAttributeFilter = undefined;
	public toJSON = function(this: PickupLineItemSearchFilter) {
		return {
			"q" : this.q,
			"hostOriginTerminalSicCd" : this.hostOriginTerminalSicCd,
			"header.pickupTerminalSicCd" : this.header_pickupTerminalSicCd,
			"header.pickupDate" : this.header_pickupDate,
			"header.pickupTypeCd" : this.header_pickupTypeCd,
			"header.readyTime" : this.header_readyTime,
			"header.closeTime" : this.header_closeTime,
			"header.cadCallNbr" : this.header_cadCallNbr,
			"guaranteedInd" : this.guaranteedInd,
			"hazardousMtInd" : this.hazardousMtInd,
			"freezableInd" : this.freezableInd,
			"proNbr" : this.proNbr,
			"shipper.geoCoordinates" : this.shipper_geoCoordinates,
			"header.statusCd" : this.header_statusCd,
			"suppRef.suppRefTypeCd" : this.suppRef_suppRefTypeCd,
			"suppRef.refValue" : this.suppRef_refValue,
			"header.shipperName1" : this.header_shipperName1,
			"header.shipperAddress" : this.header_shipperAddress,
			"header.shipperCity" : this.header_shipperCity,
			"header.shipperState" : this.header_shipperState,
			"header.shipperZip6" : this.header_shipperZip6,
			"header.shipperCountryCd" : this.header_shipperCountryCd,
			"header.shipperZip4RestUs" : this.header_shipperZip4RestUs,
			"header.shipperPhoneCountryCd" : this.header_shipperPhoneCountryCd,
			"header.shipperPhoneAreaCd" : this.header_shipperPhoneAreaCd,
			"header.shipperPhone" : this.header_shipperPhone,
			"header.shipperPhoneExtension" : this.header_shipperPhoneExtension,
			"header.sourceCd" : this.header_sourceCd,
			"specialServiceSummary.specialService" : this.specialServiceSummary_specialService,
			"header.callDate" : this.header_callDate,
			"header.originTerminalSicCd" : this.header_originTerminalSicCd,
			"destinationTerminalSicCd" : this.destinationTerminalSicCd,
			"header.rqstrRoleCd" : this.header_rqstrRoleCd,
			"header.rqstrCompanyName" : this.header_rqstrCompanyName,
			"header.rqstrName" : this.header_rqstrName,
			"header.rqstrPhoneCountryCdNbr" : this.header_rqstrPhoneCountryCdNbr,
			"header.rqstrPhoneAreaCdNbr" : this.header_rqstrPhoneAreaCdNbr,
			"header.rqstrPhoneNbr" : this.header_rqstrPhoneNbr,
			"header.rqstrPhoneExtensionNbr" : this.header_rqstrPhoneExtensionNbr,
			"header.rqstrEMailId" : this.header_rqstrEMailId,
			"shipper.acctMadCd" : this.shipper_acctMadCd		}
	}
}
/**
 * Elastic Search document for Pickup line item
 */
export class PickupLineItemSearchRecord {
	pickupRequestInstId:number = undefined;
	hostOriginTerminalSicCd:string = undefined;
	zip6:string = undefined;
	pickupRequestSequenceNbr:number = undefined;
	palletsCount:number = undefined;
	loosePiecesCount:number = undefined;
	weightLbs:number = undefined;
	guaranteedInd:boolean = undefined;
	hazardousMtInd:boolean = undefined;
	freezableInd:boolean = undefined;
	statusCd:PickupStatusCd = undefined;
	destinationTerminalSicCd:string = undefined;
	createdUserId:string = undefined;
	remarks:string = undefined;
	updateById:string = undefined;
	serviceTypeCd:string = undefined;
	holDeliveryInd:boolean = undefined;
	proNbr:string = undefined;
	headloadInd:boolean = undefined;
	cubeNbr:number = undefined;
	earlyCutoffCd:string = undefined;
	motorMovesNbr:number = undefined;
	archiveInd:boolean = undefined;
	shipmentInstanceId:number = undefined;
	omsCd:string = undefined;
	omsEmployeeUserId:string = undefined;
	omsAuthUserId:string = undefined;
	foodInd:boolean = undefined;
	bolInstId:number = undefined;
	ediSenderId:string = undefined;
	ediSenderShipmentId:string = undefined;
	optOutBy:string = undefined;
	totalVolumeCubicFeet:number = undefined;
	bulkLiquidInd:boolean = undefined;
	suspectCubeInd:boolean = undefined;
	reviewReqdInd:boolean = undefined;
	moveShiftDate:string = undefined;
	moveShiftCd:string = undefined;
	expectedDeliveryDate:string = undefined;
	cubicFeetProfileMthdCd:string = undefined;
	cubicFeetProfileTypeCd:string = undefined;
	sourceCd:PickupSourceCd = undefined;
	originalPickupRequestInstId:number = undefined;
	originalPickupRequestSequenceNbr:number = undefined;
	lstUpdtTmst:Date = undefined;
	eventTmst:Date = undefined;
	pickupTypeCd:PickupTypeCd = undefined;
	shipper:AccountDetail = undefined;
	requestor:PickupRequestor = undefined;
	header:PickupRequest = undefined;
	specialServiceSummary:ShipmentSpecialServiceSummary[] = undefined;
	suppRef:SupplementalReference[] = undefined;
	preassignedDriver:string = undefined;
	remarksAvailableInd:boolean = undefined;
	operationalRemarks:string = undefined;
	pickupInstructions:string = undefined;
	locationType:string = undefined;
	minimumAllowableTrailerAge:number = undefined;
	pickupDwellTime:number = undefined;
	equipmentDwellTime:number = undefined;
	lineItemCount:number = undefined;
	srnCount:number = undefined;
	headerDerivedStatusCd:string = undefined;
}
export class Accessorial {
	accessorialCd:string = undefined;
	quantity:number = undefined;
	quantityUom:string = undefined;
	/**
	 * Description of the Accessorial
	 */
	accessorialDesc:string = undefined;
	/**
	 * Accessorial Amount
	 */
	chargeAmt:MonetaryAmount = undefined;
}
export class Appointment {
	apptTypeCd:string = undefined;
	/**
	 * A system generated sequence number to uniquely identify a nootification for a shipment
	 */
	seq:number = undefined;
	startDateTime:Date = undefined;
	endDateTime:Date = undefined;
	callDateTime:Date = undefined;
	/**
	 * *** Needs Review
	 */
	apptContact:ContactInfo = undefined;
	/**
	 * This might be any special instructions for accessing customer dock
	 */
	apptNote:Comment = undefined;
	/**
	 * customer provided reference number for the appointment
	 */
	apptConfirmation:string = undefined;
	/**
	 * Reason for rescheduling the appt.
	 */
	rescheduleReasonCd:string = undefined;
	apptStatus:Status = undefined;
	/**
	 * SIC that will delivery freight according to this appt.
	 */
	dlvrySIC:string = undefined;
	references:ReferenceNumber[] = undefined;
}
export class Bond {
	/**
	 * Expanded Name : Bond number  : This is the IT, TE or WDTE number (7,8 or a 9 digit number) on the in-bond document. It is used to identify the Shipment while it is in the customs warehouse.
	 */
	bondNbr:string = undefined;
	/**
	 * Desc : Name of the City where the customs inspection is to be made.
	 */
	city:string = undefined;
	/**
	 * Desc : State of the city where the customs inspection is to be made.
	 */
	state:string = undefined;
}
export class ChemicalCharacteristics {
	chemProperShippingName:string = undefined;
	chemTechnicalName1:string = undefined;
	chemTechnicalName2:string = undefined;
	chemTechnicalName3:string = undefined;
	classPrimary:string = undefined;
	classSecondary:string = undefined;
	classTertiary:string = undefined;
	contentWeight:number = undefined;
	contentWeightUOM:string = undefined;
	reportableQuantityInd:boolean = undefined;
	reportQuantityWeight:number = undefined;
	reportQuantityWeightUOM:string = undefined;
	packagingGroupCd:string = undefined;
	specialProvision:string = undefined;
	emergencyGuideBookName:string = undefined;
	hotInd:boolean = undefined;
	residueInd:boolean = undefined;
	poisonousInd:boolean = undefined;
	notOtherwiseSpecifiedInd:boolean = undefined;
	limitedQuantityInd:boolean = undefined;
	toxicInhalationInd:boolean = undefined;
	passengerAircraftForbidInd:boolean = undefined;
	commercialAircraftForbidInd:boolean = undefined;
	massivePollutantInd:boolean = undefined;
	oilContainedInd:boolean = undefined;
	temperatureUOM:string = undefined;
	flashPointTemperature:number = undefined;
	emergencyTemperature:number = undefined;
	controlTemperature:string = undefined;
}
export class CommodityBasicInfo {
	/**
	 * number of commodity items
	 */
	pieceCnt:number = undefined;
	/**
	 * Type of Package
	 */
	packageCode:string = undefined;
	/**
	 * total weight for package and item together
	 */
	grossWeight:Weight = undefined;
	/**
	 * weight of commodity without packaging
	 */
	tareWeight:Weight = undefined;
	volume:Volume = undefined;
	desc:string = undefined;
	nmfcClass:string = undefined;
	nmfcItemCd:string = undefined;
	/**
	 * Set to True if there is any Hazardous material
	 */
	hazmatInd:boolean = undefined;
}
export class CommodityInfo {
	/**
	 * number of commodity items
	 */
	pieceCnt:number = undefined;
	/**
	 * packaging information
	 */
	packaging:Packaging = undefined;
	/**
	 * total weight for package and item together
	 */
	grossWeight:Weight = undefined;
	/**
	 * weight of commodity without packaging
	 */
	tareWeight:Weight = undefined;
	volume:Volume = undefined;
	/**
	 * Commodity description
	 */
	desc:string = undefined;
	nmfcClass:string = undefined;
	nmfcItemCd:string = undefined;
	/**
	 * Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * Y - Can be damaged by freezing
	 */
	freezableInd:boolean = undefined;
	/**
	 * Indicates whether the commodity contains hazardous materials
	 */
	hazmatInd:boolean = undefined;
	hazmatInfo:HazardousCharacteristics = undefined;
	chemicalCharacteristics:ChemicalCharacteristics = undefined;
	emergencyContactName:string = undefined;
	emergencyContactPhone:Phone = undefined;
}
/**
 * ***Needs Review
 */
export class CommodityLine {
	/**
	 * Might be versions of a commodity line - original, corrected, as-rated
	 */
	cmdyLineTypeCd:string = undefined;
	/**
	 * sequence on shipment
	 */
	seq:number = undefined;
	/**
	 * ***Needs Review - will there be dimensions on commodities?
	 */
	dimensions:Dimensions = undefined;
	/**
	 * commodity and packing info including hazmat
	 */
	commodityInfo:CommodityInfo = undefined;
	commoditySubLine:CommodityInfo[] = undefined;
	/**
	 * this will have the rate and charge information. if weight was changed based on rated as, then the new weight will be in quantity. The class will be reflected in asRatedNmfcClass
	 */
	charge:Charge = undefined;
	/**
	 * this is an FAK that has overridden the as entered class when rated
	 */
	asRatedNmfcClass:string = undefined;
	/**
	 * This is value declared by the shipper. This might have implications for the claims.
	 */
	declaredValue:MonetaryAmount = undefined;
	/**
	 * These are refenence numbers provided by the party on the bill (shipper) specific to this commodity line.
	 */
	suppRef:SupplementalReference[] = undefined;
	reference:ReferenceNumber[] = undefined;
}
export class CommodityRatingInfo {
	/**
	 * number of commodity items
	 */
	pieceCnt:number = undefined;
	/**
	 * Type of Package
	 */
	packageCode:string = undefined;
	/**
	 * total weight for package and item together
	 */
	grossWeight:Weight = undefined;
	/**
	 * weight of commodity without packaging
	 */
	tareWeight:Weight = undefined;
	charge:Charge = undefined;
	volume:Volume = undefined;
	desc:string = undefined;
	nmfcClass:string = undefined;
	nmfcItemCd:string = undefined;
	/**
	 * Set to True if there is any Hazardous material
	 */
	hazmatInd:boolean = undefined;
}
/**
 * Reveals the criteria for the application of deficit rating
 */
export class DeficitRating {
	/**
	 * The freight class for this calculation. This will be eitther the actual class or if the customer has an FAK, it will be the FAK
	 */
	nmfcClass:string = undefined;
	/**
	 * difference between the actual weight and the nearest weight break
	 */
	deficitWght:Weight = undefined;
	/**
	 * The weight used to rate the shipment
	 */
	asRatedWeight:Weight = undefined;
	/**
	 * The rate used to calculate the freight charges
	 */
	deficitRate:MonetaryAmount = undefined;
	deficitAmt:MonetaryAmount = undefined;
}
export class FreightExceptions {
	/**
	 * sequence number of the movement for which this exception applies
	 */
	movementSeq:number = undefined;
	exception:StatusException = undefined;
	/**
	 * Over Short and Damage
	 */
	osdPieces:number = undefined;
}
export class HazardousCharacteristics {
	countryCd:string = undefined;
	/**
	 * UN/NA Hazardous codes
	 */
	hazardousCd:string = undefined;
	desc:string = undefined;
}
export class Lading {
	/**
	 * number of lading units (pallets, cartons, etc.) with the same set of dimensions
	 */
	pieceCnt:number = undefined;
	/**
	 * Describes the type of packaging. Barrell, Create, Pallet etc.
	 */
	packageInfo:Packaging = undefined;
	/**
	 * additional description if required
	 */
	desc:string = undefined;
	weight:Weight = undefined;
	/**
	 * This is the source of weight information - ltl.xpo or customer
	 */
	weightSrc:string = undefined;
	volume:Volume = undefined;
	dimensions:Dimensions = undefined;
	/**
	 * pounds per cubic foot
	 */
	density:Density = undefined;
	/**
	 * reference to commodity lines, trackable serial numbers, packing slip number, etc.
	 */
	references:ReferenceNumber[] = undefined;
}
/**
 * The details about the lading unit with dimensions for all pieces of the same dimensions. Both dimensions originally entered by the driver and the inspector may be included. 
 */
export class LadingPieceDimensions {
	/**
	 * sequence on shipment
	 */
	seqNbr:number = undefined;
	/**
	 * The package code for this group of pieces (lading units)
	 */
	packageCd:string = undefined;
	/**
	 * number of  lading units
	 */
	pieceCnt:number = undefined;
	/**
	 * dimensions recorded by either inspector, driver or derived by footprint. These may not be populated depending on source of dimensions
	 */
	dimensions:Dimensions = undefined;
}
/**
 * A NMFTA description and encoding for a particular commodity used by the motor freight industry
 */
export class NmfcItem {
	/**
	 * Numeric identifier for the commodity(s) description.
	 */
	itemNbr:number = undefined;
	/**
	 * Identifies a particular commodity(s) and may include allowable packaging.
	 */
	itemDesc:string = undefined;
	/**
	 * Identifies the item group that this item is subordinate to. For example Abrasives group 1020 and 1060 are subordinates and reference back to 1010
	 */
	itemReferenceGroup:number = undefined;
	/**
	 * A numeric ranking (class) assigned to the commodity(s) described based on its transportation characteristics. There are 18 classes: 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500. (Also see item/rule 110 and 420) 
	 * When 0 is shown in the Class field, it means the commodity(s) is subject to rates and regulations of the individual carrier. 
	 * 
	 * In some cases, it is also 0 when the sub items have different class codes
	 * 
	 * When Exempt is shown in the Class field, it means the commodity(s) is not subject to economic regulation. 
	 * When NotTaken is shown in the Class field, it means the commodity(s) will not be accepted for shipment.
	 */
	nmfcClass:string = undefined;
	/**
	 * All the sub-items that exist for this item. The sub, if used indicates that there is further refinement of the description for the commodity(s) embraced by this item. For example if there are two ranges that have different classes because of different densities - less than and greater than 12.
	 */
	itemSub:NmfcSubItem[] = undefined;
}
/**
 * A NMFTA description and encoding for a particular commodity used by the motor freight industry
 */
export class NmfcSubItem {
	/**
	 * The sub, if used indicates that there is further refinement of the description for the commodity(s) embraced by this item. For example if there are two ranges that have different classes. less than density per cubic foot of 12 and greater than 12
	 */
	itemSubNbr:number = undefined;
	/**
	 * Numeric identifier for the maing commodity(s) description.  This identifier appears as the first part of the commodity id - 
	 * 
	 * For example 1060.1 describes a commodity that 4. Abrasive wheels, other than pulp grinding, may be shipped in Package 2105. Belts, abrasive, in boxes, subject to Item 170 and having a density in pounds per cubic foot of: Less than 12 and 1060.2 is the same for > 12
	 */
	parentItemNbr:string = undefined;
	/**
	 * Further describes the item for this sub item.
	 */
	subItemDesc:string = undefined;
	/**
	 * A numeric ranking (class) assigned to the commodity(s) described based on its transportation characteristics. There are 18 classes: 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500. (Also see item/rule 110 and 420) 
	 * When 0 is shown in the Class field, it means the commodity(s) is subject to rates and regulations of the individual carrier. 
	 * When Exempt is shown in the Class field, it means the commodity(s) is not subject to economic regulation. 
	 * When NotTaken is shown in the Class field, it means the commodity(s) will not be accepted for shipment.
	 */
	nmfcClass:string = undefined;
}
export class Notification {
	/**
	 * identifies the reason the notification was necessary. valid values are                 1- Driver Collect   
	 * 2- NOA/DNC          
	 * 3- Construction Site
	 * 4- Residential      
	 * 5- Other
	 */
	notificationTypeCd:string = undefined;
	/**
	 * A system generated sequence number to uniquely identify a nootification for a shipment
	 */
	seq:number = undefined;
	/**
	 * date time when the notification was made. (typically a phone call)
	 */
	callDateTime:Date = undefined;
	/**
	 * This might be any special instructions for accessing customer dock
	 */
	comment:Comment = undefined;
	/**
	 * person who was contacted for the notification.
	 */
	contact:ContactInfo = undefined;
	/**
	 * Pending or Set
	 */
	notificationStatusCd:string = undefined;
	references:ReferenceNumber[] = undefined;
}
export class Packaging {
	packageCd:string = undefined;
	packageWeight:Weight = undefined;
	packageDimensions:Dimensions = undefined;
	packageVolume:Volume = undefined;
}
/**
 * Information about measurements for the pieces on a shipment.
 */
export class ShipmentDimension {
	/**
	 * The length measurement for the piece(s)
	 */
	length:number = undefined;
	/**
	 * The width measurement for the piece(s)
	 */
	width:number = undefined;
	/**
	 * The height measurement for the piece(s)
	 */
	height:number = undefined;
	/**
	 * The weight of the piece(s)
	 */
	weight:number = undefined;
	/**
	 * The number of pieces for which the measurement and weight values apply.
	 */
	piecesCnt:number = undefined;
	/**
	 * Indicates whether the piece(s) can be stacked.
	 */
	stackableInd:boolean = undefined;
}
export class ShipmentParty {
	/**
	 * A sequential number used to identify a specifc shipment party in a database.
	 */
	shipmentPartySeq:number = undefined;
	/**
	 * An internal identifier to the customer that this shipment party references.
	 */
	custInstID:string = undefined;
	/**
	 * A business key that identifies the customer that this shipment party references.
	 */
	custMadCd:string = undefined;
	/**
	 * Identifies the type of shipment party that this is: (e.g. Shipper; Consignee; etc.  This will only be used in otherShipmentParties element
	 */
	shipmentPartyTypeCd:string = undefined;
	/**
	 * This is the name and address information that was provided by the customer directly.  This could be keyed from a paper BOL or provided via EDI or some other electronic means.  This is sometimes referred to as the 'as entered' address.
	 */
	shipmentPartyBOLAddress:Address = undefined;
	/**
	 * This is the shipment party location information that comes from the customer that the shipment party was matched to.
	 */
	shipmentPartyLocation:Location = undefined;
	/**
	 * The time stamp of the last time that this shipment party was matched to a customer.
	 */
	lastMatchedDateTime:Date = undefined;
	/**
	 * Indicates whether this shipment party is a debtor for this shipment.  Note that a 'bill-to' associated with a shipper/consignee is not a debtor.  They are actiing on behalf of the debtor.
	 */
	debtorInd:boolean = undefined;
	/**
	 * Indicates whether all shipments for this shipment party are to be billed a prepaid.
	 */
	allPrepaidInd:boolean = undefined;
	/**
	 * Indicates whether the address for this shipment party, to be used on an invoice, should be the one provided by the customer instead of the one on the customer that was matched.
	 */
	useAsEnteredInd:boolean = undefined;
	/**
	 * Indicates whether this shipment party requires a 'Proof of Delivery' document to be captured and provided with an invoice.
	 */
	proofOfDlvryRequiredInd:boolean = undefined;
	/**
	 * Indicates whether a 'Proof of Delivery' image has been recorded for this shipment party for this shipment.
	 */
	proofOfDlvryImagedInd:boolean = undefined;
	/**
	 * This iindicates that type of credit that this shipment party has.  Values: Credit; Non-credit; Bad Debt; A/R Control; Voluntary; Cash Only
	 */
	creditStatusCd:string = undefined;
	/**
	 * Defines the currency that this shipment party prefers using.
	 */
	preferredCurrency:string = undefined;
	/**
	 * This is an internal identifier to the customs broker that this shipment party uses for a shipment that will cross a border.
	 */
	brokerInstID:string = undefined;
	/**
	 * This is a business key that identifies the customs broker that this shipment party uses for a shipment that will cross a border.
	 */
	brokerBusinessKey:string = undefined;
	/**
	 * This is a code that defines the relationship that a bill-to shipment party has to the shipper and the consignee.  Values are: None; Shipper; Consignee; Both
	 */
	bil2RelationshipCd:string = undefined;
	suppRef:SupplementalReference[] = undefined;
	/**
	 * Match status from CIS matching. Valid Values :
	 * The following values ARE ACTIVE for Forward Sync:
	 * Spaces - Initial value, matching activity has not yet occurred. 
	 * '70' - Full Match 
	 * '60' - Un-matched (valid name / address but does not exist in CIS)
	 * The following values ARE NOT ACTIVE for Forward Sync:
	 * '15' - Possible matches found
	 * '30' - Invalid address (Bad US postal address as per Code-1)
	 * '50' - Blank bill2 - no bill-to was entered
	 */
	mchStatCd:string = undefined;
	/**
	 * Contains the initials of the Review Clerk who performed matching on a pro, or forced the MAD code on a pro to a different MAD using a Pro Override.
	 */
	mchInitTxt:string = undefined;
	/**
	 * Used to group customer oriented service reports and manifests for direct customer access via the online system.
	 */
	directCd:string = undefined;
	/**
	 * Flag to indicate whether the Bill-To is related to the Shipper.
	 */
	bil2RelToShipperInd:boolean = undefined;
	/**
	 * Flag to indicate whether the Bill-To is related to the Consignee.
	 */
	bil2RelToConsigneeInd:boolean = undefined;
	/**
	 * Contains the customer key to an uncoded customer in the customer component. These are pickup and delivery locations that have been a part of at least one shipment in the Con-way system. (Note that they are not currently stored in the P and D table in the customer component.) They do not have an assigned MAD code (uncoded) and are therefore identified and matched separately so that we know when we service the same physical location.
	 */
	alternateCustNbr:string = undefined;
	/**
	 * Phone information for the party on the shipment.
	 */
	phone:Phone = undefined;
}
export class ShipmentPartyBasic {
	/**
	 * An internal identifier to the customer that this shipment party references.
	 */
	custInstID:string = undefined;
	/**
	 * A business key that identifies the customer that this shipment party references.
	 */
	custMadCd:string = undefined;
	/**
	 * Identifies the type of shipment party that this is: (e.g. Shipper; Consignee; etc.  This will only be used in otherShipmentParties element
	 */
	shipmentPartyTypeCd:string = undefined;
	/**
	 * This is the name and address information that was provided by the customer directly.  This could be keyed from a paper BOL or provided via EDI or some other electronic means.  This is sometimes referred to as the 'as entered' address.
	 */
	shipmentPartyBOLAddress:Address = undefined;
	suppRef:SupplementalReference[] = undefined;
}
/**
 * A generic representation of an indicator for special treatment of a delivery/pickup.
 */
export class ShipmentSpecialServiceSummary {
	/**
	 * The types of special services.
	 */
	specialService:ShipmentSpecialServiceCd = undefined;
	/**
	 * A boolean value indicating if the indicator type is applicable.
	 */
	applicableInd:boolean = undefined;
}
export class Tender {
	tenderOfferDateTime:Date = undefined;
	/**
	 * Expiration of tender offer
	 */
	tenderExpDateTime:Date = undefined;
	/**
	 * Tender response deadline
	 */
	tenderRespDateTime:Date = undefined;
	earliestReqPkupDateTime:Date = undefined;
	latestReqPkupDateTime:Date = undefined;
	earliestDlvryDateTime:Date = undefined;
	latestDlvryDateTime:Date = undefined;
	tenderResponseCd:string = undefined;
	tenderRejectionReasonCd:string = undefined;
	tenderID:string = undefined;
}
export class XrtAttributeFilter {
	equals:string = undefined;
	min:string = undefined;
	max:string = undefined;
	startsWith:string = undefined;
	values:string[] = undefined;
	query:string = undefined;
	exclude:string = undefined;
	radius:string = undefined;
	radiusMeasurementUnit:string = undefined;
	lat:number = undefined;
	lon:number = undefined;
	points:XrtGeoPoint[] = undefined;
	convert:boolean = undefined;
	contains:string = undefined;
}
export class XrtGeoPoint {
	lat:number = undefined;
	lon:number = undefined;
}
export class XrtSortExpression {
	IsDescendingSort:boolean = undefined;
	Column:string = undefined;
	direction:string = undefined;
}
export class XrtSearchQueryHeader {
	pageNumber:number = undefined;
	sortExpressions:XrtSortExpression[] = undefined;
	pageSize:number = undefined;
	fields:string = undefined;
}
export class XrtSearchRespHeader {
	/**
	 * Duration of query
	 */
	duration:number = undefined;
	/**
	 * Current page number of result set
	 */
	pageNumber:number = undefined;
	/**
	 * Number of rows contained in a page
	 */
	pageSize:number = undefined;
	/**
	 * Number of results
	 */
	resultCount:number = undefined;
}
export class XrtExportQueryHeader {
	fields:string = undefined;
	fileType:string = undefined;
}
/**
 * Information about global accessorials and how to calculate their charge amounts
 */
export class AccessorialChargePricing {
	/**
	 * This code also identifies the accessorial charge.
	 */
	accessorialCd:string = undefined;
	/**
	 * Indicates whether the charge for the accesorial service is discountable or not.
	 */
	accessorialDiscInd:boolean = undefined;
	/**
	 * This is the unit of measure for specifying the maximum and minimum limits for the accessorial service.
	 */
	accessorialUom:string = undefined;
	/**
	 * 1- Flat Rate per UOM
	 * 2 - % of Specified Charge type
	 */
	chargeTypeCd:string = undefined;
	/**
	 * Indicates whether the AC is a literal or not.
	 */
	literalInd:boolean = undefined;
	/**
	 * This is the maximum charge amount that will be charged if the accessorial service was performed.
	 */
	overrideMaxChgAmt:MonetaryAmount = undefined;
	/**
	 * The maximum units for which this charge applies
	 */
	overrideMaxUnitCount:number = undefined;
	/**
	 * This indicates if the charges for the accessorial service are tiered by weight or other units of measure.
	 */
	overrideMinChgAmt:MonetaryAmount = undefined;
	/**
	 * The minimum units for which this charge applies.
	 */
	overrideMinUnitCount:number = undefined;
	/**
	 * This indicates if the accessorial charge will always be charged on every shipment for the customer that gets rated using this ruleset irrespective of whether the Accessorial service was performed or not.
	 * Valid Values:
	 * Y - Always Apply
	 * N- Apply only when service is performed
	 */
	alwaysApplyInd:string = undefined;
	/**
	 * This is the override type that overrides the default AC service. Based on this type code charges will be derived. i.e., charges can be from standard default or the override value.
	 * Valid Values: 
	 * 1. Selected without changes (Charges will float)
	 * 2. Modified (Charges are frozen as modified)
	 * 3. Waived (Charges are frozen at 0.00)
	 * 4. Frozen (Charges are fozen at the default value during ruleset setup)
	 * 5. Modified-Float (Charges will float, but will use min/max units from override)
	 */
	overrideTypeCode:string = undefined;
	/**
	 * Specifies the type of charge which is used for calculating the charges for the accessorial service using the RATE_PCT. 
	 * Valid Values: 
	 * 
	 * 1: COD Amount
	 * 2: Linehaul Charges
	 * 3: Advance Amount
	 * 4: Order Notify Amount
	 * 5: Excess Value Amount Declared
	 */
	overridePctTypeCode:string = undefined;
	/**
	 * This is the rate at which the accessorial service is charged. This is mutually exclusive with ratePct and will not be specified if ratePct is specified.
	 */
	overrideAmt:MonetaryAmount = undefined;
	/**
	 * This specfies the percentage to be used for calculating the charges for the accessorial service. Mutually exclusive with rateAmt
	 */
	overridePct:number = undefined;
	termCreditCd:string = undefined;
	/**
	 * This indicates if the charges for the accessorial service are tiered by weight or other units of measure.
	 */
	tieredInd:boolean = undefined;
	tierSeqNbr:number = undefined;
}
/**
 * Accessorial overrides from the Pricing Agreement
 */
export class AccessorialOverride {
	/**
	 * accessorial code to be overridden
	 */
	accessorialCd:string = undefined;
	/**
	 * Valid Values: 
	 * 1. Selected without changes (Charges will float)
	 * 2. Modified (Charges are frozen as modified)
	 * 3. Waived (Charges are frozen at 0.00)
	 * 4. Frozen (Charges are fozen at the default value during ruleset setup)
	 */
	accOverrideTypeCd:string = undefined;
}
/**
 * Information about the Contract Terms for the Pricing Agreement
 */
export class AgreementContractTerm {
	/**
	 * Information about the Pricing Agreement
	 */
	prcAgreement:PricingAgreementId = undefined;
	/**
	 * This indicates whether the discount percentage will be displayed in the discount line text on the customers invoice. For example, whether the discount line should be
	 * CON-WAY DISOCUNT SAVES YOU 70% or 
	 * CON-WAY DISCOUNT SAVES YOU
	 * 
	 * Note: Even if the indicator is turned on, it will not display the discount on a minimum charge shipment.
	 * 
	 * Valid Values: 
	 * Y- Display Discount
	 * N- Do not Display Discount
	 */
	displayDiscountInd:string = undefined;
	/**
	 * This is the number of days the customer has for paying the freight charges. This will update the default payment terms in CIS when the agreement is approved. When the agreement is expired or cancelled it will reset the CIS value to default 15 days.
	 */
	paymentTerm:number = undefined;
	/**
	 * This pallet weight value is used for two purposes:
	 * The first is to provide an average weight per pallet for calculating the total shipment pallet weight tolerance for Con-Firm / re-weigh when the PLLT_WAIVER_IND is set to Y. This is also use to indicate the average weight per pallet for calculating reduced weight (reduced charge) on palletized shipments when the REDUCE_PLLT_WGT_IND is set to Y.
	 */
	avgPalletWeight:Weight = undefined;
	/**
	 * Indicates that the customer has a pallet weight allowance for re-weigh purposes. A value of 'Y' also requires that there to be a value in AVG_PLLT_WGT_LBS. Some customers may have a reduced weight (reduced charge) allowance for the weight of their pallets (see REDUCE_PLLT_WGT_IND ), but that same customer doesn't necessarily get a pallet weight allowance for re-weigh purposes, unless this indicator is set.
	 */
	palletWaiverInd:string = undefined;
	/**
	 * Indicates that the customer is not be charged for the weight of their pallets. A value of 'Y' also requires that there to be a value in AVG_PLLT_WGT_LBS. Some customers may have a pallet weight allowance for re-weigh purposes (see PLLT_WAIVER_IND), but that same customer doesn't necessarily get a reduced weight (reduced charge) for the weight of the pallet(s) on their shipments, unless this indicator is set.
	 */
	reducedPalletWgtInd:string = undefined;
}
export class ClassOverrideBasic {
	/**
	 * commodityClassCode
	 */
	commodityClass:string = undefined;
	/**
	 * Commodity class that overrides the commodityClass
	 */
	overrideClass:string = undefined;
}
/**
 * Commodity class overrides in the Pricing Agreement
 */
export class ClassOverridexx {
	/**
	 * Commodity NMFC class
	 */
	commodityClass:string = undefined;
	/**
	 * NMFC item that along with class uniquely defines the type of Freight
	 */
	commodityNmfcItem:string = undefined;
	/**
	 * FAK that overrides the NMFC class code and NMFC Item
	 */
	commodityFak:string = undefined;
}
/**
 * Information about the Class Rate Ruleset
 */
export class ClassRate {
	/**
	 * Indicates whether any AC has been overridden for the ruleset
	 */
	acOverrideInd:string = undefined;
	agrmtContractTerm:AgreementContractTerm = undefined;
	/**
	 * Identifies the rate audit queue the shipments will be put into if they are rated using this rule set.
	 */
	auditQueueName:string = undefined;
	/**
	 * Indicates whether any Class has been overridden for the ruleset. Valid values are Y or N
	 */
	classOverrideInd:string = undefined;
	classOverride:ClassOverrideBasic[] = undefined;
	commodityPricingInd:string = undefined;
	commodityPricing:CommodityPricing[] = undefined;
	corpFsc:FuelSurcharge = undefined;
	/**
	 * This indicates if this ruleset should be used to rate a a shipment even if this ruleset is not from the debtor of the shipment and the debtor of the shipment has his own ruleset that is applicable to the shipment.
	 */
	debtorOverrideInd:string = undefined;
	/**
	 * Indicates if the ruleset is defined with default discount. Values are Y or N
	 */
	defaultDiscInd:string = undefined;
	/**
	 * Indicates whether Deferred Override exists.
	 */
	deferredOverrideInd:string = undefined;
	/**
	 * Indicates if the discounts are defined by weight bracket. In this case the value in the DISC_PCT attribute should be ignored
	 */
	discByWgtInd:string = undefined;
	discountPgm:DiscountPgm = undefined;
	discountTier:DiscountTier[] = undefined;
	/**
	 * Identifies the entry queue the shipments will be put into if they qualify to be rated using this rule set.
	 * 
	 * If this is non blank, the pro will not be auto rated.
	 */
	entryQueueName:string = undefined;
	/**
	 * This is the discount that will be applied when the fall back tariff is used to rate the shipment
	 */
	fallBackDiscPct:number = undefined;
	/**
	 * This field is applicable for frozen rate bases or tariffs. When this rule set cannot rate a shipment using a frozen rate base the fall back tariff will be used to rate the shipment.
	 */
	fallBackTariffName:string = undefined;
	/**
	 * Indicates whether the FSC has been overridden or waived for the ruleset. 
	 * Valid Values: 
	 * 
	 * 1- FSC neither Waived nor Overridden 
	 * 2 - FSC Waived
	 * 3 - FSC Overridden (Other FSC Tariff)
	 * 4 - FSC Overridden (CNWY190 Tariff)
	 * 5 - Universal (this value indicates the overrides are defined in universal ruleset i.e., rs seq nbr zero record)
	 */
	fscOverrideCd:string = undefined;
	/**
	 * Name of the tariff that governs how the fuel surcharge will be applied by the rule set.
	 */
	fscTariffName:string = undefined;
	/**
	 * Indicates that the bill should be held for manual processing following any autorating.
	 */
	holdForManualRateInd:string = undefined;
	/**
	 * Indicates whether the rule set applies on various combinations of direct and interline shipments
	 * Valid Values: 
	 * 1 - Applies on Advance Carrier Interline only
	 * 2 - Applies on Direct and Advance Carrier Interline only
	 * 3 - Applies on Advance and Beyond interline but not on direct shipments
	 * 4 - Applies on all shipments
	 * 5 - Applies on Beyond Interline shipments only
	 * 6 - Applies on Direct Shipments only
	 * 7 - Applies on Direct and Beyond Interline only
	 */
	interlineApplyCd:string = undefined;
	/**
	 * The discount percentage to be applied for lineal foot rates. This is only valid if the Lineal foot rates are overridden
	 */
	linealFtDiscPct:number = undefined;
	/**
	 * Indicates whether a shipment qualified to be rated using this rule set is exempt from lineal foot rates or not.
	 */
	linealFtExemptInd:string = undefined;
	/**
	 * Indicates whether the lineal foot has been overridden for the ruleset.
	 */
	linealFtOverrideInd:string = undefined;
	linealFtRate:LinealFeetRate[] = undefined;
	/**
	 * Indicates lineal foot queuing is required for shipments rated with this ruleset 
	 * 
	 * Valid Values: 
	 * 
	 * Y- Yes (automatic queuing of lineal foot shipments)
	 * N - No (no LF queuing is required).
	 */
	linealFtQueInd:string = undefined;
	/**
	 * extra services that are not accessorial fees that are associated with a customer's pricing
	 */
	miscSvcOverride:MiscSvcOverride = undefined;
	/**
	 * Indicates whether NMFC has been overridden or not. Values are Y or N
	 */
	nmfcOverrideInd:string = undefined;
	/**
	 * NMFC numbers for which the class is overridden to a different class value.
	 */
	nmfcOverride:NmfcClassOverride[] = undefined;
	nonTieredAccessorial:AccessorialChargePricing[] = undefined;
	/**
	 * This indicates if another parties discounts or the default discounts applicable to certain regions should apply to the bill if these dicounts result in a lower rate for the customer.
	 */
	overrideAllowedInd:string = undefined;
	ratingTariffName:string = undefined;
	/**
	 * Effective date of the Ruleset customer record
	 */
	rulesetCustEffDate:Date = undefined;
	/**
	 * Expiry date of ruleset customer record
	 */
	rulesetCustExpDate:Date = undefined;
	/**
	 * Effective Date of the Ruleset
	 */
	rulesetEffDate:Date = undefined;
	/**
	 * Expiry date of the Ruleset
	 */
	rulesetExpDate:Date = undefined;
	/**
	 * Ruleset Sequence Number
	 */
	rulesetNbr:number = undefined;
	/**
	 * Ruleset rating instruction
	 */
	ratingInstr:string = undefined;
	/**
	 * Name of the rules tariff applicable to the rule set
	 */
	rulesTariffName:string = undefined;
	/**
	 * This is the factor by which the rates from the rating tariff will be multiplied while rating a shipment. The Pricing analysts can set this field. The AEs and NAEs will be allowed to view this field but they will not be able to change it.
	 */
	tariffFactor:number = undefined;
	/**
	 * This is the factor by which the rates from the rating tariff minimum charge will be multiplied while rating a shipment. The Pricing analysts can set this field. The AEs and NAEs will be allowed to view this field but they will not be able to change it.
	 */
	tariffMcFactor:number = undefined;
	tieredAccessorial:AccessorialChargePricing[] = undefined;
	weightRestriction:WeightRestriction = undefined;
	/**
	 * Indicates whether any weight restrictions exist for the ruleset
	 */
	weightRestrInd:string = undefined;
}
/**
 * Information about the commodity rates for a ruleset
 */
export class CommodityPricing {
	/**
	 * A system assigned sequential number to uniquely
	 * identify the rate entries
	 */
	seqNbr:number = undefined;
	/**
	 * Identifies the lowest unit count for which the commodity pricing is to be applied
	 */
	lowRangeCnt:number = undefined;
	/**
	 * Identifies the highest unit count for which the commodity pricing is to be applied.
	 */
	highRangeCnt:number = undefined;
	/**
	 * Minimum Charge Amount - A shipment rated using this ruleset's commodity pricing may not be charged less than this amount.
	 */
	minChargeAmt:MonetaryAmount = undefined;
	/**
	 * Price per unit (Ex. $35 per each pallet).
	 */
	unitRate:MonetaryAmount = undefined;
	/**
	 * Rate type is used for various total charge calculation types.
	 * 
	 * Valid values:
	 * 1 - Per Unit (Ex. Total Charge will be $150.00 (50*3), rate per unit is $50 and 3 unit are used).
	 * 2 - Total Charges (Ex. Total Charge will be $60 for one unit, $100 for two units, $140 for three units). 
	 * 3 - Both (Ex. Total charge will be $ 250 (175+60) for 5 units, i.e., for $175 for first four units and $60 per each additional unit).
	 */
	rateTypeCode:string = undefined;
	/**
	 * Desc: 
	 * 1. When rt_typ_cd is '2', this is the total charge for the all the commodity units together.
	 * 2. When rt_typ_cd is '3', this is the base rate that will be added to the (unit_rt * (total units - base_rt_unit_cnt)).
	 */
	baseRate:MonetaryAmount = undefined;
	/**
	 * This is the number of units for which the base rate is applied. It must be specified when rt_typ_cd is '3'.
	 */
	baseRateUnitCount:number = undefined;
	/**
	 * This indicates how the fuel surcharge to be applied.
	 * 
	 * Valid Values:
	 * 0 - FSC applies.
	 * 1 - FSC is waived for all the shipments.
	 * 2 - FSC is waived for intra SIC shipments only.
	 * 3 - FSC is waived for intra state shipments only.
	 * 4 - FSC is waived for intra US shipments only.
	 * 5 - FSC is waived for intra Canadian shipments only.
	 */
	fscCode:string = undefined;
}
/**
 * Information about the Discount Program related to a Ruleset
 */
export class DiscountPgm {
	/**
	 * System assigned sequential number to unqiuely identify each discount program for a ruleset
	 */
	discountPgmSeqNbr:number = undefined;
	/**
	 * Applicable discount in %. This attribute does not have a value when the discounts are by weight bracket
	 */
	discountPct:number = undefined;
	/**
	 * Applicable discount in %, when minimum charges are applied to a line item.
	 */
	minChrgDiscountPct:number = undefined;
	/**
	 * Absolute Minimum Charge Amount - A shipment rated using the rule set may not be charged less than this amount.
	 */
	absMinChargeAmt:number = undefined;
}
/**
 * Tiered discount structure
 */
export class DiscountTier {
	/**
	 * This is the lower limit of the range of weight or volume for which the ruleset is applicable
	 */
	lowRange:number = undefined;
	/**
	 * This is the higher limit of the range of the weight or volume for which the ruleset is applicable
	 */
	highRange:number = undefined;
	/**
	 * Applicable discount pct
	 */
	discountPct:number = undefined;
	rngLabelText:string = undefined;
}
/**
 * Information about the Fuel Surcharge 
 */
export class FuelSurcharge {
	typeCode:string = undefined;
	chargeAmt:MonetaryAmount = undefined;
	pct:number = undefined;
	tlFactorNbr:number = undefined;
}
/**
 * Information about the Lineal Feet pricing from the Ruleset
 */
export class LinealFeetRate {
	/**
	 * A system assigned sequential number to uniquely identify each lineal foot rate line for a ruleset.
	 */
	seqNbr:number = undefined;
	/**
	 * This identifies the trailer type to which the overridden lineal foot rate applies.
	 * 
	 * Valid Values:
	 * 1 - Single 28 Feet
	 * 2 - Double 28 Feet
	 * 3 - Single 40 Feet
	 */
	trailerTypeCode:string = undefined;
	/**
	 * This specifies the minimum loading space on the trailer the shipment should occupy to be rated using this rule set.
	 */
	minLoadSpaceFt:number = undefined;
	/**
	 * This specifies the maximum loading space on the trailer the shipment should occupy to be rated using this rule set.
	 */
	maxLoadSpaceFt:number = undefined;
	/**
	 * This is the rate per mile lineal feet rate.
	 */
	rateAmt:MonetaryAmount = undefined;
	/**
	 * This is the minimum amount that will be charged for the shipment that qualifies for rating using these overridden rate.
	 */
	minChargeAmt:MonetaryAmount = undefined;
	/**
	 * This is the minimum miles for which this rates apply.
	 */
	minMiles:number = undefined;
	/**
	 * This is the maximum miles for which this rates apply.
	 */
	maxMiles:number = undefined;
	/**
	 * Defines the unit of measure for the rate
	 * 
	 * Valid Values: 
	 * 
	 * 1 - per mile per feet
	 * 2 - per mile
	 */
	rateUom:string = undefined;
}
/**
 * Information about extra services that are not accessorial fees that are associated with a customer's pricing
 * 
 * 
 */
export class MiscSvcOverride {
	/**
	 * A system assigned sequential number to uniquely
	 * identify each service for a Pricing rule set.
	 */
	seqNbr:number = undefined;
	/**
	 * Identifies the type of charge for the Service. For certain type of services it may be a discount amount or percentage that will be applied to the shipment. For other type of services it may be an extra charge amount or percentage applied to the shipment.
	 * 
	 * Valid Values: 
	 * 1. Deferred Service Discount
	 */
	chargeTypeCode:string = undefined;
	/**
	 * This is the amount that will either be charged or discounted for this service based on the charge type.
	 */
	svcAmt:MonetaryAmount = undefined;
	/**
	 * This is the percentage of total charge of the shipment that will be either discounted or applied to the bill for the service based on the chareg type
	 */
	svcPct:number = undefined;
}
/**
 * NMFC Clase overrides in the Pricing Agreement
 */
export class NmfcClassOverride {
	/**
	 * A system assigned sequential number to uniquely
	 * identify each NMFC number included or excluded for a Pricing rule set.
	 */
	seqNbr:number = undefined;
	/**
	 * Commodity NMFC class
	 */
	commodityClass:string = undefined;
	/**
	 * NMFC class that overrides the commodity class
	 */
	overrideClass:string = undefined;
}
/**
 * NMFC Item Number overrides in the Pricing Agreement
 */
export class NmfcItemOverride {
	/**
	 * Commodity NMFC Item Number
	 */
	nmfcItemNbr:string = undefined;
	/**
	 * Override NMFC Item Number for commodity
	 */
	nmfcItemOverride:string = undefined;
}
/**
 * Information about the Id of the Pricing Agreement
 */
export class PricingAgreementId {
	/**
	 * This is the unique database key of the agreement.
	 */
	agreementId:string = undefined;
	/**
	 * The version of the  pricing agreement
	 */
	agreementCustVersion:string = undefined;
	/**
	 * The sequence number within the version of pricing agreement
	 */
	agreementSeqNbr:string = undefined;
	/**
	 * The instance ID of the customer that negotiated this pricing agreement
	 */
	leadAcctId:string = undefined;
	/**
	 * Values :
	 * 1 - LTL Pricing
	 * 2 - Spot Quote
	 * 3 - Pallet Pricing
	 */
	paTypeCd:string = undefined;
	/**
	 * Indicates if Pricing Agreement is active. Valid values are Y or N.This is set to N when either the Pricing Agreement Active Ind = N or the Ruleset Active Ind = N
	 */
	activeInd:string = undefined;
}
/**
 * Ruleset Identification Information
 */
export class RulesetId {
	agreementId:string = undefined;
	/**
	 * Ruleset Sequence Number
	 */
	rulesetNbr:number = undefined;
}
/**
 * Information about the Weight restrictions in class rates ruleset
 */
export class WeightRestriction {
	minWgt:Weight = undefined;
	maxWgt:Weight = undefined;
	minWgtPerPce:Weight = undefined;
	maxWgtPerPce:Weight = undefined;
}
export class PalletCommodity {
	palletTypeCd:string = undefined;
	palletCount:number = undefined;
	palletWeight:number = undefined;
	palletStackableInd:boolean = undefined;
	palletVolume:number = undefined;
	palletVolumeUom:string = undefined;
}
/**
 * Indicates whose ruleset was actually used for rating: S - Shipper; C - Consignee; B - Bill-to (Third Party).
 */
export class PricingAgreement {
	prcAgreementInstId:number = undefined;
	prcRulesetNbr:number = undefined;
	prcAgreementNbrTxt:string = undefined;
	ratingCustomerNbr:number = undefined;
	prcAgreementCustomerAcctId:string = undefined;
	overrideFactor:number = undefined;
	/**
	 * Pricing Agreement Absolute Minimum Charge Amount
	 */
	agreementAmcAmt:number = undefined;
	/**
	 * Pricing Agreement Effective Date
	 */
	pricingAgreementEffectiveDate:Date = undefined;
	/**
	 * Offshore Tariff
	 */
	offshoreTariff:string = undefined;
	/**
	 * Indicates whose ruleset was actually used for rating: S - Shipper; C - Consignee; B - Bill-to (Third Party).
	 */
	rulesetOwnerCd:string = undefined;
}
export class Rating {
	rateRequestTypeCd:RateRequestTypeCd = undefined;
	rateRequestSubTypeCd:string = undefined;
	requestId:number = undefined;
	cts599RatingInd:boolean = undefined;
	rerateInd:boolean = undefined;
	requestAccOnlyInd:boolean = undefined;
	disallowDeficitWeightRatingInd:boolean = undefined;
	doNotApplyFakInd:boolean = undefined;
	doNotOverrideFscInd:boolean = undefined;
	doNotApplySpecialDiscountInd:boolean = undefined;
	useClassRatesInd:boolean = undefined;
	overrideDiscountInd:boolean = undefined;
	consumerCallingProgram:string = undefined;
	reducedWeightCommodityLineCnt:number = undefined;
}
export class RatingAccessorial {
	sequenceNbr:number = undefined;
	accessorialCd:string = undefined;
	/**
	 * Description of the Accessorial
	 */
	accessorialDesc:string = undefined;
	minChargeInd:boolean = undefined;
	/**
	 * Accessorial Amount
	 */
	chargeAmt:number = undefined;
	unitRate:number = undefined;
	unitQty:number = undefined;
	maxChargeInd:boolean = undefined;
	chargeTypeDesc:string = undefined;
	rateTypeCd:string = undefined;
	literalInd:boolean = undefined;
}
/**
 * ***Needs Review
 */
export class RatingCommodityLine {
	/**
	 * Might be versions of a commodity line - original, corrected, as-rated
	 */
	commodityLineTypeCd:string = undefined;
	/**
	 * sequence on shipment
	 */
	shipmentSequenceNbr:number = undefined;
	/**
	 * ***Needs Review - will there be dimensions on commodities?
	 */
	dimensions:Dimensions = undefined;
	/**
	 * commodity and packing info including hazmat
	 */
	commodity:CommodityInfo = undefined;
	commoditySubLines:CommodityInfo[] = undefined;
	/**
	 * this will have the rate and charge information. if weight was changed based on rated as, then the new weight will be in quantity. The class will be reflected in asRatedNmfcClass
	 */
	charge:Charge = undefined;
	/**
	 * this is an FAK that has overridden the as entered class when rated
	 */
	asRatedNmfcClass:string = undefined;
	/**
	 * This is value declared by the shipper. This might have implications for the claims.
	 */
	declaredValue:MonetaryAmount = undefined;
	/**
	 * Commodity Line Override Charge Amount.
	 */
	overrideCharge:MonetaryAmount = undefined;
	/**
	 * These are refenence numbers provided by the party on the bill (shipper) specific to this commodity line.
	 */
	suppRefs:SupplementalReference[] = undefined;
	references:ReferenceNumber[] = undefined;
}
/**
 * Account IDs and address
 */
export class RatingParty {
	/**
	 * Database key for customer
	 */
	acctInstId:string = undefined;
	/**
	 * Business key for customer
	 */
	acctMadCd:string = undefined;
	/**
	 * Primary name
	 */
	name:string = undefined;
	/**
	 * Applies to mailing address of a person, where the address is actually in another entity's name.
	 */
	careOfName:string = undefined;
	/**
	 * Address line that includes Street name, direction and optionally building number, unit# etc. 
	 * e.g. 1000 SW Broadway st
	 */
	addressLine1:string = undefined;
	/**
	 * Address line that includes Street name, direction and optionally building number, unit# etc. 
	 * e.g. 1000 SW Broadway st
	 */
	addressLine2:string = undefined;
	/**
	 * PO Box typically applies to a mailing address. This is the standard PO Box# of an address.
	 */
	postOfficeBox:string = undefined;
	/**
	 * City Name part of the address.
	 */
	cityName:string = undefined;
	/**
	 * County Name part of the address.
	 */
	countyName:string = undefined;
	/**
	 * Standard Postal abbreviation for state name. Example: OR for Oregon.
	 */
	stateCd:string = undefined;
	/**
	 * standard Postal abbreviation for a country. Example: CA for Canada.
	 */
	countryCd:string = undefined;
	/**
	 * Postal Code are aka Zip code in USA. US and Mexico use 5 digit postal codes. Canada uses 6 char postal codes.
	 */
	postalCd:string = undefined;
	/**
	 * This is a 4 digit extension to US 5 digit zip code.
	 */
	usZip4:string = undefined;
}
export class RatingRequest {
	chargeToCd:ChargeToCd = undefined;
	guaranteedInd:boolean = undefined;
	overrideRatingTariff:string = undefined;
	advanceScacCd:string = undefined;
	advanceRevenueAmt:number = undefined;
	beyondScacCd:string = undefined;
	beyondRevenueAmt:number = undefined;
	overrideDiscountPct:number = undefined;
	overrideMinimumChargeDiscountPct:number = undefined;
	overrideFscPct:number = undefined;
	overrideFuelPrice:number = undefined;
	rulesTarifffDate:string = undefined;
	fuelTariffDate:string = undefined;
	discountSourceCd:string = undefined;
	overrideRulesTariff:string = undefined;
	overrideFuelTariff:string = undefined;
	classOverride:string = undefined;
	overrideAbsoluteMinimumChargeAmt:number = undefined;
	/**
	 * The Dynamic Pricing external rule ID for Dynamic Pricing evaluation.
	 */
	dpExternalRuleId:number = undefined;
	/**
	 * The Dynamic Pricing quote number for Dynamic Pricing evaluation.
	 */
	dpQuoteNbr:string = undefined;
}
export class RatingResponse {
	/**
	 * Density Linehaul Charge Amount
	 */
	densityLinehaulAmt:number = undefined;
	/**
	 * Density Calculated Class Code
	 */
	densityCalculatedClassCd:string = undefined;
	/**
	 * Density Source Type Code
	 */
	densitySourceTypeCd:string = undefined;
	/**
	 * Commodity As Weight or Deficit Weight Description
	 */
	asDeficitWeightDescription:string = undefined;
	/**
	 * Commodity As Weight or Deficit Weight
	 */
	asDeficitWeight:number = undefined;
	/**
	 * Commodity As Weight or Deficit Rate
	 */
	asDeficitWeightRate:number = undefined;
	/**
	 * Commodity As Weight or Deficit Amount
	 */
	asDeficitWeightAmt:number = undefined;
	/**
	 * Rated As Weight
	 */
	ratedAsWeight:number = undefined;
	/**
	 * Rating Tariff Name
	 */
	ratingTariff:string = undefined;
	/**
	 * Rating Tariff Version
	 */
	tariffVersion:string = undefined;
	/**
	 * Rate Base Tariff Name
	 */
	infoRatingTariff:string = undefined;
	/**
	 * Rules Tariff Name
	 */
	rulesTariff:string = undefined;
	/**
	 * Rules Tariff Version
	 */
	rulesTariffVersion:number = undefined;
	/**
	 * Fuel Surcharge Tariff Name
	 */
	fuelSurchargeTariff:string = undefined;
	/**
	 * Rating Currency Code
	 */
	ratingCurrencyCd:string = undefined;
	/**
	 * Applicable Discount Percentage
	 */
	applicableDiscountPct:number = undefined;
	/**
	 * Actual Discount Percentage
	 */
	actualDiscountPct:number = undefined;
	/**
	 * Fuel Price Amount
	 */
	fuelPriceAmt:number = undefined;
	/**
	 * Fuel Price Effective Date
	 */
	fuelPriceEffectiveDate:string = undefined;
	/**
	 * FSC Rate Type Unit of Measue
	 */
	fscRateUom:string = undefined;
	/**
	 * FSC Rate Type
	 */
	fscRateNbr:number = undefined;
	/**
	 * FSC Overrride Code
	 */
	fscOverrideInd:string = undefined;
	/**
	 * Discount Source Code
	 */
	discountSourceCd:string = undefined;
	/**
	 * Currency Exchange Rate
	 */
	currencyExchangeRate:number = undefined;
	/**
	 * Class Override Code
	 */
	classOverrideCd:string = undefined;
	/**
	 * Multiple Class Indicator
	 */
	multipleClassInd:string = undefined;
	/**
	 * Invoicing Currency Code
	 */
	invoicingCurrencyCd:InvoiceCurrencyCd = undefined;
	/**
	 * Reduced Weight
	 */
	reducedWeight:number = undefined;
	/**
	 * Rating Origin Zip Code
	 */
	ratingOrigZipCd:string = undefined;
	/**
	 * Rating Destination Zip Code
	 */
	ratingDestZipCd:string = undefined;
	/**
	 * Tariff Type Code
	 */
	tariffTypeCd:string = undefined;
	/**
	 * Revenue Amount
	 */
	ctsRevenueAmt:number = undefined;
	/**
	 * Revenue Amount in USD
	 */
	ctsRevenueAmtInUsd:number = undefined;
	/**
	 * Volume Shipment Base Charge Amount
	 */
	trueLtlPricingBaseChargeAmt:number = undefined;
	/**
	 * Volume Shipment Factor Number
	 */
	trueLtlPricingFactorNbr:number = undefined;
	/**
	 * Volume Shipment Miles Rate
	 */
	trueLtlPricingPerMileRate:number = undefined;
	/**
	 * Absolute Minimum Charge Amount
	 */
	absoluteMinimumChargeAmt:number = undefined;
	/**
	 * Rating Tariff Effective Date
	 */
	ratingTariffEffectiveDate:string = undefined;
	/**
	 * Absolute Minimum Charge Indicator
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * Corporate Absolute Minimum Charge Indicator
	 */
	corporateAbsoluteMinimumChargeInd:string = undefined;
}
export class RatingRule {
	/**
	 * The name for the rate rules.
	 */
	rateRulesName:string = undefined;
	/**
	 * The value associated with the rate rules.
	 */
	rateRulesValue:string = undefined;
}
export class RatingShipmentRequest {
	proNbr:string = undefined;
	pickupDate:string = undefined;
	estimatedDeliveryDate:string = undefined;
	destTerminalSic:string = undefined;
	origTerminalSic:string = undefined;
	materializedPcs:number = undefined;
	hazmatInd:boolean = undefined;
	freezableInd:boolean = undefined;
	totalPalletCnt:number = undefined;
	shipmentInstId:number = undefined;
	codAmt:number = undefined;
	manualRatingRequiredInd:boolean = undefined;
	invoiceCurrencyCd:InvoiceCurrencyCd = undefined;
	spotQuoteNbr:number = undefined;
	floorSpace:number = undefined;
	floorSpaceUom:string = undefined;
	equipmentTypeCd:EquipmentTypeCd = undefined;
	shipmentValue:number = undefined;
	dimensions:Dimensions = undefined;
	diameter:number = undefined;
	grossWeight:Weight = undefined;
	linealFootTotalNbr:number = undefined;
	linealUom:string = undefined;
	totalVolume:number = undefined;
	totalVolumeUom:string = undefined;
}
export class RatingShipmentResponse {
	/**
	 * codAmt
	 */
	codAmt:number = undefined;
	/**
	 * Shipper To Consignee Miles
	 */
	totalDistance:number = undefined;
	/**
	 * Number of Trailers
	 */
	trailerCnt:number = undefined;
	/**
	 * Offshore US Port Identifier
	 */
	offshoreUSPort:string = undefined;
	/**
	 * Offshore Zone Code
	 */
	offshoreZoneCd:number = undefined;
	/**
	 * Carrier SCAC Code
	 */
	scac:string = undefined;
	/**
	 * Carrier SCAC Name
	 */
	carrierName:string = undefined;
	/**
	 * Warranty Eligible Indicator
	 */
	warrantyInd:boolean = undefined;
	/**
	 * Spot Quote instance id
	 */
	spotQuoteInstId:number = undefined;
}
export class RatingTotal {
	/**
	 * Total Accessorial Amount
	 */
	totalAccessorialAmt:number = undefined;
	/**
	 * Total Charge Amount
	 */
	totalChargeAmt:number = undefined;
	/**
	 * Linehaul Charge Amount
	 */
	linehaulChargeAmt:number = undefined;
	/**
	 * Total Discount Amount
	 */
	totalDiscountAmt:number = undefined;
	/**
	 * Total FSC Amount
	 */
	totalFscAmt:number = undefined;
	/**
	 * Total Tax Amount
	 */
	totalTaxAmt:number = undefined;
	/**
	 * Total Weight
	 */
	totalWeight:number = undefined;
	/**
	 * Offshore Accessorial Amount
	 */
	offshoreAccessorialChargeAmt:number = undefined;
	/**
	 * Offshore Linehaul Charge Amount
	 */
	offshoreLinehaulChargeAmt:number = undefined;
	/**
	 * Offshore FSC Charge Amount
	 */
	offshoreFscChargeAmt:number = undefined;
	/**
	 * Total Charge Amount in USD
	 */
	totalChargeAmtInUsd:number = undefined;
}
export class TransactionContext {
	/**
	 * Details of the user making this request
	 */
	user:User = undefined;
	/**
	 * The authorization token used to invoke this API
	 */
	authorization:string = undefined;
	/**
	 * The ID of the application using this API
	 */
	srcApplicationId:string = undefined;
	/**
	 * Http Method
	 */
	httpMethod:string = undefined;
	/**
	 * Passed from f5 to find out where they came from
	 */
	xForwardedFor:string = undefined;
	/**
	 * A unique ID for the business transaction.
	 * The purpose of this id is to tie multiple computer transactions across multiple technologies for a given business transaction.
	 * The scope of the business transaction is determined by the initiator of the transaction.
	 * The same id should be used across multiple tiers for a given business transaction.
	 * For example, bill entry will use the same correlationId to record the bill details in the database and by TIBCO in backsyncing to DB2. 
	 * This will generated by the API gateway if one is not passed by the client.
	 */
	correlationId:string = undefined;
	/**
	 * A unique ID for the database transaction.
	 * This will be generated by the REST resource bean before invoking the EJB.
	 */
	transactionId:string = undefined;
}

