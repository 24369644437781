import {InvoicePreferenceConstraintCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoicePreferenceConstraintCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoicePreferenceConstraintCd ) {
      this._values.push(InvoicePreferenceConstraintCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoicePreferenceConstraintCd {

    for ( const obj in InvoicePreferenceConstraintCd ) {
      if (InvoicePreferenceConstraintCd[obj] === value){
        return InvoicePreferenceConstraintCd[obj] as InvoicePreferenceConstraintCd;
      }
    }
  }
}

const InvoicePreferenceConstraintCdHelper = new InvoicePreferenceConstraintCdHelperEnumHelperClass();
export default InvoicePreferenceConstraintCdHelper;
