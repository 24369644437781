import {ClaimPaymentMethodCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimPaymentMethodCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimPaymentMethodCd ) {
      this._values.push(ClaimPaymentMethodCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimPaymentMethodCd {

    for ( const obj in ClaimPaymentMethodCd ) {
      if (ClaimPaymentMethodCd[obj] === value){
        return ClaimPaymentMethodCd[obj] as ClaimPaymentMethodCd;
      }
    }
  }
}

const ClaimPaymentMethodCdHelper = new ClaimPaymentMethodCdHelperEnumHelperClass();
export default ClaimPaymentMethodCdHelper;
