import {RuleExpressionValueTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RuleExpressionValueTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RuleExpressionValueTypeCd ) {
      this._values.push(RuleExpressionValueTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RuleExpressionValueTypeCd {

    for ( const obj in RuleExpressionValueTypeCd ) {
      if (RuleExpressionValueTypeCd[obj] === value){
        return RuleExpressionValueTypeCd[obj] as RuleExpressionValueTypeCd;
      }
    }
  }
}

const RuleExpressionValueTypeCdHelper = new RuleExpressionValueTypeCdHelperEnumHelperClass();
export default RuleExpressionValueTypeCdHelper;
