import {RefusedReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class RefusedReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RefusedReasonCd ) {
      this._values.push(RefusedReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RefusedReasonCd {

    for ( const obj in RefusedReasonCd ) {
      if (RefusedReasonCd[obj] === value){
        return RefusedReasonCd[obj] as RefusedReasonCd;
      }
    }
  }
}

const RefusedReasonCdHelper = new RefusedReasonCdHelperEnumHelperClass();
export default RefusedReasonCdHelper;
