import {MetricVarianceCd} from '../';
import {EnumHelper} from './enum-helper';

export class MetricVarianceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MetricVarianceCd ) {
      this._values.push(MetricVarianceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MetricVarianceCd {

    for ( const obj in MetricVarianceCd ) {
      if (MetricVarianceCd[obj] === value){
        return MetricVarianceCd[obj] as MetricVarianceCd;
      }
    }
  }
}

const MetricVarianceCdHelper = new MetricVarianceCdHelperEnumHelperClass();
export default MetricVarianceCdHelper;
