
export enum ExceptionStatusCd {
	ACKNOWLEDGE = 'Acknowledge',
	CLOSED = 'Closed',
	COMPLETED = 'Completed',
	EXPIRED = 'Expired',
	NEW = 'New',
	PENDING = 'Pending',
	RESOLVED = 'Resolved',
	RESPONDED = 'Responded'}

