import {ShipperLoadedTrailerCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipperLoadedTrailerCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipperLoadedTrailerCd ) {
      this._values.push(ShipperLoadedTrailerCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipperLoadedTrailerCd {

    for ( const obj in ShipperLoadedTrailerCd ) {
      if (ShipperLoadedTrailerCd[obj] === value){
        return ShipperLoadedTrailerCd[obj] as ShipperLoadedTrailerCd;
      }
    }
  }
}

const ShipperLoadedTrailerCdHelper = new ShipperLoadedTrailerCdHelperEnumHelperClass();
export default ShipperLoadedTrailerCdHelper;
