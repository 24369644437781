
export enum InvoicePostDeliveryStatusCd {
	ACCEPTED = 'Accepted',
	ACCEPTED_BY_CUSTOMER = 'AcceptedByCustomer',
	ACCEPTED_WITH_ERRORS = 'AcceptedWithErrors',
	DEFERRED = 'Deferred',
	PARTIALLY_ACCEPTED = 'PartiallyAccepted',
	REJECTED = 'Rejected',
	SENT_TO_CUSTOMER = 'SentToCustomer',
	SENT_TO_SI = 'SentToSi',
	SENT_TO_SI_TEST = 'SentToSiTest'}

