import {ClaimTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimTypeCd ) {
      this._values.push(ClaimTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimTypeCd {

    for ( const obj in ClaimTypeCd ) {
      if (ClaimTypeCd[obj] === value){
        return ClaimTypeCd[obj] as ClaimTypeCd;
      }
    }
  }
}

const ClaimTypeCdHelper = new ClaimTypeCdHelperEnumHelperClass();
export default ClaimTypeCdHelper;
