
export enum PaymentStatusInternalCd {
	CHECK_CASHED = 'CheckCashed',
	CHECK_VOIDED = 'CheckVoided',
	DECLINED = 'Declined',
	PAYMENT_CANCELLED = 'PaymentCancelled',
	PAYMENT_ISSUED = 'PaymentIssued',
	PAYMENT_ON_HOLD = 'PaymentOnHold',
	PAYMENT_REQUESTED = 'PaymentRequested',
	PENDING_TRANSMISSION = 'PendingTransmission',
	SENT_TO_ORACLE = 'SentToOracle'}

