import {EquipmentTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class EquipmentTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EquipmentTypeCd ) {
      this._values.push(EquipmentTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EquipmentTypeCd {

    for ( const obj in EquipmentTypeCd ) {
      if (EquipmentTypeCd[obj] === value){
        return EquipmentTypeCd[obj] as EquipmentTypeCd;
      }
    }
  }
}

const EquipmentTypeCdHelper = new EquipmentTypeCdHelperEnumHelperClass();
export default EquipmentTypeCdHelper;
