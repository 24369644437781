
export enum RequestedChangeTypeCd {
	BUSINESS_NAME_ALIAS = 'BusinessNameAlias',
	CONTACT = 'Contact',
	FINANCIAL = 'Financial',
	INVOICE_INSTRUCTION = 'InvoiceInstruction',
	NAME_AND_ADDRESS = 'NameAndAddress',
	SERVICE_RECIPIENT = 'ServiceRecipient',
	TRUE_DEBTOR = 'True Debtor'}

