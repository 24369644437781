/**
 * DockOperations API
 * Version: 1.0
 * Build: 1.0.0.29
 */

import {
	ListInfo,
	EquipmentId,
	DataValidationError,
	Dimensions,
	Weight,
	AuditInfo,
	ActionCd,
	BillStatusCd,
	CubicFeetProfileMthdCd,
	DeliveryQualifierCd,
	DockShipmentListTypeCd,
	DockShipmentProgressCd,
	DockShipmentTypeCd,
	EquipmentStatusCd,
	EquipmentTypeCd,
	FoodPoisonCd,
	HazmatBulkQuantityCd,
	NodeTypeCd,
	RouteCategoryCd,
	ServiceTypeCd,
	ShiftCd,
	ShipmentServiceTypeCd,
	TrailerStatusCd,
	TripCategoryCd,
	TripNodeActivityCd,
	TripNodeStatusCd,
	TripNodeTypeCd,
	TripStatusCd
} from '@xpo-ltl/sdk-common';

/**
 * Path parameters used to return Dock Engineered Standard by location and employee.
 */
export class GetCurrentDockEngineeredStandardPath {
	/**
	 * The location associated with the employee.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The unique identifier for the employee.
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Query parameter used to return Dock Engineered Standard by location and employee.
 */
export class GetCurrentDockEngineeredStandardQuery {
	/**
	 * The code associated with the shift.  Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: true
	 * Array: false
	 */
	shiftCd:ShiftCd = undefined;
}
/**
 * 
 */
export class GetCurrentDockEngineeredStandardHeader {
	/**
	 * Pass the token to make the call.
	 * Required: true
	 * Array: false
	 */
	smartToken:string = undefined;
}
/**
 * Response to the request to return the Dock Engineered Standard by location and employee.
 */
export class GetCurrentDockEngineeredStandardResp {
	/**
	 * The dock worker's current Dock Engineered Standard percentage
	 * Required: false
	 * Array: false
	 */
	currentDesPercentage:number = undefined;
	/**
	 * The timestamp for which the DES was calculated in UTC.
	 * Required: false
	 * Array: false
	 */
	calculatedDateTimeUtc:Date = undefined;
	/**
	 * The current DES percentage for the entire SIC or shift
	 * Required: false
	 * Array: false
	 */
	currentSicDesPercentage:number = undefined;
	/**
	 * The employee's DES rank among all employees on current SIC or shift.
	 * Required: false
	 * Array: false
	 */
	employeeRank:number = undefined;
	/**
	 * The total number of employees ranked on current SIC or shift.
	 * Required: false
	 * Array: false
	 */
	totalEmployees:number = undefined;
	/**
	 * The number of minutes clocked into Kronos that the current DES is based on.
	 * Required: false
	 * Array: false
	 */
	minutesClockedIn:number = undefined;
	/**
	 * The Kronos work center the employee is currently clocked into.
	 * Required: false
	 * Array: false
	 */
	kronosWorkcenter:string = undefined;
}
/**
 * Path parameters used to return motor moves per hour by location and employee.
 */
export class GetCurrentMotorMovesPerHourPath {
	/**
	 * The location associated with the employee.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The unique identifier for the employee.
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Query parameter used to return motor moves per hour by location and employee.
 */
export class GetCurrentMotorMovesPerHourQuery {
	/**
	 * The code associated with the shift.  Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: true
	 * Array: false
	 */
	shiftCd:ShiftCd = undefined;
}
/**
 * 
 */
export class GetCurrentMotorMovesPerHourHeader {
	/**
	 * Pass the token to make the call.
	 * Required: true
	 * Array: false
	 */
	smartToken:string = undefined;
}
/**
 * Response to the request to return motor moves per hour by location and employee.
 */
export class GetCurrentMotorMovesPerHourResp {
	/**
	 * The dock worker's current motor moves per hour
	 * Required: false
	 * Array: false
	 */
	currentMotorMovesPerHour:number = undefined;
	/**
	 * The timestamp for which the MMPH were calculated in UTC.
	 * Required: false
	 * Array: false
	 */
	calculatedDateTimeUtc:Date = undefined;
	/**
	 * The SIC or shift MMPH goal.
	 * Required: false
	 * Array: false
	 */
	motorMovesGoal:number = undefined;
	/**
	 * The employee's MMPH rank among all employees on current SIC or shift.
	 * Required: false
	 * Array: false
	 */
	employeeRank:number = undefined;
	/**
	 * The total number of employees ranked on current SIC or shift.
	 * Required: false
	 * Array: false
	 */
	totalEmployees:number = undefined;
	/**
	 * The number of minutes clocked into Kronos that the current MMPH is based on.
	 * Required: false
	 * Array: false
	 */
	minutesClockedIn:number = undefined;
	/**
	 * The Kronos work center the employee is currently clocked into.
	 * Required: false
	 * Array: false
	 */
	kronosWorkcenter:string = undefined;
}
/**
 * Path parameters used to retrieve trailer details if one is present at a particular location and door.
 */
export class GetTrailerDetailsByDoorPath {
	/**
	 * The location associated with the door.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The door number that will be used to check if there is a trailer.
	 * Required: true
	 * Array: false
	 */
	doorNbr:string = undefined;
}
/**
 * Response to the request to trailer details if one is present at a particular location and door.
 */
export class GetTrailerDetailsByDoorResp {
	/**
	 * Details about the trailer that is at the location and door number provided.
	 * Required: false
	 * Array: false
	 */
	trailerDetail:TrailerLoad = undefined;
}
/**
 * 
 */
export class GetSmartApiTokenResp {
	/**
	 * SMART API Token to be used in the query call.
	 * Required: false
	 * Array: false
	 */
	smartApiToken:SmartApiToken = undefined;
}
/**
 * Path parametes used to retrieve door details.
 */
export class GetDoorDetailsPath {
	/**
	 * The location associated with the door.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The door number that will be used to check if there is a trailer.
	 * Required: true
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * The shift code for which the door details are requested.  Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: true
	 * Array: false
	 */
	shiftCd:ShiftCd = undefined;
}
/**
 * Query parameters used to retrieve door details.
 */
export class GetDoorDetailsQuery {
	/**
	 * The type of dock location. It can be either the door or a bay near the loading door.
	 * Required: false
	 * Array: false
	 */
	dockLocationType:string = undefined;
	/**
	 * The unique Identifier of the device making the request.
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * The version of the app used on the device.
	 * Required: false
	 * Array: false
	 */
	appVersion:string = undefined;
	/**
	 * The employee ID of the user that is requesting the information.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
}
/**
 * Response to the request to retrieve door details.
 */
export class GetDoorDetailsResp {
	/**
	 * Details about the door at the SIC for the shift provided.
	 * Required: false
	 * Array: false
	 */
	dockDoor:DockDoor = undefined;
}
/**
 * Path parameters used to retrieve door setup details.
 */
export class GetDoorSetupDetailsPath {
	/**
	 * The location associated with the door
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The door number that will be used to check if there is a trailer.
	 * Required: true
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * The shift code for which the door details are requested.  Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: true
	 * Array: false
	 */
	shiftCd:ShiftCd = undefined;
}
/**
 * Query parameters used to retrieve door setup details.
 */
export class GetDoorSetupDetailsQuery {
	/**
	 * If set to true, dock door detailed information will be returned. If set to false, only the door plan information will be returned.
	 * Required: false
	 * Array: false
	 */
	detailInd:boolean = undefined;
	/**
	 * The type of dock location. It can be either the door or a bay near the loading door.
	 * Required: false
	 * Array: false
	 */
	dockLocationType:string = undefined;
	/**
	 * The uique identifier of the device making the request.
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * The version of the app used on the device.
	 * Required: false
	 * Array: false
	 */
	appVersion:string = undefined;
	/**
	 * The employee ID of the user that is requesting the information.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
}
/**
 * Response to the request to retrieve door setup details.
 */
export class GetDoorSetupDetailsResp {
	/**
	 * The door details about the dock door at the SIC for the shift provided.
	 * Required: false
	 * Array: false
	 */
	dockDoorTrailer:DockDoorTrailer = undefined;
}
export class GetProDetailsPath {
	/**
	 * PRO Number of the shipment
	 * Required: true
	 * Array: false
	 */
	proNbr:object = undefined;
}
export class GetProDetailsQuery {
	/**
	 * SIC Cd where the PRO Details was requesterd
	 * Required: true
	 * Array: false
	 */
	requestingSicCd:object = undefined;
	/**
	 * Unique Identifier of the device making the request
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * Version of the App used on the device
	 * Required: false
	 * Array: false
	 */
	appVersion:string = undefined;
	/**
	 * Employee Id of the user that is requesting the information
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
}
export class GetProDetailsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	dockShipment:DockShipment = undefined;
}
export class GetProLoadDetailsPath {
	/**
	 * Shipment PRO Number
	 * Required: true
	 * Array: false
	 */
	proNbr:object = undefined;
	/**
	 * Service Center Identification Code of the location
	 * Required: true
	 * Array: false
	 */
	sicCd:object = undefined;
	/**
	 * The shift code for which the door details are requested.  Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: true
	 * Array: false
	 */
	shiftCd:string = undefined;
}
export class GetProLoadDetailsQuery {
	/**
	 * Unique Identifier of the device making the request
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * Version of the App used on the device
	 * Required: false
	 * Array: false
	 */
	appVersion:string = undefined;
	/**
	 * Employee Id of the user that is requesting the information
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	errorOverrideInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	getReweighInd:boolean = undefined;
}
export class GetProLoadDetailsResp {
	/**
	 * Shipment information
	 * Required: false
	 * Array: false
	 */
	proDetail:DockShipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	loadsTo:LoadToLocation[] = undefined;
}
/**
 * Path parameters used to list break trailers.
 */
export class ListBreakTrailersPath {
	/**
	 * The location associated with the door.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The shift code for which the door details are requested.  Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: true
	 * Array: false
	 */
	shiftCd:ShiftCd = undefined;
}
/**
 * Query parameters used to list break trailers.
 */
export class ListBreakTrailersQuery {
	/**
	 * The plan date for which to get the trailers. If not supplied, then the current date will be used.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * The unique identifier of the device making the request.
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * The version of the app used on the device.
	 * Required: false
	 * Array: false
	 */
	appVersion:string = undefined;
	/**
	 * The employee ID of the user that is requesting the information.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * The sector ID which is used to filter the list of doors.
	 * Required: false
	 * Array: false
	 */
	sectorNbr:number = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list break trailers.
 */
export class ListBreakTrailersResp {
	/**
	 * The list of trailers to be worked on at the SIC and shift.
	 * Required: false
	 * Array: true
	 */
	breakTrailers:BreakTrailer[] = undefined;
}
/**
 * Request to create a new dock location.
 */
export class CreateDockLocationRqst {
	/**
	 * Dock location details which will be used to be create the record.
	 * Required: true
	 * Array: false
	 */
	dockLocation:DockLocation = undefined;
}
/**
 * Response to the request to create a new dock location.
 */
export class CreateDockLocationResp {
	/**
	 * Dock location details which were used to create the record.
	 * Required: false
	 * Array: false
	 */
	dockLocation:DockLocation = undefined;
}
/**
 * Path parameter used to delete a dock location.
 */
export class DeleteDockLocationPath {
	/**
	 * The unique identifier of the dock location.
	 * Required: false
	 * Array: false
	 */
	dockInstId:number = undefined;
}
/**
 * Path parameter used to retrieve a dock location.
 */
export class GetDockLocationPath {
	/**
	 * The unique identifier of the dock location.
	 * Required: false
	 * Array: false
	 */
	dockInstId:number = undefined;
}
/**
 * Response to the request to return details for a dock location.
 */
export class GetDockLocationResp {
	/**
	 * The dock location associated with the unique identifier provided.
	 * Required: false
	 * Array: false
	 */
	dockLocation:DockLocation = undefined;
}
/**
 * Query parameters used to list dock locations.
 */
export class ListDockLocationsQuery {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The name of the dock location.
	 * Required: false
	 * Array: false
	 */
	dockName:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the requst to list dock locations at a given service center.
 */
export class ListDockLocationsResp {
	/**
	 * A list of dock locations that match the input critria.
	 * Required: false
	 * Array: true
	 */
	dockLocations:DockLocation[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Path parameter used to update a dock location.
 */
export class UpdateDockLocationRemarksPath {
	/**
	 * The unique identifier of the dock location.
	 * Required: true
	 * Array: false
	 */
	dockInstId:number = undefined;
}
/**
 * Request to update a dock location.
 */
export class UpdateDockLocationRemarksRqst {
	/**
	 * Remarks about the dock location.
	 * Required: false
	 * Array: false
	 */
	dockRemarks:string = undefined;
}
/**
 * Response to the request to update a dock location.
 */
export class UpdateDockLocationRemarksResp {
	/**
	 * Dock location details which were used to update the record.
	 * Required: false
	 * Array: false
	 */
	dockLocation:DockLocation = undefined;
}
/**
 * Path parameter used to bulk update trailer load status, door, and remarks.
 */
export class BulkUpdateTrailerLoadStatusDoorRemarksPath {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Request to bulk update trailer load status, door, and remarks.
 */
export class BulkUpdateTrailerLoadStatusDoorRemarksRqst {
	/**
	 * The list of details about Trailer Load status, door, and remarks that is used to perform the bulk update.
	 * Required: true
	 * Array: true
	 */
	trailerLoadStatusDoorRemarks:TrailerLoadStatusDoorRemark[] = undefined;
}
/**
 * Response to the request to bulk update trailer load status, door, and remarks.
 */
export class BulkUpdateTrailerLoadStatusDoorRemarksResp {
	/**
	 * The list of details about Trailer Load status, door, and remarks that was used to perform the bulk update.
	 * Required: false
	 * Array: true
	 */
	trailerLoadStatusDoorRemarks:TrailerLoadStatusDoorRemark[] = undefined;
}
/**
 * Path parameters used to update a trailer and DSR assignment.
 */
export class UpdateTrailerDsrAssignmentPath {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The unique identifier of the trailer.
	 * Required: true
	 * Array: false
	 */
	equipmentId:number = undefined;
}
/**
 * Request to update update a trailer and DSR assignment.
 */
export class UpdateTrailerDsrAssignmentRqst {
	/**
	 * The employee ID of the DSR who is assigned to the trailer.
	 * Required: true
	 * Array: false
	 */
	dsrEmployeeId:string = undefined;
	/**
	 * The trailer suffix number.
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * The trailer prefix number.
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * The date and time when the DSR was assigned to the trailer.
	 * Required: false
	 * Array: false
	 */
	startDateTime:Date = undefined;
	/**
	 * The date and time when the DSR was unassigned from the trailer.
	 * Required: false
	 * Array: false
	 */
	endDateTime:Date = undefined;
}
/**
 * Response to the request to update a trailer and DSR assignment.
 */
export class UpdateTrailerDsrAssignmentResp {
	/**
	 * The trailer DSR assignment that was updated.
	 * Required: false
	 * Array: false
	 */
	trailerDsrAssignment:TrailerDsrAssignment = undefined;
}
/**
 * Request payload to add a new Foreign Equipment
 */
export class AddForeignEquipmentRqst {
	/**
	 * The location that is requesting to add the foreign equipment.
	 * Required: true
	 * Array: false
	 */
	responsibleSicCd:string = undefined;
	/**
	 * The details about the Foreign Equipment which will be added.
	 * Required: true
	 * Array: true
	 */
	foreignEquipment:ForeignEquipment[] = undefined;
	/**
	 * The unique identifier of the device making the request.
	 * Required: false
	 * Array: false
	 */
	deviceId:string = undefined;
	/**
	 * The version of the app used on the device.
	 * Required: false
	 * Array: false
	 */
	appVersion:string = undefined;
	/**
	 * The employee ID of the user that is requesting the information.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
}
export class AddForeignEquipmentResp {
	/**
	 * The details about the Foreign Equipment which were added.
	 * Required: false
	 * Array: true
	 */
	foreignEquipment:AddForeignEquipmentResp_ForeignEquipment[] = undefined;
}
/**
 * Request to perform a bulk update for Status and Remarks for one or more Equipment records.
 */
export class BulkUpdateEquipmentStatusRemarkRqst {
	/**
	 * The list of EquipmentStatusRemark which will be used to perform the bulk update.
	 * Required: true
	 * Array: true
	 */
	equipmentStatusRemarks:EquipmentStatusRemark[] = undefined;
}
/**
 * Response to the request to perform a bulk update for Status and Remarks for one or more Equipment records.
 */
export class BulkUpdateEquipmentStatusRemarkResp {
	/**
	 * The list of EquipmentStatusRemark which was used to perform the bulk update.
	 * Required: false
	 * Array: true
	 */
	equipmentStatusRemarks:EquipmentStatusRemark[] = undefined;
}
/**
 * Request to associate Forklift with an Operator
 */
export class CreateForkliftScanRqst {
	/**
	 * Sic where the forklift is located
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * Identifier for the employee using the forklift
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * Forklift Equipment Prefix - Suffix
	 * Required: true
	 * Array: false
	 */
	forkliftNbr:string = undefined;
}
/**
 * Response to associate Forklift with an Operator 
 */
export class CreateForkliftScanResp {
	/**
	 * Forklift equipment prefix-suffix
	 * Required: false
	 * Array: false
	 */
	forkliftNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	forkliftOperator:EquipmentOperator = undefined;
}
/**
 * Path parameter used to retrieve the forklift associated with the employee.
 */
export class GetForkliftScanByEmployeePath {
	/**
	 * The identifier for the employee using the forklift.
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Response to the request to retrieve the most recent forklift and employee association.
 */
export class GetForkliftScanByEmployeeResp {
	/**
	 * The ID associated with the forklift equipment.
	 * Required: false
	 * Array: false
	 */
	forkliftNbr:string = undefined;
	/**
	 * Forklift employee association details.
	 * Required: false
	 * Array: false
	 */
	forkliftOperator:EquipmentOperator = undefined;
}
/**
 * Path parameter used to retrieve a list of equipment at a given SIC.
 */
export class ListEquipmentBySicPath {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
}
/**
 * Query parameters used to retrieve a list of equipment at a given SIC.
 */
export class ListEquipmentBySicQuery {
	/**
	 * The current status of the trailer at the door location.
	 * Required: false
	 * Array: false
	 */
	trailerStatusCd:TrailerStatusCd = undefined;
	/**
	 * The type of Equipment. E.g. Dolly, Power, Trailer, etc.
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:EquipmentTypeCd = undefined;
	/**
	 * The current status of the equipment at the SIC.
	 * Required: false
	 * Array: false
	 */
	currentStatus:EquipmentStatusCd = undefined;
	/**
	 * The door number at the dock.
	 * Required: false
	 * Array: false
	 */
	fromDoorNbr:string = undefined;
	/**
	 * The door number at the dock.
	 * Required: false
	 * Array: false
	 */
	toDoorNbr:string = undefined;
	/**
	 * A list of equipment prefix and suffix concatenations.
	 * Required: false
	 * Array: true
	 */
	equipmentNbrs:string[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to retrieve a list of equipment at a given SIC.
 */
export class ListEquipmentBySicResp {
	/**
	 * Details for the Equipment record which matches the input criteria.
	 * Required: false
	 * Array: true
	 */
	equipment:Equipment[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to create an equipment-employee association.
 */
export class CreateEquipmentScanRqst {
	/**
	 * The service center where the forklift is located.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The unique identifer for the employee who using the forklift.
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The equipment prefix-suffix.
	 * Required: true
	 * Array: false
	 */
	equipmentNbr:string = undefined;
	/**
	 * The type of equipment that was scanned.  E.g. Dolly, Forklift, Hostlers, etc.
	 * Required: false
	 * Array: true
	 */
	equipmentTypeCds:string[] = undefined;
}
/**
 * Response to the request to create an equipment-employee association.
 */
export class CreateEquipmentScanResp {
	/**
	 * The equipment prefix-suffix.
	 * Required: false
	 * Array: false
	 */
	equipmentNbr:string = undefined;
	/**
	 * The equipment-employee association details.
	 * Required: false
	 * Array: false
	 */
	equipmentOperator:EquipmentOperator = undefined;
}
/**
 * Path parameters used to list reship freight trailers.
 */
export class ListReshipFreightTrailersPath {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The shift code for which the door details are requested.  Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: true
	 * Array: false
	 */
	shiftCd:ShiftCd = undefined;
}
/**
 * Query parameters used to list reship freight trailers.
 */
export class ListReshipFreightTrailersQuery {
	/**
	 * The plan date for which to retrieve the trailers. If is isn't supplied, then the current date will be used.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * If set to true, then trailers from the next working day are also included.
	 * Required: false
	 * Array: false
	 */
	nextWorkDayInd:boolean = undefined;
	/**
	 * The unique identifier of the selection profile.
	 * Required: false
	 * Array: false
	 */
	selectionProfileId:number = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list reship freight trailers.
 */
export class ListReshipFreightTrailersResp {
	/**
	 * The list of trailers associated with the XDock plan.
	 * Required: false
	 * Array: true
	 */
	plannedTrailers:PlanningTrailer[] = undefined;
	/**
	 * The list of trailers not associated with the XDock plan.
	 * Required: false
	 * Array: true
	 */
	excludedTrailers:TrailerLoad[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Path parameters used to list XDock Plan shipments.
 */
export class ListShipmentsInDockPlanPath {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The shift code for which the door details are requested.  Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: true
	 * Array: false
	 */
	shiftCd:ShiftCd = undefined;
}
/**
 * Query parameters used to list XDock Plan shipments.
 */
export class ListShipmentsInDockPlanQuery {
	/**
	 * The plan date for which the shipments will be retrieved. If is isn't supplied, then the current date will be used.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * The codes associated with the progress for the dock shipments.  Permitted values: NotStripped, Stripped, and TConned.
	 * Required: false
	 * Array: true
	 */
	xdockProgressCds:DockShipmentProgressCd[] = undefined;
	/**
	 * The type of dock shipment.  Permitted values:: Dock, Pickup, Reship, and Strand.
	 * Required: false
	 * Array: false
	 */
	dockShipmentTypeCd:DockShipmentTypeCd = undefined;
	/**
	 * The shipment's destination SIC.
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * The SIC of the FAC for the plan.
	 * Required: false
	 * Array: false
	 */
	facCd:string = undefined;
	/**
	 * The sector number for the door.
	 * Required: false
	 * Array: false
	 */
	doorSectorNbr:number = undefined;
	/**
	 * The number assigned to the door.
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * The type of dock shipment list that will be returned.  Permitted values: Dirverted and Excluded.
	 * Required: false
	 * Array: false
	 */
	dockShipmentListTypeCd:DockShipmentListTypeCd = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list XDock Plan shipments.
 */
export class ListShipmentsInDockPlanResp {
	/**
	 * List of Shipments that are in the XDOCK Plan
	 * Required: false
	 * Array: true
	 */
	dockShipments:XdockShipment[] = undefined;
	/**
	 * Pagination Information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Path parameters used to remove trailers from an XDock Plan
 */
export class RemoveTrailersFromDockPlanPath {
	/**
	 * The service center identification code.
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The shift code for the plan.  Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: true
	 * Array: false
	 */
	shiftCd:ShiftCd = undefined;
}
/**
 * Request to remove trailers from an XDock Plan.
 */
export class RemoveTrailersFromDockPlanRqst {
	/**
	 * The plan date for the XDock Plan.  If not supplied, then the current date will be used.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * Basic equipment information.
	 * Required: true
	 * Array: true
	 */
	equipment:EquipmentId[] = undefined;
}
/**
 * Response to the request to remove trailers from an XDock plan.
 */
export class RemoveTrailersFromDockPlanResp {
	/**
	 * The list of trailers with errors that occurred while removing.
	 * Required: true
	 * Array: true
	 */
	equipment:EquipmentId[] = undefined;
	/**
	 * The list of error messages associated with the trailers.
	 * Required: false
	 * Array: true
	 */
	dataValidationErrors:DataValidationError[] = undefined;
}
/**
 * Request message to update the Planning Trailers
 */
export class BulkUpdatePlanningTrailersRqst {
	/**
	 * Information about Planning trailer that can be updated
	 * Required: true
	 * Array: true
	 */
	dockPlanningTrailer:DockPlanningTrailer[] = undefined;
}
/**
 * Request message to update the Planning Trailers
 */
export class BulkUpdatePlanningTrailersResp {
	/**
	 * Information about Planning trailer that can be updated
	 * Required: true
	 * Array: true
	 */
	dockPlanningTrailer:DockPlanningTrailer[] = undefined;
}
/**
 * Information about the trailer to break
 */
export class BreakTrailer {
	/**
	 * The door number on the dock to which the trailer is assigned.
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * The door number that is assigned from the StepSaver process.
	 * Required: false
	 * Array: false
	 */
	stepSaverDoorNbr:string = undefined;
	/**
	 * The door sector number as assigned by the StepSaver process.
	 * Required: false
	 * Array: false
	 */
	stepSaverSectorNbr:string = undefined;
	/**
	 * Information about the trailer at the dock door.
	 * Required: false
	 * Array: false
	 */
	trailer:Trailer = undefined;
}
/**
 * Information about the Customer Profile
 */
export class CustomerProfile {
	/**
	 * The destination zip code in the profile.
	 * Required: false
	 * Array: false
	 */
	destZip:string = undefined;
	/**
	 * The Cubic Feet Profile for the customer.
	 * Required: false
	 * Array: false
	 */
	profileType:CubicFeetProfileMthdCd = undefined;
}
/**
 * Information about a dock door.
 */
export class DockDoor {
	/**
	 * The location of the door in the dock.
	 * Required: false
	 * Array: false
	 */
	dockLocation:string = undefined;
	/**
	 * The equipment ID prefix-suffix for the trailer. E.g. 313-3760.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * The current status of the trailer at the door location.
	 * Required: false
	 * Array: false
	 */
	trailerStatus:string = undefined;
	/**
	 * The unique identifier of the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerInstId:string = undefined;
	/**
	 * The unique identifier of the Cross Dock Plan for a SIC and shift.
	 * Required: false
	 * Array: false
	 */
	planInstId:string = undefined;
	/**
	 * The count of the shipments remaining to be loaded/unloaded to/from the trailer.
	 * Required: false
	 * Array: false
	 */
	remainingShipmentsCount:number = undefined;
	/**
	 * The total cube of the shipments remaining to be loaded/unloaded to/from the trailer.
	 * Required: false
	 * Array: false
	 */
	remainingShipmentsCube:number = undefined;
	/**
	 * The total weight of the shipments remaining to be loaded/unloaded to/from the trailer.
	 * Required: false
	 * Array: false
	 */
	remainingShipmentsWeight:number = undefined;
	/**
	 * The total count of headload shipments remaining to be loaded/unloaded to/from the trailer.
	 * Required: false
	 * Array: false
	 */
	remainingHeadLoadShipments:number = undefined;
	/**
	 * The total count of shipments loaded on the trailer.
	 * Required: false
	 * Array: false
	 */
	loadedShipmentCount:number = undefined;
	/**
	 * The total cube of all the shipments loaded on trailer.
	 * Required: false
	 * Array: false
	 */
	loadedShipmentCube:number = undefined;
	/**
	 * The total weight of the shipments loaded on the trailer.
	 * Required: false
	 * Array: false
	 */
	loadedShipmentWeight:number = undefined;
	/**
	 * The count of the hazardous shipments that are loaded on the trailer.
	 * Required: false
	 * Array: false
	 */
	hazmatShipmentCount:number = undefined;
	/**
	 * The current status of the trailer at the door.
	 * Required: false
	 * Array: false
	 */
	doorStatus:string = undefined;
	/**
	 * The destination SIC code of the trailer.
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic:string = undefined;
	/**
	 * The trailer length, in feet, of the trailer. E.g. 28.
	 * Required: false
	 * Array: false
	 */
	trailerLengthFt:number = undefined;
	/**
	 * The count of load deck.
	 * Required: false
	 * Array: false
	 */
	loadDeckCount:number = undefined;
	/**
	 * The total count of the headload shipments that are loaded onto the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerHeadloadShipmentCount:number = undefined;
	/**
	 * The total weight of the equipment that is loaded on the trailer.
	 * Required: false
	 * Array: false
	 */
	loadedEquipmentWeightLbs:number = undefined;
	/**
	 * The door plan associated with the door for the SIC and shift.
	 * Required: false
	 * Array: false
	 */
	currentPlan:string = undefined;
	/**
	 * The identifier of the door plan profile associated with the door for the SIC and shift.
	 * Required: false
	 * Array: false
	 */
	profileInstId:string = undefined;
	/**
	 * The current date time.
	 * Required: false
	 * Array: false
	 */
	versionCheckTimestamp:string = undefined;
	/**
	 * Route Prefix - Route Suffix
	 * 	The route name prefix is usually the name of the geographic area.  The route name suffix is usually user assigned and could be a number assigned to uniquely identify the route within a geographic area.
	 * Required: false
	 * Array: false
	 */
	routeName:string = undefined;
	/**
	 * The unique identifier assigned to the route.
	 * Required: false
	 * Array: false
	 */
	routeInstId:string = undefined;
	/**
	 * The SIC for the service center responsible for the route.
	 * Required: false
	 * Array: false
	 */
	routeSicCd:string = undefined;
	/**
	 * The count of routes for this dock door.
	 * Required: false
	 * Array: false
	 */
	routesForDoorCount:number = undefined;
}
/**
 * Information about the door setup at a dock in a service center.
 */
export class DockDoorSetup {
	/**
	 * The door plan associated with the door for the SIC and shift.
	 * Required: false
	 * Array: false
	 */
	currentPlan:string = undefined;
	/**
	 * The destination SIC of the trailer.
	 * Required: false
	 * Array: false
	 */
	loadDestination:string = undefined;
	/**
	 * The identifier of the door plan profile associated with the door for the SIC and shift.
	 * Required: false
	 * Array: false
	 */
	profileInstId:string = undefined;
	/**
	 * The equipment ID prefix-suffix for the trailer. E.g. 313-3760.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * The current status of the trailer at the door location.
	 * Required: false
	 * Array: false
	 */
	trailerStatus:TrailerStatusCd = undefined;
	/**
	 * The current date time.
	 * Required: false
	 * Array: false
	 */
	versionCheckTimestamp:string = undefined;
}
/**
 * Information about trailer that is currently assigned to a Dock Door
 */
export class DockDoorTrailer {
	/**
	 * The location of the door in the dock.
	 * Required: false
	 * Array: false
	 */
	dockLocation:string = undefined;
	/**
	 * The equipment ID prefix-suffix for the trailer. E.g. 313-3760.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * The current status of the trailer at the door location.
	 * Required: false
	 * Array: false
	 */
	trailerStatus:TrailerStatusCd = undefined;
	/**
	 * The unique identifier of the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerInstId:string = undefined;
	/**
	 * The unique identifier of the cross dock plan for the SIC and shift.
	 * Required: false
	 * Array: false
	 */
	planInstId:string = undefined;
	/**
	 * The count of the shipments remaining to be loaded/unloaded to/from the trailer.
	 * Required: false
	 * Array: false
	 */
	remainingShipmentsCount:number = undefined;
	/**
	 * The total cube of the shipments remaining to be loaded/unloaded to/from the trailer.
	 * Required: false
	 * Array: false
	 */
	remainingShipmentsCube:number = undefined;
	/**
	 * The total weight of the shipments remaining to be loaded/unloaded to/from the trailer.
	 * Required: false
	 * Array: false
	 */
	remainingShipmentsWeight:number = undefined;
	/**
	 * The total count of headload shipments remaining to be loaded/unloaded to/from the trailer.
	 * Required: false
	 * Array: false
	 */
	remainingHeadLoadShipments:number = undefined;
	/**
	 * The total count of shipments loaded on the trailer.
	 * Required: false
	 * Array: false
	 */
	loadedShipmentCount:number = undefined;
	/**
	 * The total cube of all the shipments loaded on trailer.
	 * Required: false
	 * Array: false
	 */
	loadedShipmentCube:number = undefined;
	/**
	 * The total weight of the Shipments loaded on the trailer.
	 * Required: false
	 * Array: false
	 */
	loadedShipmentWeight:number = undefined;
	/**
	 * The count of the hazardous shipments that are loaded on the trailer.
	 * Required: false
	 * Array: false
	 */
	hazmatShipmentCount:number = undefined;
	/**
	 * The current status of the trailer at the door.
	 * Required: false
	 * Array: false
	 */
	doorStatus:string = undefined;
	/**
	 * The destination SIC of the trailer.
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic:string = undefined;
	/**
	 * The current date time.
	 * Required: false
	 * Array: false
	 */
	versionCheckTimestamp:string = undefined;
	/**
	 * Route Prefix - Route Suffix
	 * 	The route name prefix is usually the name of the geographic area.  The route name suffix is usually user assigned and could be a number assigned to uniquely identify the route within a geographic area.
	 * Required: false
	 * Array: false
	 */
	routeName:string = undefined;
	/**
	 * The unique identifier assigned to a route.
	 * Required: false
	 * Array: false
	 */
	routeInstId:string = undefined;
	/**
	 * The SIC of the service center responsible for the route.
	 * Required: false
	 * Array: false
	 */
	routeSicCd:string = undefined;
	/**
	 * The count of routes for this dock door.
	 * Required: false
	 * Array: false
	 */
	routesForDoorCount:number = undefined;
	/**
	 * The length, in feet, of the trailer. E.g. 28.
	 * Required: false
	 * Array: false
	 */
	trailerLengthFt:number = undefined;
	/**
	 * The count of load deck.
	 * Required: false
	 * Array: false
	 */
	loadDeckCount:number = undefined;
	/**
	 * The total count of the headload shipments that are loaded onto the trailer.
	 * Required: false
	 * Array: false
	 */
	trailerHeadloadShipmentCount:number = undefined;
	/**
	 * The total weight of the equipment that is loaded on the trailer.
	 * Required: false
	 * Array: false
	 */
	loadedEquipmentWeightLbs:number = undefined;
	/**
	 * The door plan associated with the door for the SIC and shift.
	 * Required: false
	 * Array: false
	 */
	currentPlan:string = undefined;
	/**
	 * The identifier of the door plan profile associated with the door for the SIC and shift.
	 * Required: false
	 * Array: false
	 */
	profileInstId:string = undefined;
}
/**
 * Information about Planning Trailer that can be updated by the users.
 */
export class DockPlanningTrailer {
	/**
	 * The uUnique identifier of the XDock Plan
	 * Required: false
	 * Array: false
	 */
	planInstId:number = undefined;
	/**
	 * The plan for XDock.
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * The SIC for the XDock plan.
	 * Required: false
	 * Array: false
	 */
	planSicCd:string = undefined;
	/**
	 * The shift code. Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: false
	 * Array: false
	 */
	shiftCd:ShiftCd = undefined;
	/**
	 * Basic equipment information.
	 * Required: false
	 * Array: false
	 */
	equipment:EquipmentId = undefined;
	/**
	 * Indicates the action being taken for the trailer in the plan.
	 * 
	 * Valid Values:
	 * BYP - Bypass
	 * HLD - Hold
	 * BRK - Breaker
	 * Required: false
	 * Array: false
	 */
	trailerActionCd:string = undefined;
	/**
	 * The load destination for Step Saver use.
	 * Required: false
	 * Array: false
	 */
	stepSaverLoadDest:string = undefined;
	/**
	 * Indicates if a review is required.
	 * Required: false
	 * Array: false
	 */
	reviewRequiredInd:boolean = undefined;
}
/**
 * Information about the shipment that is at a dock.
 */
export class DockShipment {
	/**
	 * If true, then this indicates that an accuracy check is required for the shipment.
	 * Required: false
	 * Array: false
	 */
	accuracyCheckRequiredInd:boolean = undefined;
	/**
	 * The current billing status code of the shipment.
	 * Required: false
	 * Array: false
	 */
	billStatusCd:BillStatusCd = undefined;
	/**
	 * The shipment's volume in cubic feet based on the dimensions.
	 * Required: false
	 * Array: false
	 */
	volCuFt:number = undefined;
	/**
	 * The dimensions profile for the customer based on the destination zip code.
	 * Required: false
	 * Array: true
	 */
	customerDimensionsProfile:CustomerProfile[] = undefined;
	/**
	 * The shipment's default dimensions.
	 * Required: false
	 * Array: true
	 */
	defaultDimensions:Dimensions[] = undefined;
	/**
	 * The shipment delivery qualifier. E.g. Over, Short, Trap, Final Delivered, etc.
	 * Required: false
	 * Array: false
	 */
	deliveryQualifier:DeliveryQualifierCd = undefined;
	/**
	 * The shipment destination terminal SIC.
	 * Required: false
	 * Array: false
	 */
	destSicCd:string = undefined;
	/**
	 * The shipment dimensions for each of the pieces.
	 * Required: false
	 * Array: true
	 */
	dimensions:ShipmentDimension[] = undefined;
	/**
	 * If true, then shipment dimensions are required to be captured.
	 * Required: false
	 * Array: false
	 */
	dimensionsRequiredInd:boolean = undefined;
	/**
	 * The name of the dock location where the shipment is currently located.
	 * Required: false
	 * Array: false
	 */
	dockLocation:string = undefined;
	/**
	 * The type of dock location. Either a bay or dock door.
	 * Required: false
	 * Array: false
	 */
	dockLocationType:string = undefined;
	/**
	 * The Freight Bill Delivery Set print count.The number of times that a FBDS has been printed for this shipment
	 * Required: false
	 * Array: false
	 */
	fbdsPrintCount:number = undefined;
	/**
	 * Indicates if the shipment type is either a food shipment or poison shipment.
	 * Required: false
	 * Array: false
	 */
	foodPoisonCd:FoodPoisonCd = undefined;
	/**
	 * If true, then the shipment contents can freeze.
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * If true, then the shipment is guaranteed.
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * If true, then the shipment has hazardous materials.
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * The hazardous material details.
	 * Required: false
	 * Array: true
	 */
	hazmats:Hazmat[] = undefined;
	/**
	 * If true, then hazardous material information is required to be captured.
	 * Required: false
	 * Array: false
	 */
	hazmatRequiredInd:boolean = undefined;
	/**
	 * If true, then the shipment is a headload on the trailer.
	 * Required: false
	 * Array: false
	 */
	headloadInd:boolean = undefined;
	/**
	 * If true, then the inspection is required on this shipment at the dock.
	 * Required: false
	 * Array: false
	 */
	inspectionRequiredInd:boolean = undefined;
	/**
	 * If set to true, then the shipment should be left on the trailer.
	 * Required: false
	 * Array: false
	 */
	leaveOnTrailerInd:boolean = undefined;
	/**
	 * The date and time the shipment was last loaded.
	 * Required: false
	 * Array: false
	 */
	loadTimestamp:string = undefined;
	/**
	 * The count of loose pieces on this shipment.
	 * Required: false
	 * Array: false
	 */
	loosePiecesCount:number = undefined;
	/**
	 * If set to true, then the number of motor moves are known for this shipment.
	 * Required: false
	 * Array: false
	 */
	mmKnownInd:boolean = undefined;
	/**
	 * Identifies the number of moves or lifts made with a forklift or a pallet jack to move freight without manual intervention by the driver.
	 * Required: false
	 * Array: false
	 */
	motorMoves:number = undefined;
	/**
	 * The unqiue identifier of the planned route the shipment is currently on.
	 * Required: false
	 * Array: false
	 */
	plannedRouteId:string = undefined;
	/**
	 * Identifies the shipment position on the trailer for a given route. Previously, progressiveLoadingCd.
	 * Required: false
	 * Array: false
	 */
	positionOnTrailerCd:string = undefined;
	/**
	 * The business identifier for the shipment.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * If true, then the shipment is moving as rate saver.
	 * Required: false
	 * Array: false
	 */
	rateSaverInd:boolean = undefined;
	/**
	 * If set to true, then the reweigh button is displayed on the handheld device.
	 * Required: false
	 * Array: false
	 */
	reweighButtonInd:boolean = undefined;
	/**
	 * If set to true, then reweigh is required for this shipment.
	 * Required: false
	 * Array: false
	 */
	reweighRequiredInd:boolean = undefined;
	/**
	 * The unique identifier of the Pickup and Delivery route for this shipment.
	 * Required: false
	 * Array: false
	 */
	routeInstId:string = undefined;
	/**
	 * When the shipment is on a released route, then this represents the SIC of the service center.
	 * Required: false
	 * Array: false
	 */
	routeSic:string = undefined;
	/**
	 * Indicates the service type on the shipment. Normal, Deferred, Guaranteed By Noon, etc.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:ServiceTypeCd = undefined;
	/**
	 * If true, then the shipping documents are required by the DSR. Normally shipping documents are required for cross border shipments.
	 * Required: false
	 * Array: false
	 */
	shipmentDocRequiredInd:boolean = undefined;
	/**
	 * The unique identifier of the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:string = undefined;
	/**
	 * The current dock location of the shipment.
	 * Required: false
	 * Array: false
	 */
	shipmentLocation:string = undefined;
	/**
	 * The count of shipments on this stop.
	 * Required: false
	 * Array: false
	 */
	shipmentsOnStopCount:number = undefined;
	/**
	 * The count of shipments remaining to be loaded/unloaded.
	 * Required: false
	 * Array: false
	 */
	shipmentsRemainingCount:number = undefined;
	/**
	 * The total pallet count of the shipment.
	 * Required: false
	 * Array: false
	 */
	totalPalletCount:number = undefined;
	/**
	 * The total piece count of the shipment.
	 * Required: false
	 * Array: false
	 */
	totalPieceCount:number = undefined;
	/**
	 * The total weight of the shipment.
	 * Required: false
	 * Array: false
	 */
	totalWeight:Weight = undefined;
	/**
	 * The section of the trailer where the shipment is placed.
	 * Required: false
	 * Array: true
	 */
	trailerSections:TrailerSection[] = undefined;
	/**
	 * The equipment ID of the trailer to inspect.
	 * Required: false
	 * Array: false
	 */
	trailerToInspect:string = undefined;
	/**
	 * Usually set to current date time when the request was sent by the handheld.
	 * Required: false
	 * Array: false
	 */
	versionCheckTimestamp:string = undefined;
}
/**
 * Maintenance Indicators for an Equipment
 */
export class EquipmentMaintenanceIndicator {
	/**
	 * mntRtgInd.
	 * Values: E or X
	 * Required: false
	 * Array: false
	 */
	reportingCode:string = undefined;
	/**
	 * mnt pm Ind. Value can be P
	 * Required: false
	 * Array: false
	 */
	preventiveMaintenanceCode:string = undefined;
	/**
	 * mntTTInd. Value can be T
	 * Required: false
	 * Array: false
	 */
	troubleTicketCode:string = undefined;
}
/**
 * Information about Equipment Current Status and Remarks
 */
export class EquipmentStatusRemark {
	/**
	 * Current Location of Equipment
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * Current Status of the Equipment
	 * Required: false
	 * Array: false
	 */
	currentStatus:EquipmentStatusCd = undefined;
	/**
	 * Unique Identifier of the Equipment
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * EquipmentPrefix-Suffix
	 * Required: false
	 * Array: false
	 */
	equipmentNbr:string = undefined;
	/**
	 * Remarks associated with the Equipment
	 * Required: false
	 * Array: false
	 */
	equipmentRemarks:string = undefined;
	/**
	 * Date and time when the Status update was done
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * Validation message associated with Updating the Status and Remark
	 * Required: false
	 * Array: false
	 */
	validationMessage:string = undefined;
}
/**
 * Basic Information about an Equipment
 */
export class ForeignEquipment {
	/**
	 * Unique Id of Equipment
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * Equipment Prefix - Suffix
	 * Required: false
	 * Array: false
	 */
	equipmentNbr:string = undefined;
	/**
	 * This is the 4 character prefix of the equipment id. (eg. 0415 or SWFT etc)
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix:string = undefined;
	/**
	 * Sequencing or serial part of an equipment unit's identifying number (pure numeric form for equipment number is preferred)
	 * Required: false
	 * Array: false
	 */
	equipmentSuffix:string = undefined;
	/**
	 * Equipment Type Cd.Container, Forklift, Dolly, Tractor, Skid, TrailerType -
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:EquipmentTypeCd = undefined;
	/**
	 * Equipment Type Detail Cd This is a 'intelligent' code that will help identify the characteristics of a piece of equipment.
	 * eg. TTC28 - could mean linehaul trailer 28' long.
	 * Required: false
	 * Array: false
	 */
	equipmentTypeDetailCd:string = undefined;
	/**
	 * This is the SCAC code of the carrier that the equipment was rented from or the SCAC code of the Substitute service provider.
	 * Required: false
	 * Array: false
	 */
	foreignScacCd:string = undefined;
}
/**
 * Hazardous material information
 */
export class Hazmat {
	/**
	 * Used to uniquely identify a hazardous material row on a shipment.
	 * Required: false
	 * Array: false
	 */
	hazmatSeqNbr:number = undefined;
	/**
	 * The UN or NA Identification number for a hazardous material. Format: UN0009 or NA0009 (2 letters followed by 4 numbes).
	 * Required: false
	 * Array: false
	 */
	hazmatUnna:string = undefined;
	/**
	 * The primary hazard class for a hazardous material.
	 * Required: false
	 * Array: false
	 */
	hazmatClassCd:string = undefined;
	/**
	 * The weight, in pounds, of the hazardous material (note other tables use DECIMAL(11,2)).
	 * Required: false
	 * Array: false
	 */
	hazmatWeight:number = undefined;
	/**
	 * Indicates the set of federal regulations to impose based on the volume of hazmat of this material.  Values: ' '= Normal or Non-Bulk, 'B' = Bulk, 'L' = Limited Quantity.
	 * Required: false
	 * Array: false
	 */
	hazmatBulkQtyCd:HazmatBulkQuantityCd = undefined;
	/**
	 * If true, then the container has only the residue of hazardous material from a previous use.
	 * Required: false
	 * Array: false
	 */
	hazmatResidueInd:boolean = undefined;
}
/**
 * Information about to where the shipment should be loaded
 */
export class LoadToLocation {
	/**
	 * The unique identifier of the area.
	 * Required: false
	 * Array: false
	 */
	areaInstId:string = undefined;
	/**
	 * The name of the area to which the dock location belongs.
	 * Required: false
	 * Array: false
	 */
	areaName:string = undefined;
	/**
	 * The type of route. Valid values: SPCL Special Svcs, CRTG Cartage, OPER Operating, DOCK Dock.
	 * Required: false
	 * Array: false
	 */
	categoryCd:RouteCategoryCd = undefined;
	/**
	 * The identifying name of the dock location.
	 * Required: false
	 * Array: false
	 */
	dockLocation:string = undefined;
	/**
	 * The count of load deck in a trailer or location.
	 * Required: false
	 * Array: false
	 */
	loadDeckCount:number = undefined;
	/**
	 * The total weight of all the equipment that is loaded onto a trailer.
	 * Required: false
	 * Array: false
	 */
	loadedEquipmentWeight:Weight = undefined;
	/**
	 * The sequence number of the shipment on the trailer with respect to all other shipments loaded on the trailer.
	 * Required: false
	 * Array: false
	 */
	loadSequence:string = undefined;
	/**
	 * The priority number.
	 * Required: false
	 * Array: false
	 */
	priority:string = undefined;
	/**
	 * Identifies the shipment position on the trailer for a given route. Previously, progressiveLoadingCd
	 * Required: false
	 * Array: false
	 */
	positionOnTrailerCd:string = undefined;
	/**
	 * The unique identifier of the released route.
	 * Required: false
	 * Array: false
	 */
	routeInstId:string = undefined;
	/**
	 * The released route name.
	 * Required: false
	 * Array: false
	 */
	routeName:string = undefined;
	/**
	 * The SIC associated with the route.
	 * Required: false
	 * Array: false
	 */
	routeSic:string = undefined;
	/**
	 * The current status.
	 * Required: false
	 * Array: false
	 */
	status:string = undefined;
	/**
	 * Trailer Number. Prefix-Suffix
	 * Required: false
	 * Array: false
	 */
	trailer:string = undefined;
	/**
	 * Total length of the trailer
	 * Required: false
	 * Array: false
	 */
	trailerLength:number = undefined;
	/**
	 * Total loaded volume in cu ft of the trailer
	 * Required: false
	 * Array: false
	 */
	trailerLoadCube:number = undefined;
	/**
	 * Total loaded weight of the trailer
	 * Required: false
	 * Array: false
	 */
	trailerLoadWeight:number = undefined;
}
/**
 * Information about access token for the SMART API integration
 */
export class SmartApiToken {
	/**
	 * Access Token returned from SMART API Call.
	 * Required: false
	 * Array: false
	 */
	access_token:string = undefined;
	/**
	 * When the token expires.
	 * Required: false
	 * Array: false
	 */
	expires_in:number = undefined;
	/**
	 * The Type of the token.  (i.e. Bearer)
	 * Required: false
	 * Array: false
	 */
	token_type:string = undefined;
}
/**
 * Information about the Shipment Dimensions
 */
export class ShipmentDimension {
	/**
	 * Piece Count
	 * Required: false
	 * Array: false
	 */
	pieceCount:number = undefined;
	/**
	 * Dimensions for the piece
	 * Required: false
	 * Array: false
	 */
	dimension:Dimensions = undefined;
}
/**
 * Information about the trailer status
 */
export class Trailer {
	/**
	 * If set to true then Trailer Accuracy check is required
	 * Required: false
	 * Array: false
	 */
	accuracyCheckReqdInd:boolean = undefined;
	/**
	 * Estimated Time of Arrival for the Trailer
	 * Required: false
	 * Array: false
	 */
	etaTimestamp:Date = undefined;
	/**
	 * Indicates if there is a Head Load on this trailer.
	 * Required: false
	 * Array: false
	 */
	headLoadInd:boolean = undefined;
	/**
	 * Name of user and Sic code where the Last Unload was performed on this trailer
	 * Required: false
	 * Array: false
	 */
	lastUnloadNameAndSic:string = undefined;
	/**
	 * Date and Time when the last shipment was unloaded from the Trailer
	 * Required: false
	 * Array: false
	 */
	lastUnloadTimestamp:Date = undefined;
	/**
	 * Unique identifier of the Trailer
	 * Required: false
	 * Array: false
	 */
	trailerInstId:string = undefined;
	/**
	 * The equipment ID prefix-suffix for the trailer. E.g. 313-3760.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * The trailer status.  E.g. Unloading, Loading, Trap, etc.
	 * Required: false
	 * Array: false
	 */
	trailerStatus:TrailerStatusCd = undefined;
	/**
	 * Trailer door assignment details.
	 * Required: false
	 * Array: false
	 */
	trailerDsrAssignment:TrailerDsrAssignment = undefined;
}
/**
 * Information to update Trailer Load Status, Door and Remarks
 */
export class TrailerLoadStatusDoorRemark {
	/**
	 * The door number at the dock where the trailer is assigned.
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * The unique identifier of the trailer.
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * The date and time the update was performed. If not supplied, then the current date and time will be used.
	 * Required: false
	 * Array: false
	 */
	eventTimestamp:Date = undefined;
	/**
	 * The trailer load status. E.g. Arrive, City Arrive, Drop, Empty, Trap, etc.
	 * Required: false
	 * Array: false
	 */
	newTrailerStatus:TrailerStatusCd = undefined;
	/**
	 * Indicates if the user is overriding the status update to Overhead.
	 * Required: false
	 * Array: false
	 */
	overheadStatusOverrideInd:boolean = undefined;
	/**
	 * The shift code. Permitted values: DayReship, Inbound, NightFAC, and Outbound.
	 * Required: false
	 * Array: false
	 */
	shiftCd:ShiftCd = undefined;
	/**
	 * Remarks for the trailer load.
	 * Required: false
	 * Array: false
	 */
	trailerRemarks:string = undefined;
	/**
	 * Remarks associated with the equipment.
	 * Required: false
	 * Array: false
	 */
	equipmentRemarks:string = undefined;
	/**
	 * The validation message for the action performed.
	 * Required: false
	 * Array: false
	 */
	validationMessage:string = undefined;
}
/**
 * Information about the trailer section
 */
export class TrailerSection {
	/**
	 * The section number of the trailer.
	 * Required: false
	 * Array: false
	 */
	sectionNbr:number = undefined;
}
/**
 * 
 */
export class DockLoadedShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DockLocation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	terminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockClosestDoorNbr:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
}
/**
 * 
 */
export class TrailerDsrAssignment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrAssignmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrEmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activeInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	startDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	endDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Door {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displaySeqNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doorNbrTxt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hzntlCoordNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	vrtclCoordNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sectorId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pupOnlyInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dtlCapxTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replLstUpdtTmst:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlTmst:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DoorPlan {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displaySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cutTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	facCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	priorityNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	facSectorNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doorSectorNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	secondFacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	thirdFacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mustMoveSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dayFreightInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DoorPlanProfile {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ownerSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	weekDayNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationReference {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpOfcFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dstFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finFuncCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finScndFuncCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyGroupFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsBgnDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsEndDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pdtTimeOffset:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physLocationType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prvtFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	refSicScpType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltPrimarySequence:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltTermAbbrvName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	kronFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lseExpiryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ownOrLseCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sltStateAbbrv:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pdtTimeOffsetInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	slsSicFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timezoneName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mrcCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanLocationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scanLocationFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trkldXrefCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mileageFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rgnSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safetyClasSzCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physSiteLatd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	physSiteLngt:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpCompanyCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpProfitCenterCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	erpDepartmentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Equipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeDetailCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleSequence:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvnt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvntDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnScacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnReturnSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRespSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRntlCntrNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRntlInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mfrYr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmclSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	wrkRequestInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pmDueDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inServiceDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safeStackInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeToRsiInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	extdSlprLaneInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	limitedAvailableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatusQlfrCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * The equipment prefix and suffix concatenated.
	 * Required: false
	 * Array: false
	 */
	equipmentNbr:string = undefined;
	/**
	 * The validation message associated with Equipment.
	 * Required: false
	 * Array: false
	 */
	validationMessage:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoad:TrailerLoad = undefined;
}
/**
 * 
 */
export class EquipmentHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeDetailCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleSequence:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleIdNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnScacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnReturnSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRespSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRntlCntrNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnRntlInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frgnCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateTransactionSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mfrYr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	domicileSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	wrkRequestInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pmDueDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inServiceDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	safeStackInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeToRsiInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	extdSlprLaneInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	limitedAvailableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatusQlfrCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Table that captures who operated the equipment.  Added initially for forklift use, but can be used by any equipment.  It will be a self-history table, so inserts only.  Added 04/24/2020 
 */
export class EquipmentOperator {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * The date and time the operator logged in to use equipment.
	 * Required: false
	 * Array: false
	 */
	scanDateTime:Date = undefined;
	/**
	 * Employee id of the last person to scan/login to the equipment.
	 * Required: false
	 * Array: false
	 */
	lastOperatorEmployeeId:string = undefined;
	/**
	 * Service Center where equipment is.
	 * Required: false
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HeadLoadDetail {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lseOrSkdCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cbePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cbeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nbrOfFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LoadedEquipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadEquipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LoadedShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dckLrgMarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn1Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn2Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn3Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn4Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn5Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerSectn6Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	misloadInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class Placard {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	placardType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unnaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class ProMovementHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedAtSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	misloadInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	misloadAuthrzUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventShiftCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of ProMovementHist. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class ProMovementHistSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:ProMovementHist = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:ProMovementHist = undefined;
}
/**
 * 
 */
export class Schedule {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleIdNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	milg:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displayUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finalDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	etaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	intmdlScac:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	discrInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	onDfrdLegInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class TrailerLoad {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evntDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvnt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvntDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLengthFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDestination:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedReleaseNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xclvInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closeDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frzblInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	placardInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	garntServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityRoute:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	brkOrProprCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDeckCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dfrdShipmentInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDfrdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealMatchCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	poisonShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evntDoorSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	evntShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInspReqdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedLoadedDestination:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routingRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideRvwRequiredInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	loadedShipment:LoadedShipment[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadDetail:HeadLoadDetail = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	trailerRouting:TrailerRouting[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	trailerInspection:TrailerInspection[] = undefined;
}
/**
 * 
 */
export class TrailerRouting {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerActionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	viaInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unloadPtInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadPtInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	midnightsNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	omtInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	processedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class TrailerInspection {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerEquipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inspectedDateLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inspectedTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inspectedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inspNotApplicableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerStrapsCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	maintTtRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class TrailerLoadHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLengthFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedReleaseNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xclvInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closeDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frzblInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	placardInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityRoute:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	brkOrProprCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDeckCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dfrdShipmentInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDfrdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealMatchCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	poisonShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoorSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInspReqdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedLoadedDestination:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routingRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideRvwRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class EquipmentIdXref {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdMbf:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdMcms:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actDateTime:Date = undefined;
}
/**
 * 
 */
export class ScacCode {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierMcn:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierCity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierState:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierCountry:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierZip:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierPhone:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierChngFld:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierAddressChngCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierAdoptionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierUserCrtdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierLastUpdateUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierLastUpdateDateTime:Date = undefined;
}
/**
 * 
 */
export class StandardCode {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	codeType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	codeValue:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shortDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effEndDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effStartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DimensionsCheckLog {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	checkDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrEmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideExistsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrSelectionTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrSelectedByUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cycleEndDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION 
	 * id is assigned to various rows from different tables that form a single business
	 * 	transactions. In other words, this groups multiple rows across various tables f
	 * or a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DimensionsCheckTrailer {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	checkDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrEmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	processedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideByEmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION
	 * 	id is assigned to various rows from different tables that form a single busines
	 * s transactions. In other words, this groups multiple rows across various tables 
	 * for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is a
	 * ssigned to various rows from different tables that form a single database transa
	 * ctions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Contains information about the Lane the Door is loading to and the type of lane, HSS or LTL, associated with the door plan.  Added 06/09/2020
 */
export class ScoDoorPlan {
	/**
	 * Profile the door plan belongs to.
	 * Required: false
	 * Array: false
	 */
	doorPlanProfileId:number = undefined;
	/**
	 * Identifies the door at the SIC.
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * The sequence in which the door needs to be picked when loading to the same first load point
	 * Required: false
	 * Array: false
	 */
	displaySequenceNbr:string = undefined;
	/**
	 * Priority Number assigned to the Door Plan
	 * Required: false
	 * Array: false
	 */
	priorityNbr:string = undefined;
	/**
	 * First load point for the destination group the door is loading to.
	 * Required: false
	 * Array: false
	 */
	firstLoadPointSicCode:string = undefined;
	/**
	 * Shift the shipments will arrive at the first load point for the destination group the door is loading to
	 * Required: false
	 * Array: false
	 */
	firstLoadPointShiftCd:string = undefined;
	/**
	 * Farthest load point the destination group is loading to. This is the SIC code that is the farthest along the load leg path to destination sics.
	 * Required: false
	 * Array: false
	 */
	farthestLoadPointSicCode:string = undefined;
	/**
	 * Shift the shipments will arrive at the farthest load point for the destination group the door is loading to
	 * Required: false
	 * Array: false
	 */
	farthestLoadPointShiftCd:string = undefined;
	/**
	 * Type of lane.
	 * 
	 * Valid Values:
	 * LINEHAUL
	 * EXCLUSIVE_HSS
	 * SUPPLEMENTAL_HSS
	 * Required: false
	 * Array: false
	 */
	laneTypeCd:string = undefined;
	/**
	 * Sector number to which the door plan is applicable within the Freight Assembly Center (FAC)
	 * Required: false
	 * Array: false
	 */
	facSectorNbr:string = undefined;
	/**
	 * Sector number to which the door plan is applicable within the Service Center
	 * Required: false
	 * Array: false
	 */
	doorSectorNbr:string = undefined;
	/**
	 * Identifies Day Freight - freight that moves through a day point.
	 * 
	 * Y/N
	 * Required: false
	 * Array: false
	 */
	dayFreightInd:boolean = undefined;
	/**
	 * HH24:MM - Latest time when the trailer at this door sould leave. Hour should be from 0 to 23 and MM should be from 00 to 59
	 * Required: false
	 * Array: false
	 */
	cutOffTime:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is assigned to various rows from different tables that form a single database transactions. 
	 * Multiple database transactions could make up one business transaction, which is indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single database transaction.
	 * Typically this is used to publish a message to a queue for consumption or a workflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Contains information about all the Destination locations that the Door is loading to. Door plan can also be setup for Consignee Customer Number.  Added 06/09/2020
 */
export class ScoDoorPlanDestination {
	/**
	 * Profile the door plan belongs to.
	 * Required: false
	 * Array: false
	 */
	doorPlanProfileId:number = undefined;
	/**
	 * Identifies the door at the SIC.
	 * Required: false
	 * Array: false
	 */
	doorNbr:string = undefined;
	/**
	 * Sequential number to uniquely identify the record
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Final destination of shipments that will be loaded to this door
	 * Required: false
	 * Array: false
	 */
	destinationSicCode:string = undefined;
	/**
	 * Destination Postal Code of shipments that will be loaded to this door
	 * Required: false
	 * Array: false
	 */
	destinationPostalCd:string = undefined;
	/**
	 * Consignee whose shipments will be loaded to this door - from legacy CIS system.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustomerNbr:number = undefined;
	/**
	 * Consignee whose shipments will be loaded to this door - from NCIS system.
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncId:number = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is assigned to various rows from different tables that form a single database transactions. 
	 * Multiple database transactions could make up one business transaction, which is indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single database transaction.
	 * Typically this is used to publish a message to a queue for consumption or a workflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Contains information of the Door loading profile. A Loading door profile is based on a SIC and Shift. The SIC Code must be a Linehaul and Active SIC and can be a zone.  Added 06/09/2020
 */
export class ScoDoorPlanProfile {
	/**
	 * System Generated Unique Identifier.
	 * Required: false
	 * Array: false
	 */
	doorPlanProfileId:number = undefined;
	/**
	 * SIC code of the service center to which the door plan profile belongs
	 * Required: false
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * Shift Code for which the door plan profile is created.
	 * 
	 * Valid Values:
	 * OUTBOUND
	 * INBOUND
	 * NIGHT_FAC 
	 * DAY_FAC
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * Unique Name assigned to the Profile
	 * Required: false
	 * Array: false
	 */
	profileName:string = undefined;
	/**
	 * Default value  is DEFAULT.
	 * 
	 * Valid values:
	 * CURRENT
	 * DEFAULT
	 * Required: false
	 * Array: false
	 */
	profileTypeCd:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is assigned to various rows from different tables that form a single database transactions. 
	 * Multiple database transactions could make up one business transaction, which is indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single database transaction.
	 * Typically this is used to publish a message to a queue for consumption or a workflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PlanningTrailer {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	etaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	endDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nextFacSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleOriginSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCubeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadOriginSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billCountNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frzblInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDfrdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr1EmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr1Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr2EmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsr2Name:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedCbePrfCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedNbrOfFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	plannedDoorSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	plannedSectorId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	plannedYardCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleViaSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrBrkOrPropCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrExcludeTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrManualInclInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrOverrideEtaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrPlanAccptInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrDoorAssignmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bestDoorSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bestDoorStepsNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	assignmentDoorStepsNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inclHdldProsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrLoadedDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrTmanRvwInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrDoorReqInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ssrPupTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInclFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reviewRequiredFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	properInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerActnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mustMoveSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedBrkInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nextOutboundSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	partialTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	partialTrailerManualOverrideInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Same value for CORRELATIO
	 * N id is assigned to various rows from different tables that form a single busine
	 * ss transactions. In other words, this groups multiple rows across various tables
	 * 	for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is 
	 * assigned to various rows from different tables that form a single database trans
	 * actions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	thruShipment:ThruShipment[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	xdockShipment:XdockShipment[] = undefined;
}
/**
 * 
 */
export class ProfileSic {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	selectionProfileId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileSicSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	arrivalCutoffTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dateOffsetNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicGroupName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class Projection {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctnSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	adjustedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nextFacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	divertSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Same value for CORRELATION i
	 * d is assigned to various rows from different tables that form a single business 
	 * transactions. In other words, this groups multiple rows across various tables fo
	 * r a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is ass
	 * igned to various rows from different tables that form a single database transact
	 * ions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class SelectionProfile {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	selectionProfileId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enrouteStartTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enrouteEndTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inboundDestinationSicInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundArrivalTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundOverheadTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundTrapTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundEnrouteTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	outboundCloseTrailerInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	weekDayNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	owningSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	profileSic:ProfileSic[] = undefined;
}
/**
 * 
 */
export class ThruShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdServiceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	prjctdDestinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Trip {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripDsrName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualReturnDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	categoryCd:TripCategoryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dsrEmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedReturnDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	helperEmployeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lunchTakenTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopAreaId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	odometerEndNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	odometerStartNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overtimeStartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:TripStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripRmrkInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	terminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cashDueCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timeDateCriticalCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryStopCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryCompletedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	earlyCutoffCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezeCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	liftgateCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupStopCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupCompletedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopCompletedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentRtndCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headloadCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	warrantyCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripCompletedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceValidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mobileDeviceStartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mobileDeviceEndDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedEmptyDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	incompletePickupWeightCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	releaseStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doNotAutoDisplayFlag:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopArrivalDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmsPullId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hssBookingNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmsOutForBidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmsOfferId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	thirdPartyDriverHazmatCertFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastStopNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUsedNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	directionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	departTimeOfDayCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	arrivalTimeOfDayCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createSourceCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	optimizerSourceCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	optimizerRunId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	optimizerTripId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastResequenceDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The total number of motor moves associated with the trip.
	 * Required: false
	 * Array: false
	 */
	totalMotorMoves:number = undefined;
	/**
	 * The identifier of the first Trip Node that was a Stop on this Trip.
	 * Required: false
	 * Array: false
	 */
	firstStopNodeSeqNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * The number of RRS shipments on this 
	 * 
	 * Trip.  Added 10/23/2019
	 * Required: false
	 * Array: false
	 */
	rrsCount:string = undefined;
	/**
	 * The number of Guaranteed by 12 
	 * 
	 * shipments on this Trip.  Added 10/23/2019
	 * Required: false
	 * Array: false
	 */
	guaranteedBy12Count:string = undefined;
}
/**
 * Holds a snapshot of Trip. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class TripSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	historyActionCd:ActionCd = undefined;
	/**
	 * The timestamp of when this snapshot was created.
	 * Required: false
	 * Array: false
	 */
	historyCreatedTimestamp:Date = undefined;
	/**
	 * Snapshot.
	 * Required: false
	 * Array: false
	 */
	trip:Trip = undefined;
	/**
	 * Previous values. Only attributes that are different from snapshot will be populated
	 * Required: false
	 * Array: false
	 */
	tripDiff:Trip = undefined;
}
/**
 * 
 */
export class TripNode {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeTypeCd:NodeTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeTypeCd:TripNodeTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedArriveDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedArriveDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideInstructionInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryFromTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryToTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:TripNodeStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planDistanceFromPrevNode:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	odometerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentRtndCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	delayReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDelayMinimum:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUsedActivitySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopBypassedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryWindowCalculatedOffsetMinutes:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryActivityCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupActivityCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentActivityCount:number = undefined;
	/**
	 * The planned distance from the previous node captured with up to 2 places decimal.  Added 10/08/2019
	 * Required: false
	 * Array: false
	 */
	plannedDistanceFromNodeMl:number = undefined;
	/**
	 * Identifies the type of trip; denormed from SCO_TRIP.  At present, it is needed solely for Linehaul.  Added 04/15/2020
	 * 
	 * VALID VALUES
	 * L=Linehaul
	 * (null)
	 * Required: false
	 * Array: false
	 */
	tripTypeCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates if a customer picks up or drops off freight at the dock.
	 * Required: false
	 * Array: false
	 */
	dockProfileInd:boolean = undefined;
	/**
	 * Indicates that a customer has a forklift.
	 * Required: false
	 * Array: false
	 */
	forkliftInd:boolean = undefined;
	/**
	 * The type of customer location; i.e. business/commercial, distribution center, construction site, etc.
	 * Required: false
	 * Array: false
	 */
	customerLocationType:string = undefined;
	/**
	 * The employee ID of the DSR.
	 * Required: false
	 * Array: false
	 */
	driverEmployeeId:string = undefined;
}
/**
 * Holds a snapshot of TripNode. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class TripNodeSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	historyActionCd:ActionCd = undefined;
	/**
	 * The timestamp of when this snapshot was created.
	 * Required: false
	 * Array: false
	 */
	historyCreatedTimestamp:Date = undefined;
	/**
	 * Snapshot.
	 * Required: false
	 * Array: false
	 */
	tripNode:TripNode = undefined;
	/**
	 * Previous values. Only attributes that are different from snapshot will be populated
	 * Required: false
	 * Array: false
	 */
	tripNodeDiff:TripNode = undefined;
}
/**
 * 
 */
export class TripNodeActivity {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeActivitySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activityCd:TripNodeActivityCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	entityTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryInformationRqdCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualActivityDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualActivityDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	swingEquipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	swingTripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cashDueInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	earlyCutoffCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezeCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headloadCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	liftgateInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timeDateCriticalInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	appointmentInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalBillCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPalletCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalMmCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Defines the specific 
	 * 
	 * type of service provided for a shipment.    Added 10/23/2019                           
	 * 
	 * 																																																	
	 * Values:  
	 * 1 - Normal LTL
	 * 2 - Con-Way Deferred Express (Deprecated)
	 * 3 - LTL Expedite Service
	 * 4 - Guaranteed by 12
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:ShipmentServiceTypeCd = undefined;
	/**
	 * Indicates that a shipment has bulk liquid.
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
}
/**
 * Holds a snapshot of TripNodeActivity. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class TripNodeActivitySnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:TripNodeActivity = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:TripNodeActivity = undefined;
}
/**
 * 
 */
export class XdockPlan {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	driverCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoInclReshipFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	planningTrailer:PlanningTrailer[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	xdockShipment:XdockShipment[] = undefined;
}
/**
 * 
 */
export class XdockShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xdockPlanInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xdockShipmentSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xdockProgressCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planInclInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalMmCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ttlPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ttlWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	divertSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnNextMoveSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnLoadToShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnHssCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnDayInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	runId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dvrsnInstructionSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hssScheduledDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hssLoadedGroupSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
export class AddForeignEquipmentResp_ForeignEquipment extends ForeignEquipment {
	/**
	 * Validation messages that are used when adding the equipment.
	 * Required: false
	 * Array: false
	 */
	messages:string = undefined;
}



