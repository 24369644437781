import {ClaimFieldName} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimFieldNameHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimFieldName ) {
      this._values.push(ClaimFieldName[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimFieldName {

    for ( const obj in ClaimFieldName ) {
      if (ClaimFieldName[obj] === value){
        return ClaimFieldName[obj] as ClaimFieldName;
      }
    }
  }
}

const ClaimFieldNameHelper = new ClaimFieldNameHelperEnumHelperClass();
export default ClaimFieldNameHelper;
