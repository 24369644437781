import {ClaimExternalVisibilityCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimExternalVisibilityCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimExternalVisibilityCd ) {
      this._values.push(ClaimExternalVisibilityCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimExternalVisibilityCd {

    for ( const obj in ClaimExternalVisibilityCd ) {
      if (ClaimExternalVisibilityCd[obj] === value){
        return ClaimExternalVisibilityCd[obj] as ClaimExternalVisibilityCd;
      }
    }
  }
}

const ClaimExternalVisibilityCdHelper = new ClaimExternalVisibilityCdHelperEnumHelperClass();
export default ClaimExternalVisibilityCdHelper;
