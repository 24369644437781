import {ClaimCurrencyCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimCurrencyCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimCurrencyCd ) {
      this._values.push(ClaimCurrencyCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimCurrencyCd {

    for ( const obj in ClaimCurrencyCd ) {
      if (ClaimCurrencyCd[obj] === value){
        return ClaimCurrencyCd[obj] as ClaimCurrencyCd;
      }
    }
  }
}

const ClaimCurrencyCdHelper = new ClaimCurrencyCdHelperEnumHelperClass();
export default ClaimCurrencyCdHelper;
