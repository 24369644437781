
export enum MovementExceptionTypeCd {
	AD_HOC = 'AdHoc',
	DAMAGED = 'Damaged',
	DAMAGED_BUT_ACCEPTED = 'DamagedButAccepted',
	EXCP_NOTED = 'ExcpNoted',
	OVER = 'Over',
	OVER_BUT_ACCEPTED = 'OverButAccepted',
	REFUSED = 'Refused',
	SHORT = 'Short',
	UNDELIVERABLE = 'Undeliverable'}

