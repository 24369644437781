import {RateDiscountSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class RateDiscountSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RateDiscountSourceCd ) {
      this._values.push(RateDiscountSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RateDiscountSourceCd {

    for ( const obj in RateDiscountSourceCd ) {
      if (RateDiscountSourceCd[obj] === value){
        return RateDiscountSourceCd[obj] as RateDiscountSourceCd;
      }
    }
  }
}

const RateDiscountSourceCdHelper = new RateDiscountSourceCdHelperEnumHelperClass();
export default RateDiscountSourceCdHelper;
