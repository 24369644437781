import {TripNodeTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class TripNodeTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TripNodeTypeCd ) {
      this._values.push(TripNodeTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TripNodeTypeCd {

    for ( const obj in TripNodeTypeCd ) {
      if (TripNodeTypeCd[obj] === value){
        return TripNodeTypeCd[obj] as TripNodeTypeCd;
      }
    }
  }
}

const TripNodeTypeCdHelper = new TripNodeTypeCdHelperEnumHelperClass();
export default TripNodeTypeCdHelper;
