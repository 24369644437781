import {ExceptionSummaryTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ExceptionSummaryTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ExceptionSummaryTypeCd ) {
      this._values.push(ExceptionSummaryTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ExceptionSummaryTypeCd {

    for ( const obj in ExceptionSummaryTypeCd ) {
      if (ExceptionSummaryTypeCd[obj] === value){
        return ExceptionSummaryTypeCd[obj] as ExceptionSummaryTypeCd;
      }
    }
  }
}

const ExceptionSummaryTypeCdHelper = new ExceptionSummaryTypeCdHelperEnumHelperClass();
export default ExceptionSummaryTypeCdHelper;
