import {WarrantyStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class WarrantyStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in WarrantyStatusCd ) {
      this._values.push(WarrantyStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): WarrantyStatusCd {

    for ( const obj in WarrantyStatusCd ) {
      if (WarrantyStatusCd[obj] === value){
        return WarrantyStatusCd[obj] as WarrantyStatusCd;
      }
    }
  }
}

const WarrantyStatusCdHelper = new WarrantyStatusCdHelperEnumHelperClass();
export default WarrantyStatusCdHelper;
