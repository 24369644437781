import {FoodPoisonCd} from '../';
import {EnumHelper} from './enum-helper';

export class FoodPoisonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in FoodPoisonCd ) {
      this._values.push(FoodPoisonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): FoodPoisonCd {

    for ( const obj in FoodPoisonCd ) {
      if (FoodPoisonCd[obj] === value){
        return FoodPoisonCd[obj] as FoodPoisonCd;
      }
    }
  }
}

const FoodPoisonCdHelper = new FoodPoisonCdHelperEnumHelperClass();
export default FoodPoisonCdHelper;
