import {DynamicPriceApplicationTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DynamicPriceApplicationTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DynamicPriceApplicationTypeCd ) {
      this._values.push(DynamicPriceApplicationTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DynamicPriceApplicationTypeCd {

    for ( const obj in DynamicPriceApplicationTypeCd ) {
      if (DynamicPriceApplicationTypeCd[obj] === value){
        return DynamicPriceApplicationTypeCd[obj] as DynamicPriceApplicationTypeCd;
      }
    }
  }
}

const DynamicPriceApplicationTypeCdHelper = new DynamicPriceApplicationTypeCdHelperEnumHelperClass();
export default DynamicPriceApplicationTypeCdHelper;
