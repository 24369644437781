import {ShipmentRemarkTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentRemarkTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentRemarkTypeCd ) {
      this._values.push(ShipmentRemarkTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentRemarkTypeCd {

    for ( const obj in ShipmentRemarkTypeCd ) {
      if (ShipmentRemarkTypeCd[obj] === value){
        return ShipmentRemarkTypeCd[obj] as ShipmentRemarkTypeCd;
      }
    }
  }
}

const ShipmentRemarkTypeCdHelper = new ShipmentRemarkTypeCdHelperEnumHelperClass();
export default ShipmentRemarkTypeCdHelper;
