import {LengthUomCd} from '../';
import {EnumHelper} from './enum-helper';

export class LengthUomCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LengthUomCd ) {
      this._values.push(LengthUomCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LengthUomCd {

    for ( const obj in LengthUomCd ) {
      if (LengthUomCd[obj] === value){
        return LengthUomCd[obj] as LengthUomCd;
      }
    }
  }
}

const LengthUomCdHelper = new LengthUomCdHelperEnumHelperClass();
export default LengthUomCdHelper;
