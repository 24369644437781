import {ClaimEmailTemplateFieldCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimEmailTemplateFieldCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimEmailTemplateFieldCd ) {
      this._values.push(ClaimEmailTemplateFieldCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimEmailTemplateFieldCd {

    for ( const obj in ClaimEmailTemplateFieldCd ) {
      if (ClaimEmailTemplateFieldCd[obj] === value){
        return ClaimEmailTemplateFieldCd[obj] as ClaimEmailTemplateFieldCd;
      }
    }
  }
}

const ClaimEmailTemplateFieldCdHelper = new ClaimEmailTemplateFieldCdHelperEnumHelperClass();
export default ClaimEmailTemplateFieldCdHelper;
