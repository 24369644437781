
export enum UndeliveredReasonCd {
	CONSTRUCTION_SITE = 'ConstructionSite',
	CONS_IN_LABOR_DISPUTE = 'ConsInLaborDispute',
	CONS_MOVED = 'ConsMoved',
	CONS_NOT_FOUND_AT_ADDR_OR_PHON = 'ConsNotFoundAtAddrOrPhon',
	CREDIT_HOLD = 'CreditHold',
	INSUFFICIENT_DLVRY_TM = 'InsufficientDlvryTm',
	NO_RSPNS_TO_ARRIVAL_NTFICTN = 'NoRspnsToArrivalNtfictn',
	OTHER = 'Other',
	SUSPENDED_AT_CUST_RQST = 'SuspendedAtCustRqst'}

