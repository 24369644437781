import {DynamicPriceStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class DynamicPriceStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DynamicPriceStatusCd ) {
      this._values.push(DynamicPriceStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DynamicPriceStatusCd {

    for ( const obj in DynamicPriceStatusCd ) {
      if (DynamicPriceStatusCd[obj] === value){
        return DynamicPriceStatusCd[obj] as DynamicPriceStatusCd;
      }
    }
  }
}

const DynamicPriceStatusCdHelper = new DynamicPriceStatusCdHelperEnumHelperClass();
export default DynamicPriceStatusCdHelper;
