import {EventTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class EventTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EventTypeCd ) {
      this._values.push(EventTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EventTypeCd {

    for ( const obj in EventTypeCd ) {
      if (EventTypeCd[obj] === value){
        return EventTypeCd[obj] as EventTypeCd;
      }
    }
  }
}

const EventTypeCdHelper = new EventTypeCdHelperEnumHelperClass();
export default EventTypeCdHelper;
