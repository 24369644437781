import {TransitTimeDirectionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class TransitTimeDirectionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TransitTimeDirectionTypeCd ) {
      this._values.push(TransitTimeDirectionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TransitTimeDirectionTypeCd {

    for ( const obj in TransitTimeDirectionTypeCd ) {
      if (TransitTimeDirectionTypeCd[obj] === value){
        return TransitTimeDirectionTypeCd[obj] as TransitTimeDirectionTypeCd;
      }
    }
  }
}

const TransitTimeDirectionTypeCdHelper = new TransitTimeDirectionTypeCdHelperEnumHelperClass();
export default TransitTimeDirectionTypeCdHelper;
