import {CustomsPortCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomsPortCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomsPortCd ) {
      this._values.push(CustomsPortCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomsPortCd {

    for ( const obj in CustomsPortCd ) {
      if (CustomsPortCd[obj] === value){
        return CustomsPortCd[obj] as CustomsPortCd;
      }
    }
  }
}

const CustomsPortCdHelper = new CustomsPortCdHelperEnumHelperClass();
export default CustomsPortCdHelper;
