
export enum RuleOperatorCd {
	ANY_IN = 'AnyIn',
	ANY_NOT_IN = 'AnyNotIn',
	EQUAL_TO = 'EqualTo',
	GREATER_THAN = 'GreaterThan',
	GREATER_THAN_OR_EQUAL = 'GreaterThanOrEqual',
	IN = 'In',
	LESS_THAN = 'LessThan',
	LESS_THAN_OR_EQUAL = 'LessThanOrEqual',
	MATCHES = 'Matches',
	NOT_EQUAL_TO = 'NotEqualTo',
	NOT_IN = 'NotIn',
	NOT_MATCHES = 'NotMatches'}

