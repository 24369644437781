import {InvoiceTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceTypeCd ) {
      this._values.push(InvoiceTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceTypeCd {

    for ( const obj in InvoiceTypeCd ) {
      if (InvoiceTypeCd[obj] === value){
        return InvoiceTypeCd[obj] as InvoiceTypeCd;
      }
    }
  }
}

const InvoiceTypeCdHelper = new InvoiceTypeCdHelperEnumHelperClass();
export default InvoiceTypeCdHelper;
