import {AgendaItemStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class AgendaItemStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in AgendaItemStatusCd ) {
      this._values.push(AgendaItemStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): AgendaItemStatusCd {

    for ( const obj in AgendaItemStatusCd ) {
      if (AgendaItemStatusCd[obj] === value){
        return AgendaItemStatusCd[obj] as AgendaItemStatusCd;
      }
    }
  }
}

const AgendaItemStatusCdHelper = new AgendaItemStatusCdHelperEnumHelperClass();
export default AgendaItemStatusCdHelper;
