/**
 * UserPreference API
 * Version: 1.0
 * Build: 1.0-manual_b3
 */

import {
	AuditInfo,
	ActionCd
} from '@xpo-ltl/sdk-common';

/**
 * Request for upsertUserPreference
 */
export class UpsertUserPreferenceRqst {
	/**
	 * Name of the UI Component. If omitted, upsert the default preferences for the UI component
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * Id of user for whom the preferences are to be stored.
	 * 					By default, the id is determined by the API gateway based on subscription.
	 * 					The Id passed here will only be used when there is a generic subscription
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * If true, upsert the UI component default preferences
	 * Required: false
	 * Array: false
	 */
	uiComponentDefault:boolean = undefined;
	/**
	 * If true, upsert the application default preferences
	 * Required: false
	 * Array: false
	 */
	appDefault:boolean = undefined;
	/**
	 * If true, upsert the global default preferences
	 * Required: false
	 * Array: false
	 */
	globalDefault:boolean = undefined;
	/**
	 * The preferences in JSON format
	 * Required: false
	 * Array: false
	 */
	preferences:string = undefined;
}
/**
 * Request for getUserPreference
 */
export class GetUserPreferenceRqst {
	/**
	 * Id of user for whom the preferences are to be stored.
	 * 					By default, the id is determined by the API gateway based on subscription.
	 * 					The Id passed here will only be used when there is a generic subscription
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * Name of the UI Component.
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * If true, retrieve the UI component default preferences
	 * Required: false
	 * Array: false
	 */
	uiComponentDefault:boolean = undefined;
	/**
	 * If true, retrieve the application default preferences
	 * Required: false
	 * Array: false
	 */
	appDefault:boolean = undefined;
	/**
	 * If true, retrieve the global default preferences
	 * Required: false
	 * Array: false
	 */
	globalDefault:boolean = undefined;
}
/**
 * Response for getUserPreference
 */
export class GetUserPreferenceResp {
	/**
	 * The preferences in JSON format
	 * Required: false
	 * Array: false
	 */
	preferences:string = undefined;
}
/**
 * Stores default preferences for applications
 */
export class ApplicationPreference {
	/**
	 * unique identifier of the UPF_APPLICATION table. this key is generated from a sequence.
	 * Required: false
	 * Array: false
	 */
	applicationInstId:number = undefined;
	/**
	 * name of the application. The application name will be decided and created on WSO2 gateway during the API subscription process.
	 * Required: false
	 * Array: false
	 */
	applicationName:string = undefined;
	/**
	 * Default preferences for the application.
	 * Required: false
	 * Array: false
	 */
	applicationPreference:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Stores default preferences for ui component
 */
export class UiComponentPreference {
	/**
	 * unique identifier of the UI_CMPNT_INST_ID table. this key is generated from a sequence.
	 * Required: false
	 * Array: false
	 */
	uiComponentInstId:number = undefined;
	/**
	 * name of the UI component.
	 * Required: false
	 * Array: false
	 */
	uiComponentName:string = undefined;
	/**
	 * Default preferences for the UI component. Overrides or augments application default preferences
	 * Required: false
	 * Array: false
	 */
	uiComponentPreference:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Stores preferences for user
 */
export class UserPreference {
	/**
	 * ID of the user for whom the preferences apply.
	 * Required: false
	 * Array: false
	 */
	userId:string = undefined;
	/**
	 * app identifier.
	 * Required: false
	 * Array: false
	 */
	applicationInstId:number = undefined;
	/**
	 * UI component identifier.
	 * Required: false
	 * Array: false
	 */
	uiComponentInstId:number = undefined;
	/**
	 * Preferences for the user applicable to the UI component/application. Overrides or augments application/UI Component default preferences
	 * Required: false
	 * Array: false
	 */
	userPreference:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}

/**
 * Query parameters for getUserPreference
 */
export class GetUserPreferenceQuery {
	userId: string = undefined;
	uiComponentName: string = undefined;
	uiComponentDefault: string = undefined;
	appDefault: string = undefined;
	globalDefault: string = undefined;
}

/**
 * Query parameters for upsertUserPreference
 */
export class UpsertUserPreferenceQuery {
	userId: string = undefined;
}



