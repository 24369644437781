
export enum MovementStatusCd {
	CANCELLED = 'Cancelled',
	FINAL_DLVD = 'FinalDlvd',
	INTERIM_DLVRY = 'InterimDlvry',
	NOT_APPLICABLE = 'NotApplicable',
	NO_MVMT_ALLOWED = 'NoMvmtAllowed',
	ON_DOCK = 'OnDock',
	ON_TRAILER = 'OnTrailer',
	OUT_FOR_DLVRY = 'OutForDlvry'}

