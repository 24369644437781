import {CommodityClaimTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CommodityClaimTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CommodityClaimTypeCd ) {
      this._values.push(CommodityClaimTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CommodityClaimTypeCd {

    for ( const obj in CommodityClaimTypeCd ) {
      if (CommodityClaimTypeCd[obj] === value){
        return CommodityClaimTypeCd[obj] as CommodityClaimTypeCd;
      }
    }
  }
}

const CommodityClaimTypeCdHelper = new CommodityClaimTypeCdHelperEnumHelperClass();
export default CommodityClaimTypeCdHelper;
