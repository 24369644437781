import {CisRequestPriorityCd} from '../';
import {EnumHelper} from './enum-helper';

export class CisRequestPriorityCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CisRequestPriorityCd ) {
      this._values.push(CisRequestPriorityCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CisRequestPriorityCd {

    for ( const obj in CisRequestPriorityCd ) {
      if (CisRequestPriorityCd[obj] === value){
        return CisRequestPriorityCd[obj] as CisRequestPriorityCd;
      }
    }
  }
}

const CisRequestPriorityCdHelper = new CisRequestPriorityCdHelperEnumHelperClass();
export default CisRequestPriorityCdHelper;
