import {DynamicPriceEventTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DynamicPriceEventTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DynamicPriceEventTypeCd ) {
      this._values.push(DynamicPriceEventTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DynamicPriceEventTypeCd {

    for ( const obj in DynamicPriceEventTypeCd ) {
      if (DynamicPriceEventTypeCd[obj] === value){
        return DynamicPriceEventTypeCd[obj] as DynamicPriceEventTypeCd;
      }
    }
  }
}

const DynamicPriceEventTypeCdHelper = new DynamicPriceEventTypeCdHelperEnumHelperClass();
export default DynamicPriceEventTypeCdHelper;
