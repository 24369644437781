
export enum TripDetailCd {
	ACTIVITIES = 'Activities',
	CUSTOMER = 'Customer',
	DRIVER = 'Driver',
	METRICS = 'Metrics',
	ROUTES = 'Routes',
	SPECIAL_SERVICES = 'SpecialServices',
	STOPS = 'Stops',
	TRIP = 'Trip',
	WINDOWS = 'Windows'}

