import {PartyRoleCd} from '../';
import {EnumHelper} from './enum-helper';

export class PartyRoleCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PartyRoleCd ) {
      this._values.push(PartyRoleCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PartyRoleCd {

    for ( const obj in PartyRoleCd ) {
      if (PartyRoleCd[obj] === value){
        return PartyRoleCd[obj] as PartyRoleCd;
      }
    }
  }
}

const PartyRoleCdHelper = new PartyRoleCdHelperEnumHelperClass();
export default PartyRoleCdHelper;
