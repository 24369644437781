import {DisputePartyTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputePartyTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputePartyTypeCd ) {
      this._values.push(DisputePartyTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputePartyTypeCd {

    for ( const obj in DisputePartyTypeCd ) {
      if (DisputePartyTypeCd[obj] === value){
        return DisputePartyTypeCd[obj] as DisputePartyTypeCd;
      }
    }
  }
}

const DisputePartyTypeCdHelper = new DisputePartyTypeCdHelperEnumHelperClass();
export default DisputePartyTypeCdHelper;
