
export enum CustomsPortCd {
	BLAINE_WASHINGTON = 'BlaineWashington',
	BUFFALO_NIAGARA_FALLS_NEW_YORK = 'BuffaloNiagaraFallsNewYork',
	CALAIS_MAINE = 'CalaisMaine',
	DETROIT_MICHIGAN = 'DetroitMichigan',
	GRAND_PORTAGE_MINNESOTA = 'GrandPortageMinnesota',
	HARTFORD_CONNECTICUT = 'HartfordConnecticut',
	HIGHGATE_SPRINGS_ALBANY_VERMONT = 'HighgateSpringsAlbanyVermont',
	HOULTON_MAINE = 'HoultonMaine',
	PEMBINA_NORTH_DAKOTA = 'PembinaNorthDakota',
	PORTAL_NORTH_DAKOTA = 'PortalNorthDakota',
	PORT_HURON_MICHIGAN = 'PortHuronMichigan',
	PROVIDENCE_RHODE_ISLAND = 'ProvidenceRhodeIsland',
	SWEETGRASS_MONTANA = 'SweetgrassMontana',
	TEMP_0231 = 'Temp0231',
	TEMP_0351 = 'Temp0351',
	TEMP_0427 = 'Temp0427',
	TEMP_0440 = 'Temp0440',
	TEMP_0453 = 'Temp0453',
	TEMP_0475 = 'Temp0475',
	TEMP_0705 = 'Temp0705',
	TEMP_0712 = 'Temp0712',
	TEMP_0813 = 'Temp0813'}

