import {ShipmentVolumeTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentVolumeTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentVolumeTypeCd ) {
      this._values.push(ShipmentVolumeTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentVolumeTypeCd {

    for ( const obj in ShipmentVolumeTypeCd ) {
      if (ShipmentVolumeTypeCd[obj] === value){
        return ShipmentVolumeTypeCd[obj] as ShipmentVolumeTypeCd;
      }
    }
  }
}

const ShipmentVolumeTypeCdHelper = new ShipmentVolumeTypeCdHelperEnumHelperClass();
export default ShipmentVolumeTypeCdHelper;
