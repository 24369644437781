import {DynamicPriceRateTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DynamicPriceRateTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DynamicPriceRateTypeCd ) {
      this._values.push(DynamicPriceRateTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DynamicPriceRateTypeCd {

    for ( const obj in DynamicPriceRateTypeCd ) {
      if (DynamicPriceRateTypeCd[obj] === value){
        return DynamicPriceRateTypeCd[obj] as DynamicPriceRateTypeCd;
      }
    }
  }
}

const DynamicPriceRateTypeCdHelper = new DynamicPriceRateTypeCdHelperEnumHelperClass();
export default DynamicPriceRateTypeCdHelper;
