import {CustomerFunctionCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerFunctionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerFunctionCd ) {
      this._values.push(CustomerFunctionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerFunctionCd {

    for ( const obj in CustomerFunctionCd ) {
      if (CustomerFunctionCd[obj] === value){
        return CustomerFunctionCd[obj] as CustomerFunctionCd;
      }
    }
  }
}

const CustomerFunctionCdHelper = new CustomerFunctionCdHelperEnumHelperClass();
export default CustomerFunctionCdHelper;
