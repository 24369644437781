import {HostlerInstructionCd} from '../';
import {EnumHelper} from './enum-helper';

export class HostlerInstructionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in HostlerInstructionCd ) {
      this._values.push(HostlerInstructionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): HostlerInstructionCd {

    for ( const obj in HostlerInstructionCd ) {
      if (HostlerInstructionCd[obj] === value){
        return HostlerInstructionCd[obj] as HostlerInstructionCd;
      }
    }
  }
}

const HostlerInstructionCdHelper = new HostlerInstructionCdHelperEnumHelperClass();
export default HostlerInstructionCdHelper;
