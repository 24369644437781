import {RouteTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RouteTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RouteTypeCd ) {
      this._values.push(RouteTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RouteTypeCd {

    for ( const obj in RouteTypeCd ) {
      if (RouteTypeCd[obj] === value){
        return RouteTypeCd[obj] as RouteTypeCd;
      }
    }
  }
}

const RouteTypeCdHelper = new RouteTypeCdHelperEnumHelperClass();
export default RouteTypeCdHelper;
