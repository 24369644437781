import {MetricValueKeyCd} from '../';
import {EnumHelper} from './enum-helper';

export class MetricValueKeyCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MetricValueKeyCd ) {
      this._values.push(MetricValueKeyCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MetricValueKeyCd {

    for ( const obj in MetricValueKeyCd ) {
      if (MetricValueKeyCd[obj] === value){
        return MetricValueKeyCd[obj] as MetricValueKeyCd;
      }
    }
  }
}

const MetricValueKeyCdHelper = new MetricValueKeyCdHelperEnumHelperClass();
export default MetricValueKeyCdHelper;
