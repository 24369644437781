import {ModelGroupCd} from '../';
import {EnumHelper} from './enum-helper';

export class ModelGroupCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ModelGroupCd ) {
      this._values.push(ModelGroupCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ModelGroupCd {

    for ( const obj in ModelGroupCd ) {
      if (ModelGroupCd[obj] === value){
        return ModelGroupCd[obj] as ModelGroupCd;
      }
    }
  }
}

const ModelGroupCdHelper = new ModelGroupCdHelperEnumHelperClass();
export default ModelGroupCdHelper;
