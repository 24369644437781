import {DeliveryQualifierCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeliveryQualifierCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeliveryQualifierCd ) {
      this._values.push(DeliveryQualifierCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeliveryQualifierCd {

    for ( const obj in DeliveryQualifierCd ) {
      if (DeliveryQualifierCd[obj] === value){
        return DeliveryQualifierCd[obj] as DeliveryQualifierCd;
      }
    }
  }
}

const DeliveryQualifierCdHelper = new DeliveryQualifierCdHelperEnumHelperClass();
export default DeliveryQualifierCdHelper;
