import {RuleFailureReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class RuleFailureReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RuleFailureReasonCd ) {
      this._values.push(RuleFailureReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RuleFailureReasonCd {

    for ( const obj in RuleFailureReasonCd ) {
      if (RuleFailureReasonCd[obj] === value){
        return RuleFailureReasonCd[obj] as RuleFailureReasonCd;
      }
    }
  }
}

const RuleFailureReasonCdHelper = new RuleFailureReasonCdHelperEnumHelperClass();
export default RuleFailureReasonCdHelper;
