import {ProTrackingLabelStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProTrackingLabelStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProTrackingLabelStatusCd ) {
      this._values.push(ProTrackingLabelStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProTrackingLabelStatusCd {

    for ( const obj in ProTrackingLabelStatusCd ) {
      if (ProTrackingLabelStatusCd[obj] === value){
        return ProTrackingLabelStatusCd[obj] as ProTrackingLabelStatusCd;
      }
    }
  }
}

const ProTrackingLabelStatusCdHelper = new ProTrackingLabelStatusCdHelperEnumHelperClass();
export default ProTrackingLabelStatusCdHelper;
