import {SymphonyPubDeliverToCd} from '../';
import {EnumHelper} from './enum-helper';

export class SymphonyPubDeliverToCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in SymphonyPubDeliverToCd ) {
      this._values.push(SymphonyPubDeliverToCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): SymphonyPubDeliverToCd {

    for ( const obj in SymphonyPubDeliverToCd ) {
      if (SymphonyPubDeliverToCd[obj] === value){
        return SymphonyPubDeliverToCd[obj] as SymphonyPubDeliverToCd;
      }
    }
  }
}

const SymphonyPubDeliverToCdHelper = new SymphonyPubDeliverToCdHelperEnumHelperClass();
export default SymphonyPubDeliverToCdHelper;
