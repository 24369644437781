import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetDoorDetailsResp,
	GetDoorDetailsQuery,
	GetDoorDetailsPath,
	GetDoorSetupDetailsResp,
	GetDoorSetupDetailsQuery,
	GetDoorSetupDetailsPath,
	CreateDockLocationRqst,
	CreateDockLocationResp,
	DeleteDockLocationPath,
	GetDockLocationResp,
	GetDockLocationPath,
	ListDockLocationsResp,
	ListDockLocationsQuery,
	UpdateDockLocationRemarksRqst,
	UpdateDockLocationRemarksResp,
	UpdateDockLocationRemarksPath,
	GetCurrentDockEngineeredStandardResp,
	GetCurrentDockEngineeredStandardQuery,
	GetCurrentDockEngineeredStandardPath,
	GetCurrentMotorMovesPerHourResp,
	GetCurrentMotorMovesPerHourQuery,
	GetCurrentMotorMovesPerHourPath,
	GetSmartApiTokenResp,
	GetTrailerDetailsByDoorResp,
	GetTrailerDetailsByDoorPath,
	BulkUpdateTrailerLoadStatusDoorRemarksRqst,
	BulkUpdateTrailerLoadStatusDoorRemarksResp,
	BulkUpdateTrailerLoadStatusDoorRemarksPath,
	ListBreakTrailersResp,
	ListBreakTrailersQuery,
	ListBreakTrailersPath,
	ListReshipFreightTrailersResp,
	ListReshipFreightTrailersQuery,
	ListReshipFreightTrailersPath,
	ListShipmentsInDockPlanResp,
	ListShipmentsInDockPlanQuery,
	ListShipmentsInDockPlanPath,
	RemoveTrailersFromDockPlanRqst,
	RemoveTrailersFromDockPlanResp,
	RemoveTrailersFromDockPlanPath,
	UpdateTrailerDsrAssignmentRqst,
	UpdateTrailerDsrAssignmentResp,
	UpdateTrailerDsrAssignmentPath,
	AddForeignEquipmentRqst,
	AddForeignEquipmentResp,
	BulkUpdateEquipmentStatusRemarkRqst,
	BulkUpdateEquipmentStatusRemarkResp,
	CreateForkliftScanRqst,
	CreateForkliftScanResp,
	CreateEquipmentScanRqst,
	CreateEquipmentScanResp,
	GetForkliftScanByEmployeeResp,
	GetForkliftScanByEmployeePath,
	ListEquipmentBySicResp,
	ListEquipmentBySicQuery,
	ListEquipmentBySicPath
} from './api-dockoperations';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class DockOperationsApiService extends BaseService {
	private static DockOperationsApiEndpoint = 'dockoperationsApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* For the SIC, shift, and door provided, this operation will return details related to the door information including: trailer at the door, loaded shipment count, remaining to load count, and route or door plan info.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid SIC, shift, and door number must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If there is a trailer at the door, then trailer details are returned.
	* <br/>2. If the door doesn't exist, an error will be returned.
	*/
	public getDoorDetails(
							   pathParams: GetDoorDetailsPath,
							   queryParams: GetDoorDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetDoorDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/doors/{doorNbr}/shifts/{shiftCd}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* For the SIC, shift, and door provided, this operation will return door setup details for the dock door.
	* <br/>
	* <br/>Mandatory Input: SIC, door number, and shift code.
	* <br/>
	* <br/>Optional Input: dock location type (door or bay) and detail indicator (true or false). If not supplied, then false is assumed and only minimum information is returned.
	* <br/>If detail indicator is false, then only the dock door setup (minimum) information is returned.
	* <br/>If detail indicator is true, then dock door detailed information (remaining shipments count, remaining shipments cube, remaining shipments weight, loaded shipment count, etc.) is returned.
	* <br/>The dock location type is not required when detail indicator is false. In this case the dock location is assumed to be the door. If the detail indicator is true, then the dock location type can be specified. Valid values are door or bay (dock location).
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid SIC, shift, and door number must be provided. The optional parameterdetailInd is either set to false or not supplied.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, door setup detail, trailerNbr, trailerStatus, loadDestinationSic, currentPlan, and profile ID will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	* <br/>
	*/
	public getDoorSetupDetails(
							   pathParams: GetDoorSetupDetailsPath,
							   queryParams: GetDoorSetupDetailsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetDoorSetupDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/dock-doors/{doorNbr}/shifts/{shiftCd}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Creates a new dock location record for a given service center. The service center must be an active linehaul service center.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Dock location detail and a valid service center are provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If succesful, then the dock location record is created.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public createDockLocation(request: CreateDockLocationRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateDockLocationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/dock-locations'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation deletes a dock location for a valid dock location instance ID that is provided. If there are any shipments associated with the dock location, then the dock location can't be deleted.
	* <br/>
	* <br/>Use Case 1		
	* <br/>Pre-condition: A valid dock location instance ID is provided and no shipments are associated with the dock location.
	* <br/>Post-condition: The dock location for the input provided is deleted. 
	* <br/>
	* <br/>Use Case 2
	* <br/>Pre-condition: An invalid dock location instance ID is supplied.
	* <br/>Post-condition: A status code of 404 Not found is returned.
	*/
	public deleteDockLocation(
							   pathParams: DeleteDockLocationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/dock-locations/{dockInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation retrieves a dock location for a valid dock location instance ID that is provided.
	* <br/>
	* <br/>Use Case 1		
	* <br/>Pre-condition: A valid dock location instance ID is provided.
	* <br/>Post-condition: Dock location details are returned.
	* <br/>
	* <br/>Use Case 2
	* <br/>Pre-condition: An invalid dock location instance ID is supplied
	* <br/>Post-condition: A status code of 404 Not found is returned.
	*/
	public getDockLocation(
							   pathParams: GetDockLocationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetDockLocationResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/dock-locations/{dockInstId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will return a list of all the dock locations for the SIC provided. The SIC must be active and a linehaul SIC.
	* <br/>
	* <br/>Use Case 1		
	* <br/>Pre-condition: A valid SIC and no optional parameters are provided.
	* <br/>Post-condition: A list of all the dock locations at the SIC is retrieved. A status code of 200 is returned.
	* <br/>
	* <br/>Use Case 2
	* <br/>Pre-condition: A valid SIC and dock name are provided.
	* <br/>Post-condition: A dock location with matching dock name is returned, if one exists. Otherwise a 404 - Not found is returned.
	*/
	public listDockLocations(
							   queryParams: ListDockLocationsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListDockLocationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/dock-locations'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation updates an existing dock location by updating the remarks for the dock instance ID provided.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. A valid dockInstId is supplied along with the remarks to be updated.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then remarks for the dock location are updated.
	* <br/>2. Otherwise an appropriate error message is returned.
	* <br/>2.
	*/
	public updateDockLocationRemarks(request: UpdateDockLocationRemarksRqst,
							   pathParams: UpdateDockLocationRemarksPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateDockLocationRemarksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/dock-locations/{dockInstId}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation accepts the unique five character LTL employee ID, SIC, and shift. It returns their current Dock Engineered Standard (DES) % from SMART.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid employee ID, SIC, and shift must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the employee has a current DES% in SMART, then it is returned.
	* <br/>2. Otherwise, an appropriate error message is returned.
	*/
	public getCurrentDockEngineeredStandard(
							   pathParams: GetCurrentDockEngineeredStandardPath,
							   queryParams: GetCurrentDockEngineeredStandardQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCurrentDockEngineeredStandardResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/employees/{employeeId}/des'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation accepts the unique five character LTL employee ID, SIC, and shift. It returns their current motor moves per hour (MMPH) from SMART if the shift code is a dock shift or the hostling moves per hour if the shift code is H - Hostling.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid Employee ID, SIC, and shift must be provided.
	* <br/>
	* <br/>Post-Conditions:
	* <br/>1. If the employee has current motor moves per hour in SMART or hostling moves if shiftCd is Hostling, then they are returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public getCurrentMotorMovesPerHour(
							   pathParams: GetCurrentMotorMovesPerHourPath,
							   queryParams: GetCurrentMotorMovesPerHourQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCurrentMotorMovesPerHourResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/employees/{employeeId}/motor-moves-per-hour'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get Smart Token to make Query Call
	*/
	public getSmartApiToken(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSmartApiTokenResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/smart-api/token'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation accepts a SIC and door number. If there is a trailer at the door, then it returns details for the trailer.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid SIC and door number must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If there is a trailer at the door, then trailer details are returned.
	* <br/>2. If there is no trailer at the door, then an empty response is returned.
	* <br/>3. If the door doesn't exist, an error will be returned.
	*/
	public getTrailerDetailsByDoor(
							   pathParams: GetTrailerDetailsByDoorPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetTrailerDetailsByDoorResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/doors/{doorNbr}/trailer-details'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation performs a bulk update of the trailer load status, door and/or remarks. When updating the trailer load status, if the new trailer load status change is not allowed, an error message will be returned. Trailer remarks can be updated to blanks.
	* <br/>
	* <br/>Mandatory Input: SIC and trailer equipment ID. 
	* <br/>The equipment ID must be for a straight truck or trailer.
	* <br/>
	* <br/>Optional Input: shiftCd, newTrailerStatus,doorNbr, trailerRemarks, eventTimestamp, and overheadStatusOverrideInd.
	* <br/>If eventTimestamp is not supplied, then the current date and time will be used.
	* <br/>When the current trailer status is Overhead and the new trailer status is an invalid status change from the previous status, then the status update is only allowed when overheadStatusOverrideInd is set to true.
	* <br/>
	* <br/>Use Case 1
	* <br/>Pre-condition: A valid trailer equipmentId along with newTrailerStatus, doorNbr, and trailerRemarks is provided. An update to newTrailerStatus is allowed.
	* <br/>Post-condition: The trailer load is updated with the input provided.
	* <br/>
	* <br/>Use Case 2
	* <br/>Pre-condition: A valid trailer equipmentId along with doorNbr and trailerRemarks is provided.
	* <br/>Post-condition: The trailer load is updated with doorNbr and trailerRemarks.
	* <br/>
	* <br/>Use Case 3
	* <br/>Pre-condition: A valid trailer equipment ID along with either one of the following is provided: newTrailerStatus, doorNbr, or trailerRemarks.
	* <br/>Post-condition: A trailer load is updated with the input provided.
	* <br/>
	* <br/>Use Case 4
	* <br/>Pre-condition: A valid trailer equipmentId is supplied along with newTrailerStatus. However status change is not allowed.
	* <br/>Post-condition: A 400 - bad request error message is returned. Message: Trailer load status update not allowed.
	* <br/>
	* <br/>Use Case 5
	* <br/>Pre-condition: A valid trailer equipmentId is supplied along with newTrailerStatus, but trailer is associated with an Ops Schedule.
	* <br/>Post-condition: A 400 - bad request error message is returned. Message: Trailer is associated with a Schedule.
	*/
	public bulkUpdateTrailerLoadStatusDoorRemarks(request: BulkUpdateTrailerLoadStatusDoorRemarksRqst,
							   pathParams: BulkUpdateTrailerLoadStatusDoorRemarksPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<BulkUpdateTrailerLoadStatusDoorRemarksResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/trailer-loads/status-door-remarks'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* For the SIC and shift provided, this operation will return a list of trailers to break along with their door information.  It will also get the head load information and if the trailer is not assigned to a door, then it will get the door and sector from Step Saver. If the trailer status is UNLD, then it will return the DSR Name and time of the last unloaded shipment from the trailer.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid SIC and shift must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of trailers to break along with other information is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listBreakTrailers(
							   pathParams: ListBreakTrailersPath,
							   queryParams: ListBreakTrailersQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListBreakTrailersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/shifts/{shiftCd}/break-trailers'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will return a list of reship freight trailers, for the SIC and shift provided, that are included or excluded from the X-Dock plan. A list of trailers will be displayed with information like trailer number, load destination, action to be taken on the trailer, current status, and location.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid SIC and shift must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of reship freight trailers is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public listReshipFreightTrailers(
							   pathParams: ListReshipFreightTrailersPath,
							   queryParams: ListReshipFreightTrailersQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListReshipFreightTrailersResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/shifts/{shiftCd}/reship-trailers'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will return a list of all the XDock shipments that are included in a dock plan. The list can be filtered by shipment type: Reship, Pickup, Strand or Dock.
	* <br/>Other optional filter criteria include: one or more (up to three) xdock progress codes (Not Stripped, Stripped, TConned), destination sic code of shipments, sector number of door or FAC, and door number.
	* <br/>		
	* <br/>Pre-condition:
	* <br/>1. A valid SIC and shift must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of Xdock shipments is returned along with a status code of 200.
	* <br/>2. If the XDock plan is not valid, then a status code of 400 will be returned.
	* <br/>3. If no shipments are found in the plan, then a status code of 404 will be returned.
	*/
	public listShipmentsInDockPlan(
							   pathParams: ListShipmentsInDockPlanPath,
							   queryParams: ListShipmentsInDockPlanQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListShipmentsInDockPlanResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/shifts/{shiftCd}/planned-shipments'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation will remove one or more trailers that are included in a Dock Plan (SIC, shift, and plan date).
	* <br/>		
	* <br/>Pre-conditions:
	* <br/>1. A valid SIC and shift must be provided along with at least one trailer ID.
	* <br/>2. The plan date is optional.  If it isn't provided, then the current date will be used.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If all trailers are successfully removed from the dock plan, then a success status code will be returned.
	* <br/>2. If any of the input trailers are not part of the dock plan, then a 400 status code will be returned.
	* <br/>3. If there is an error in removing one or more trailers from the plan, then the response will indicate the trailer equipment ID along with the error message.
	*/
	public removeTrailersFromDockPlan(request: RemoveTrailersFromDockPlanRqst,
							   pathParams: RemoveTrailersFromDockPlanPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RemoveTrailersFromDockPlanResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/shifts/{shiftCd}/planning-trailers-removal'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation either assigns or unassigns a DSR from breaking a trailer.
	* <br/>
	* <br/>Mandatory Input: Equipment ID of the trailer and DSR Employee ID.
	* <br/>Optional Input: Trailer equipment prefix and suffix. Either the assignment startDateTime OR assignment endDateTime is provided.
	* <br/>
	* <br/>Use Case 1
	* <br/>Pre-condition: A valid trailer equipment ID along with DSR employee ID and startDateTime are provided.
	* <br/>Post-condition: The DSR is assigned to break the trailer with the equipment ID.
	* <br/>
	* <br/>Use Case 2
	* <br/>Pre-condition: A valid trailer equipment ID along with DSR employee ID and endDateTime are provided.
	* <br/>Post-condition: The DSR is unassigned from breaking the trailer with the equipment ID.
	* <br/>
	* <br/>Use Case 3
	* <br/>Pre-condition: An invalid trailer equipment ID is provided.
	* <br/>Post-condition: A 404 - not found status code is returned.
	*/
	public updateTrailerDsrAssignment(request: UpdateTrailerDsrAssignmentRqst,
							   pathParams: UpdateTrailerDsrAssignmentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateTrailerDsrAssignmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/trailer-loads/{equipmentId}/dsr-assignments'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation will create a new Foreign Equipment for each item on the list of foreign equipment supplied. A Foreign Equipment is an Equipment (usually Trailer or Dolly or Power or Straight Truck) that is not owned by XPO LTL. 
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Mandatory data required for adding a foreign equipment includes: responsibleSicCd, foreignScacCd, equipmentNbr, equipmentTypeCd, and equipmentTypeDetailCd.
	* <br/>2. The responsibleSicCd must be a valid linehaul SIC and must be active as of the create date. 
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. Once the foreign equipment is successfully created, an equipment ID will be returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public addForeignEquipment(request: AddForeignEquipmentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<AddForeignEquipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/foreign-equipment'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation will perform a bulk update for the Equipment Status and/or Remark. The Equipment Status and Remark can be updated at any service center. These updates are only allowed when the SIC provided matches the Equipment's current SIC. The only status updates allowed are: CITY and Available. 
	* <br/>With this request for each Equipment, either Status or Remark or both can be updated. If only the status is being updated, then ensure that remark is not supplied and vice versa.
	* <br/>
	* <br/>
	* <br/>The following conditions represent one use case. The users can choose to update either one or both.
	* <br/>
	* <br/>Pre-condition 1: At least 1 EquipmentStatusRemark record is supplied with a valid and active Linehaul SIC and either Status or Remark or both are supplied.
	* <br/>Post-condition : Equipment Status Remark is updated as per input values.
	* <br/>
	* <br/>Pre-condition 2: Input Current Status = Available and Equipment can not be updated to new Status.
	* <br/>Post-condition 2: Error message is returned for this equipment.
	* <br/>
	* <br/>Pre-condition 3: Input Current Status = Available and Equipment can be updated to input status.
	* <br/>Post-condition 3: Equipment status is updated along with Status update timestamp.
	* <br/>
	* <br/>Pre-condition 4: Input Current Status = OutOfService.
	* <br/>Post-condition 4: Equipment status can not be updated to OutOfService. Error message associated with this Equipment is returned.
	* <br/>
	* <br/>Pre-condition 5: Input Current Status = CITY.
	* <br/>Post-condition 5:. Equipment status is updated along with Status update timestamp.
	* <br/>
	* <br/>Pre-condition 6: Input remarks to be updated are supplied. Remarks can be updated to "blanks".
	* <br/>Post-conditions 6: Equipment Remarks are updated.
	* <br/>
	*/
	public bulkUpdateEquipmentStatusRemark(request: BulkUpdateEquipmentStatusRemarkRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<BulkUpdateEquipmentStatusRemarkResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/equipment-status-remarks'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation creates an association between the forklift information and the employee that scans the forklift.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid SIC, employee ID, and forklift ID are required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a status code of 201 is returned along with the forklift ID.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public createForkliftScan(request: CreateForkliftScanRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateForkliftScanResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/forklift-scans'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation creates an association between the equipment information and the employee that scans and works on the equipment.
	* <br/>
	* <br/>Pre-condition: 
	* <br/>1. A valid SIC, employee ID, and equipment number are required.
	* <br/>
	* <br/>Post-conditions: 
	* <br/>1. If successful, then a status code of 201 is returned along with the equipment operator ID.
	* <br/>2. If the equipment type was provided, but it isn't associated with the equipment number provided, then a 404 not found is returned.
	* <br/>3. Otherwise an appropriate error message is returned.
	* <br/>
	*/
	public createEquipmentScan(request: CreateEquipmentScanRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateEquipmentScanResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/equipment-scans'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation returns the most recent forklift information associated with the requested employee.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid employee ID is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the employee has the most recent forklift scan information, then it is returned.
	* <br/>2. If the employee doesn't have any forklift scans recorded, then a 404 - record not found is returned.
	* <br/>3. Otherwise an appropriate error message is returned.
	*/
	public getForkliftScanByEmployee(
							   pathParams: GetForkliftScanByEmployeePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetForkliftScanByEmployeeResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/forklift-scans/{employeeId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* For the input sic code this operation will return a list of all the Equipment at the service center provided. The list can be filtered by following: equipment type, equipment eurrent status, from door number, to door number or a list of equipment numbers can be supplied.
	* <br/>
	* <br/>Use Case 1		
	* <br/>Pre-condition: A valid SIC and no optional parameters are supplied.
	* <br/>Post-condition: A list of all the Equipment at the input SIC is retrieved.
	* <br/>
	* <br/>Use Case 2
	* <br/>Pre-condition: A valid SIC and list of Equipment numbers are supplied.
	* <br/>Post-condition: A list of Equipment with matching input Equipment number is retrieved. If an Equipment is not at that service center, then a message 'Equipment not found at the sic' is returned.
	* <br/>
	* <br/>Use Case 3
	* <br/>Pre-condition: A valid SIC and Equipment current status is supplied.
	* <br/>Post-condition: A list of Equipment with matching input Equipment status is returned.
	* <br/>
	* <br/>Use Case 4
	* <br/>Pre-condition: A valid SIC and Equipment type are supplied.
	* <br/>Post-condition: A list of Equipment with matching input Equipment type is returned.
	* <br/>
	* <br/>Use Case 5
	* <br/>Pre-condition: A valid SIC and from door and to door is supplied.
	* <br/>Post-condition: A list of Equipment that are at the doors specified in the input door range is returned.
	* <br/>
	*/
	public listEquipmentBySic(
							   pathParams: ListEquipmentBySicPath,
							   queryParams: ListEquipmentBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListEquipmentBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}/equipment'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(DockOperationsApiService.DockOperationsApiEndpoint);
	}
}
