import {CorrectionStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class CorrectionStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CorrectionStatusCd ) {
      this._values.push(CorrectionStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CorrectionStatusCd {

    for ( const obj in CorrectionStatusCd ) {
      if (CorrectionStatusCd[obj] === value){
        return CorrectionStatusCd[obj] as CorrectionStatusCd;
      }
    }
  }
}

const CorrectionStatusCdHelper = new CorrectionStatusCdHelperEnumHelperClass();
export default CorrectionStatusCdHelper;
