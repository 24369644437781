import {CommodityClassCd} from '../';
import {EnumHelper} from './enum-helper';

export class CommodityClassCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CommodityClassCd ) {
      this._values.push(CommodityClassCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CommodityClassCd {

    for ( const obj in CommodityClassCd ) {
      if (CommodityClassCd[obj] === value){
        return CommodityClassCd[obj] as CommodityClassCd;
      }
    }
  }
}

const CommodityClassCdHelper = new CommodityClassCdHelperEnumHelperClass();
export default CommodityClassCdHelper;
