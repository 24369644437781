
export enum ShipmentRemarkTypeCd {
	ADHOC_HZ_MATL_RMK = 'AdhocHzMatlRmk',
	AUTHORITY_LN_TXT_RMK = 'AuthorityLnTxtRmk',
	DLVRY_ATTM_RMK = 'DlvryAttmRmk',
	OPRATNL_FRT_HDLNG_RMK = 'OpratnlFrtHdlngRmk',
	OSD_CNTCT = 'OsdCntct',
	PIECE_CNT_RMK = 'PieceCntRmk',
	SHIPPING_RMK = 'ShippingRmk'}

