import {ClaimPartyTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ClaimPartyTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ClaimPartyTypeCd ) {
      this._values.push(ClaimPartyTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ClaimPartyTypeCd {

    for ( const obj in ClaimPartyTypeCd ) {
      if (ClaimPartyTypeCd[obj] === value){
        return ClaimPartyTypeCd[obj] as ClaimPartyTypeCd;
      }
    }
  }
}

const ClaimPartyTypeCdHelper = new ClaimPartyTypeCdHelperEnumHelperClass();
export default ClaimPartyTypeCdHelper;
