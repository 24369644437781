import {DamageTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DamageTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DamageTypeCd ) {
      this._values.push(DamageTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DamageTypeCd {

    for ( const obj in DamageTypeCd ) {
      if (DamageTypeCd[obj] === value){
        return DamageTypeCd[obj] as DamageTypeCd;
      }
    }
  }
}

const DamageTypeCdHelper = new DamageTypeCdHelperEnumHelperClass();
export default DamageTypeCdHelper;
