import {DisputeEventTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeEventTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeEventTypeCd ) {
      this._values.push(DisputeEventTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeEventTypeCd {

    for ( const obj in DisputeEventTypeCd ) {
      if (DisputeEventTypeCd[obj] === value){
        return DisputeEventTypeCd[obj] as DisputeEventTypeCd;
      }
    }
  }
}

const DisputeEventTypeCdHelper = new DisputeEventTypeCdHelperEnumHelperClass();
export default DisputeEventTypeCdHelper;
