import {ContactRoleCd} from '../';
import {EnumHelper} from './enum-helper';

export class ContactRoleCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ContactRoleCd ) {
      this._values.push(ContactRoleCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ContactRoleCd {

    for ( const obj in ContactRoleCd ) {
      if (ContactRoleCd[obj] === value){
        return ContactRoleCd[obj] as ContactRoleCd;
      }
    }
  }
}

const ContactRoleCdHelper = new ContactRoleCdHelperEnumHelperClass();
export default ContactRoleCdHelper;
