import {TripStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class TripStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TripStatusCd ) {
      this._values.push(TripStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TripStatusCd {

    for ( const obj in TripStatusCd ) {
      if (TripStatusCd[obj] === value){
        return TripStatusCd[obj] as TripStatusCd;
      }
    }
  }
}

const TripStatusCdHelper = new TripStatusCdHelperEnumHelperClass();
export default TripStatusCdHelper;
