import {ServiceLevelPartyTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ServiceLevelPartyTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ServiceLevelPartyTypeCd ) {
      this._values.push(ServiceLevelPartyTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ServiceLevelPartyTypeCd {

    for ( const obj in ServiceLevelPartyTypeCd ) {
      if (ServiceLevelPartyTypeCd[obj] === value){
        return ServiceLevelPartyTypeCd[obj] as ServiceLevelPartyTypeCd;
      }
    }
  }
}

const ServiceLevelPartyTypeCdHelper = new ServiceLevelPartyTypeCdHelperEnumHelperClass();
export default ServiceLevelPartyTypeCdHelper;
