import {CssClassCd} from '../';
import {EnumHelper} from './enum-helper';

export class CssClassCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CssClassCd ) {
      this._values.push(CssClassCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CssClassCd {

    for ( const obj in CssClassCd ) {
      if (CssClassCd[obj] === value){
        return CssClassCd[obj] as CssClassCd;
      }
    }
  }
}

const CssClassCdHelper = new CssClassCdHelperEnumHelperClass();
export default CssClassCdHelper;
