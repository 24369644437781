import {PayformNoteTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PayformNoteTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PayformNoteTypeCd ) {
      this._values.push(PayformNoteTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PayformNoteTypeCd {

    for ( const obj in PayformNoteTypeCd ) {
      if (PayformNoteTypeCd[obj] === value){
        return PayformNoteTypeCd[obj] as PayformNoteTypeCd;
      }
    }
  }
}

const PayformNoteTypeCdHelper = new PayformNoteTypeCdHelperEnumHelperClass();
export default PayformNoteTypeCdHelper;
