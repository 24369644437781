import {InvoiceRequirementCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceRequirementCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceRequirementCategoryCd ) {
      this._values.push(InvoiceRequirementCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceRequirementCategoryCd {

    for ( const obj in InvoiceRequirementCategoryCd ) {
      if (InvoiceRequirementCategoryCd[obj] === value){
        return InvoiceRequirementCategoryCd[obj] as InvoiceRequirementCategoryCd;
      }
    }
  }
}

const InvoiceRequirementCategoryCdHelper = new InvoiceRequirementCategoryCdHelperEnumHelperClass();
export default InvoiceRequirementCategoryCdHelper;
