import {ScheduleStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ScheduleStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ScheduleStatusCd ) {
      this._values.push(ScheduleStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ScheduleStatusCd {

    for ( const obj in ScheduleStatusCd ) {
      if (ScheduleStatusCd[obj] === value){
        return ScheduleStatusCd[obj] as ScheduleStatusCd;
      }
    }
  }
}

const ScheduleStatusCdHelper = new ScheduleStatusCdHelperEnumHelperClass();
export default ScheduleStatusCdHelper;
