import {RouteStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class RouteStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RouteStatusCd ) {
      this._values.push(RouteStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RouteStatusCd {

    for ( const obj in RouteStatusCd ) {
      if (RouteStatusCd[obj] === value){
        return RouteStatusCd[obj] as RouteStatusCd;
      }
    }
  }
}

const RouteStatusCdHelper = new RouteStatusCdHelperEnumHelperClass();
export default RouteStatusCdHelper;
