import {DeltaSignCd} from '../';
import {EnumHelper} from './enum-helper';

export class DeltaSignCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DeltaSignCd ) {
      this._values.push(DeltaSignCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DeltaSignCd {

    for ( const obj in DeltaSignCd ) {
      if (DeltaSignCd[obj] === value){
        return DeltaSignCd[obj] as DeltaSignCd;
      }
    }
  }
}

const DeltaSignCdHelper = new DeltaSignCdHelperEnumHelperClass();
export default DeltaSignCdHelper;
