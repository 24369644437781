import {WorkflowSelectionModeCd} from '../';
import {EnumHelper} from './enum-helper';

export class WorkflowSelectionModeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in WorkflowSelectionModeCd ) {
      this._values.push(WorkflowSelectionModeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): WorkflowSelectionModeCd {

    for ( const obj in WorkflowSelectionModeCd ) {
      if (WorkflowSelectionModeCd[obj] === value){
        return WorkflowSelectionModeCd[obj] as WorkflowSelectionModeCd;
      }
    }
  }
}

const WorkflowSelectionModeCdHelper = new WorkflowSelectionModeCdHelperEnumHelperClass();
export default WorkflowSelectionModeCdHelper;
