import {InvoiceDocumentCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvoiceDocumentCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvoiceDocumentCd ) {
      this._values.push(InvoiceDocumentCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvoiceDocumentCd {

    for ( const obj in InvoiceDocumentCd ) {
      if (InvoiceDocumentCd[obj] === value){
        return InvoiceDocumentCd[obj] as InvoiceDocumentCd;
      }
    }
  }
}

const InvoiceDocumentCdHelper = new InvoiceDocumentCdHelperEnumHelperClass();
export default InvoiceDocumentCdHelper;
