import {EmailFormatCd} from '../';
import {EnumHelper} from './enum-helper';

export class EmailFormatCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EmailFormatCd ) {
      this._values.push(EmailFormatCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EmailFormatCd {

    for ( const obj in EmailFormatCd ) {
      if (EmailFormatCd[obj] === value){
        return EmailFormatCd[obj] as EmailFormatCd;
      }
    }
  }
}

const EmailFormatCdHelper = new EmailFormatCdHelperEnumHelperClass();
export default EmailFormatCdHelper;
