import {DisputeRequestReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeRequestReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeRequestReasonCd ) {
      this._values.push(DisputeRequestReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeRequestReasonCd {

    for ( const obj in DisputeRequestReasonCd ) {
      if (DisputeRequestReasonCd[obj] === value){
        return DisputeRequestReasonCd[obj] as DisputeRequestReasonCd;
      }
    }
  }
}

const DisputeRequestReasonCdHelper = new DisputeRequestReasonCdHelperEnumHelperClass();
export default DisputeRequestReasonCdHelper;
