
export enum CorrectionStatusCd {
	APR = 'APR',
	APY = 'APY',
	DCL = 'DCL',
	LCK = 'LCK',
	MAP = 'MAP',
	OAP = 'OAP',
	PND = 'PND',
	SAV = 'SAV'}

