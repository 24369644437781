import {DisputeNoteTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeNoteTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeNoteTypeCd ) {
      this._values.push(DisputeNoteTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeNoteTypeCd {

    for ( const obj in DisputeNoteTypeCd ) {
      if (DisputeNoteTypeCd[obj] === value){
        return DisputeNoteTypeCd[obj] as DisputeNoteTypeCd;
      }
    }
  }
}

const DisputeNoteTypeCdHelper = new DisputeNoteTypeCdHelperEnumHelperClass();
export default DisputeNoteTypeCdHelper;
