import {InvPaymentChrgToCd} from '../';
import {EnumHelper} from './enum-helper';

export class InvPaymentChrgToCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in InvPaymentChrgToCd ) {
      this._values.push(InvPaymentChrgToCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): InvPaymentChrgToCd {

    for ( const obj in InvPaymentChrgToCd ) {
      if (InvPaymentChrgToCd[obj] === value){
        return InvPaymentChrgToCd[obj] as InvPaymentChrgToCd;
      }
    }
  }
}

const InvPaymentChrgToCdHelper = new InvPaymentChrgToCdHelperEnumHelperClass();
export default InvPaymentChrgToCdHelper;
