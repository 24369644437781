
export enum DynamicPriceQualifierTypeCd {
	COUNTRY = 'Country',
	DISTRICT = 'District',
	LOCATION = 'Location',
	REGION = 'Region',
	SIC = 'SIC',
	STATE = 'State',
	ZIP_CODE = 'ZipCode'}

