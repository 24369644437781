
export enum CommodityPackageCd {
	AMMO_PACK = 'AmmoPack',
	ATTM = 'Attm',
	BAG = 'Bag',
	BALE = 'Bale',
	BANDING = 'Banding',
	BARGE = 'Barge',
	BARREL = 'Barrel',
	BASKET = 'Basket',
	BEAM = 'Beam',
	BELTING = 'Belting',
	BIN = 'Bin',
	BING_CHEST = 'BingChest',
	BOBBIN = 'Bobbin',
	BOTTLE = 'Bottle',
	BOX = 'Box',
	BOX_WITH_CONTAINER = 'BoxWithContainer',
	BRACING = 'Bracing',
	BUCKET = 'Bucket',
	BULK = 'Bulk',
	BULK_CARGO = 'BulkCargo',
	BUNDLE = 'Bundle',
	CABINET = 'Cabinet',
	CAGE = 'Cage',
	CAN = 'Can',
	CAN_CASE = 'CanCase',
	CARBOY = 'Carboy',
	CARR = 'Carr',
	CARTON = 'Carton',
	CAR_LOAD_RAIL = 'CarLoadRail',
	CASE = 'Case',
	CASK = 'Cask',
	CHEESES = 'Cheeses',
	CHEST = 'Chest',
	COIL = 'Coil',
	CONES = 'Cones',
	CONTAINER = 'Container',
	CORE = 'Core',
	CORNER_REINFORCEMENT = 'CornerReinforcement',
	CRADLE = 'Cradle',
	CRATE = 'Crate',
	CYLINDER = 'Cylinder',
	DOUBLE_RACK = 'DoubleRack',
	DOUBLE_SKID = 'DoubleSkid',
	DOUBLE_TOTE_BIN = 'DoubleToteBin',
	DRUM = 'Drum',
	DRY_BULK = 'DryBulk',
	EDGE_PROTECTION = 'EdgeProtection',
	EGG_CRATING = 'EggCrating',
	ENVELOPE = 'Envelope',
	FIRKIN = 'Firkin',
	FLASK = 'Flask',
	FLO_BIN = 'FloBin',
	FORWARD_REEL = 'ForwardReel',
	FRAME = 'Frame',
	HALF_RACK = 'HalfRack',
	HALF_TOTE_BIN = 'HalfToteBin',
	HANGER_RACK = 'HangerRack',
	HEADS_BEEF = 'HeadsBeef',
	HOGSHEAD = 'Hogshead',
	HOPPER_TRUCK = 'HopperTruck',
	INT_BULK_CTR = 'IntBulkCtr',
	JAR = 'Jar',
	JERRYCAN = 'Jerrycan',
	JUG = 'Jug',
	KEG = 'Keg',
	KIT = 'Kit',
	KNOCKDOWN_RACK = 'KnockdownRack',
	KNOCKDOWN_TOTE_BIN = 'KnockdownToteBin',
	LID_TOP = 'LidTop',
	LIFTS = 'Lifts',
	LIFT_VAN = 'LiftVan',
	LINERS = 'Liners',
	LOG = 'Log',
	LOOSE = 'Loose',
	LQD_BULK = 'LqdBulk',
	LUG = 'Lug',
	MISCELLANEOUS = 'Miscellaneous',
	MIXED_CONTAINER = 'MixedContainer',
	MULTI_RACK = 'MultiRack',
	NOIL = 'Noil',
	ON_OWN_WHEEL = 'OnOwnWheel',
	PACKED = 'Packed',
	PAIL = 'Pail',
	PALLET = 'Pallet',
	PALLET_2_WAY = 'Pallet2Way',
	PALLET_4_WAY = 'Pallet4Way',
	PARTIONING = 'Partioning',
	PCS = 'Pcs',
	PIMS = 'Pims',
	PIPELINE = 'Pipeline',
	PIPE_RACK = 'PipeRack',
	PKG = 'Pkg',
	PLATFORM = 'Platform',
	PRIMARY_LIFT_CONTAINER = 'PrimaryLiftContainer',
	PRIVATE_VEHCLE = 'PrivateVehcle',
	QUARTER_BEEF = 'QuarterBeef',
	RACK = 'Rack',
	RAIL_SEMICONDUCTOR = 'RailSemiconductor',
	REEL = 'Reel',
	REINFORCEMNT = 'Reinforcemnt',
	REVERSE_REEL = 'ReverseReel',
	ROLL = 'Roll',
	SACK = 'Sack',
	SEPARATOR = 'Separator',
	SHEET = 'Sheet',
	SHOOK = 'Shook',
	SHRINK_WRAP = 'ShrinkWrap',
	SIDE_OF_BEEF = 'SideOfBeef',
	SKID = 'Skid',
	SKID_ELEVATE = 'SkidElevate',
	SLEEVE = 'Sleeve',
	SLIP_SHEET = 'SlipSheet',
	SPIN_CYLINDERS = 'SpinCylinders',
	SPOOL = 'Spool',
	TANK = 'Tank',
	TANK_CAR = 'TankCar',
	TANK_TRUCK = 'TankTruck',
	TIERCE = 'Tierce',
	TOTE_BIN = 'ToteBin',
	TRAILER_CONTAINER_LOAD = 'TrailerContainerLoad',
	TRAY = 'Tray',
	TRUNK_CHEST = 'TrunkChest',
	TRUNK_SAMPLE = 'TrunkSample',
	TUB = 'Tub',
	TUBE = 'Tube',
	UNIT = 'Unit',
	UNPACKED = 'Unpacked',
	VAN_PACK = 'VanPack',
	VEHICLES = 'Vehicles',
	WHEELED_CAR = 'WheeledCar',
	WRAPPED = 'Wrapped'}

