import {UserRoleCd} from '../';
import {EnumHelper} from './enum-helper';

export class UserRoleCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in UserRoleCd ) {
      this._values.push(UserRoleCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): UserRoleCd {

    for ( const obj in UserRoleCd ) {
      if (UserRoleCd[obj] === value){
        return UserRoleCd[obj] as UserRoleCd;
      }
    }
  }
}

const UserRoleCdHelper = new UserRoleCdHelperEnumHelperClass();
export default UserRoleCdHelper;
