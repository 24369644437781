import {FinalStandardAddressSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class FinalStandardAddressSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in FinalStandardAddressSourceCd ) {
      this._values.push(FinalStandardAddressSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): FinalStandardAddressSourceCd {

    for ( const obj in FinalStandardAddressSourceCd ) {
      if (FinalStandardAddressSourceCd[obj] === value){
        return FinalStandardAddressSourceCd[obj] as FinalStandardAddressSourceCd;
      }
    }
  }
}

const FinalStandardAddressSourceCdHelper = new FinalStandardAddressSourceCdHelperEnumHelperClass();
export default FinalStandardAddressSourceCdHelper;
