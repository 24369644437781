import {TripCategoryCd} from '../';
import {EnumHelper} from './enum-helper';

export class TripCategoryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TripCategoryCd ) {
      this._values.push(TripCategoryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TripCategoryCd {

    for ( const obj in TripCategoryCd ) {
      if (TripCategoryCd[obj] === value){
        return TripCategoryCd[obj] as TripCategoryCd;
      }
    }
  }
}

const TripCategoryCdHelper = new TripCategoryCdHelperEnumHelperClass();
export default TripCategoryCdHelper;
