import {ApiThrottlingTierCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApiThrottlingTierCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApiThrottlingTierCd ) {
      this._values.push(ApiThrottlingTierCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApiThrottlingTierCd {

    for ( const obj in ApiThrottlingTierCd ) {
      if (ApiThrottlingTierCd[obj] === value){
        return ApiThrottlingTierCd[obj] as ApiThrottlingTierCd;
      }
    }
  }
}

const ApiThrottlingTierCdHelper = new ApiThrottlingTierCdHelperEnumHelperClass();
export default ApiThrottlingTierCdHelper;
