import {RebuttalReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class RebuttalReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RebuttalReasonCd ) {
      this._values.push(RebuttalReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RebuttalReasonCd {

    for ( const obj in RebuttalReasonCd ) {
      if (RebuttalReasonCd[obj] === value){
        return RebuttalReasonCd[obj] as RebuttalReasonCd;
      }
    }
  }
}

const RebuttalReasonCdHelper = new RebuttalReasonCdHelperEnumHelperClass();
export default RebuttalReasonCdHelper;
