import {CouponCd} from '../';
import {EnumHelper} from './enum-helper';

export class CouponCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CouponCd ) {
      this._values.push(CouponCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CouponCd {

    for ( const obj in CouponCd ) {
      if (CouponCd[obj] === value){
        return CouponCd[obj] as CouponCd;
      }
    }
  }
}

const CouponCdHelper = new CouponCdHelperEnumHelperClass();
export default CouponCdHelper;
