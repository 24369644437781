import {RuleOverrideReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class RuleOverrideReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RuleOverrideReasonCd ) {
      this._values.push(RuleOverrideReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RuleOverrideReasonCd {

    for ( const obj in RuleOverrideReasonCd ) {
      if (RuleOverrideReasonCd[obj] === value){
        return RuleOverrideReasonCd[obj] as RuleOverrideReasonCd;
      }
    }
  }
}

const RuleOverrideReasonCdHelper = new RuleOverrideReasonCdHelperEnumHelperClass();
export default RuleOverrideReasonCdHelper;
