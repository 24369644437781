import {PolygonTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PolygonTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PolygonTypeCd ) {
      this._values.push(PolygonTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PolygonTypeCd {

    for ( const obj in PolygonTypeCd ) {
      if (PolygonTypeCd[obj] === value){
        return PolygonTypeCd[obj] as PolygonTypeCd;
      }
    }
  }
}

const PolygonTypeCdHelper = new PolygonTypeCdHelperEnumHelperClass();
export default PolygonTypeCdHelper;
