import {ManifestReportTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ManifestReportTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ManifestReportTypeCd ) {
      this._values.push(ManifestReportTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ManifestReportTypeCd {

    for ( const obj in ManifestReportTypeCd ) {
      if (ManifestReportTypeCd[obj] === value){
        return ManifestReportTypeCd[obj] as ManifestReportTypeCd;
      }
    }
  }
}

const ManifestReportTypeCdHelper = new ManifestReportTypeCdHelperEnumHelperClass();
export default ManifestReportTypeCdHelper;
