import {DwellSourceCd} from '../';
import {EnumHelper} from './enum-helper';

export class DwellSourceCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DwellSourceCd ) {
      this._values.push(DwellSourceCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DwellSourceCd {

    for ( const obj in DwellSourceCd ) {
      if (DwellSourceCd[obj] === value){
        return DwellSourceCd[obj] as DwellSourceCd;
      }
    }
  }
}

const DwellSourceCdHelper = new DwellSourceCdHelperEnumHelperClass();
export default DwellSourceCdHelper;
