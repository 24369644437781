import {ShipmentOnTimeStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentOnTimeStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentOnTimeStatusCd ) {
      this._values.push(ShipmentOnTimeStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentOnTimeStatusCd {

    for ( const obj in ShipmentOnTimeStatusCd ) {
      if (ShipmentOnTimeStatusCd[obj] === value){
        return ShipmentOnTimeStatusCd[obj] as ShipmentOnTimeStatusCd;
      }
    }
  }
}

const ShipmentOnTimeStatusCdHelper = new ShipmentOnTimeStatusCdHelperEnumHelperClass();
export default ShipmentOnTimeStatusCdHelper;
