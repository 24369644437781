
export enum CubicFeetProfileMthdCd {
	ACTUAL_DIMENSIONS_CAPTURED_BY_DRIVER = 'Actual Dimensions captured by driver',
	AVERAGE_SHIPMENT_VOLUME_FOR_ZIP = 'Average Shipment Volume for Zip',
	DEFAULT_SHIPPER_PROFILE = 'Default shipper profile',
	DESTINATION_BASED_SHIPPER_PROFILE = 'Destination based shipper profile',
	SERVICE_CENTER_PROFILE = 'Service center profile',
	SYSTEM_PROFILE = 'System profile',
	UNDEFINED = 'Undefined'}

