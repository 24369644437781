import {WeightUomCd} from '../';
import {EnumHelper} from './enum-helper';

export class WeightUomCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in WeightUomCd ) {
      this._values.push(WeightUomCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): WeightUomCd {

    for ( const obj in WeightUomCd ) {
      if (WeightUomCd[obj] === value){
        return WeightUomCd[obj] as WeightUomCd;
      }
    }
  }
}

const WeightUomCdHelper = new WeightUomCdHelperEnumHelperClass();
export default WeightUomCdHelper;
