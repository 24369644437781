
export enum EnsembleExecutionStatusCd {
	COMPLETED = 'Completed',
	FAILED = 'Failed',
	KILLED = 'Killed',
	NOT_IMPLEMENTED = 'NotImplemented',
	NOT_STARTED = 'NotStarted',
	STARTED = 'Started',
	SUSPENDED = 'Suspended'}

