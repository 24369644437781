import {TripDetailCd} from '../';
import {EnumHelper} from './enum-helper';

export class TripDetailCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TripDetailCd ) {
      this._values.push(TripDetailCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TripDetailCd {

    for ( const obj in TripDetailCd ) {
      if (TripDetailCd[obj] === value){
        return TripDetailCd[obj] as TripDetailCd;
      }
    }
  }
}

const TripDetailCdHelper = new TripDetailCdHelperEnumHelperClass();
export default TripDetailCdHelper;
