import {DockShipmentProgressCd} from '../';
import {EnumHelper} from './enum-helper';

export class DockShipmentProgressCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DockShipmentProgressCd ) {
      this._values.push(DockShipmentProgressCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DockShipmentProgressCd {

    for ( const obj in DockShipmentProgressCd ) {
      if (DockShipmentProgressCd[obj] === value){
        return DockShipmentProgressCd[obj] as DockShipmentProgressCd;
      }
    }
  }
}

const DockShipmentProgressCdHelper = new DockShipmentProgressCdHelperEnumHelperClass();
export default DockShipmentProgressCdHelper;
