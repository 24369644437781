import {BolPartyTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class BolPartyTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in BolPartyTypeCd ) {
      this._values.push(BolPartyTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): BolPartyTypeCd {

    for ( const obj in BolPartyTypeCd ) {
      if (BolPartyTypeCd[obj] === value){
        return BolPartyTypeCd[obj] as BolPartyTypeCd;
      }
    }
  }
}

const BolPartyTypeCdHelper = new BolPartyTypeCdHelperEnumHelperClass();
export default BolPartyTypeCdHelper;
