import {MatchedPartyTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class MatchedPartyTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MatchedPartyTypeCd ) {
      this._values.push(MatchedPartyTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MatchedPartyTypeCd {

    for ( const obj in MatchedPartyTypeCd ) {
      if (MatchedPartyTypeCd[obj] === value){
        return MatchedPartyTypeCd[obj] as MatchedPartyTypeCd;
      }
    }
  }
}

const MatchedPartyTypeCdHelper = new MatchedPartyTypeCdHelperEnumHelperClass();
export default MatchedPartyTypeCdHelper;
