import {MovementStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class MovementStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MovementStatusCd ) {
      this._values.push(MovementStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MovementStatusCd {

    for ( const obj in MovementStatusCd ) {
      if (MovementStatusCd[obj] === value){
        return MovementStatusCd[obj] as MovementStatusCd;
      }
    }
  }
}

const MovementStatusCdHelper = new MovementStatusCdHelperEnumHelperClass();
export default MovementStatusCdHelper;
