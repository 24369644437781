
export enum AppointmentNotificationStatCd {
	ACTIVE = 'Active',
	CANCELLED = 'Cancelled',
	DELIVERY_COMPLETED = 'DeliveryCompleted',
	INACTIVE = 'Inactive',
	NOT_NEEDED = 'NotNeeded',
	RESCHEDULED = 'Rescheduled',
	RETURN = 'Return'}

