import {CountryCd} from '../';
import {EnumHelper} from './enum-helper';

export class CountryCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CountryCd ) {
      this._values.push(CountryCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CountryCd {

    for ( const obj in CountryCd ) {
      if (CountryCd[obj] === value){
        return CountryCd[obj] as CountryCd;
      }
    }
  }
}

const CountryCdHelper = new CountryCdHelperEnumHelperClass();
export default CountryCdHelper;
