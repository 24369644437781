import {RateRequestSubTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RateRequestSubTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RateRequestSubTypeCd ) {
      this._values.push(RateRequestSubTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RateRequestSubTypeCd {

    for ( const obj in RateRequestSubTypeCd ) {
      if (RateRequestSubTypeCd[obj] === value){
        return RateRequestSubTypeCd[obj] as RateRequestSubTypeCd;
      }
    }
  }
}

const RateRequestSubTypeCdHelper = new RateRequestSubTypeCdHelperEnumHelperClass();
export default RateRequestSubTypeCdHelper;
