import {CommodityConditionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CommodityConditionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CommodityConditionTypeCd ) {
      this._values.push(CommodityConditionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CommodityConditionTypeCd {

    for ( const obj in CommodityConditionTypeCd ) {
      if (CommodityConditionTypeCd[obj] === value){
        return CommodityConditionTypeCd[obj] as CommodityConditionTypeCd;
      }
    }
  }
}

const CommodityConditionTypeCdHelper = new CommodityConditionTypeCdHelperEnumHelperClass();
export default CommodityConditionTypeCdHelper;
