import {EquipmentQualifierCd} from '../';
import {EnumHelper} from './enum-helper';

export class EquipmentQualifierCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in EquipmentQualifierCd ) {
      this._values.push(EquipmentQualifierCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): EquipmentQualifierCd {

    for ( const obj in EquipmentQualifierCd ) {
      if (EquipmentQualifierCd[obj] === value){
        return EquipmentQualifierCd[obj] as EquipmentQualifierCd;
      }
    }
  }
}

const EquipmentQualifierCdHelper = new EquipmentQualifierCdHelperEnumHelperClass();
export default EquipmentQualifierCdHelper;
