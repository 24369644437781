
export enum RebuttalReasonCd {
	ADD_DOCUMENTS = 'AddDocuments',
	AMENDING_CLAIM_AMOUNT = 'AmendingClaimAmount',
	CONCEALED_DAMAGE = 'ConcealedDamage',
	CONTRACT_DISCREPANCY = 'ContractDiscrepancy',
	FREIGHT_CHARGES = 'FreightCharges',
	OTHER = 'Other',
	POLICY_PAY = 'PolicyPay'}

