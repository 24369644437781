import {ConversationActionItemStatCd} from '../';
import {EnumHelper} from './enum-helper';

export class ConversationActionItemStatCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ConversationActionItemStatCd ) {
      this._values.push(ConversationActionItemStatCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ConversationActionItemStatCd {

    for ( const obj in ConversationActionItemStatCd ) {
      if (ConversationActionItemStatCd[obj] === value){
        return ConversationActionItemStatCd[obj] as ConversationActionItemStatCd;
      }
    }
  }
}

const ConversationActionItemStatCdHelper = new ConversationActionItemStatCdHelperEnumHelperClass();
export default ConversationActionItemStatCdHelper;
