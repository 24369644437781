import {TrailerAgendaItemActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class TrailerAgendaItemActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in TrailerAgendaItemActionCd ) {
      this._values.push(TrailerAgendaItemActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): TrailerAgendaItemActionCd {

    for ( const obj in TrailerAgendaItemActionCd ) {
      if (TrailerAgendaItemActionCd[obj] === value){
        return TrailerAgendaItemActionCd[obj] as TrailerAgendaItemActionCd;
      }
    }
  }
}

const TrailerAgendaItemActionCdHelper = new TrailerAgendaItemActionCdHelperEnumHelperClass();
export default TrailerAgendaItemActionCdHelper;
