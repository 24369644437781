import {CredStatCd} from '../';
import {EnumHelper} from './enum-helper';

export class CredStatCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CredStatCd ) {
      this._values.push(CredStatCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CredStatCd {

    for ( const obj in CredStatCd ) {
      if (CredStatCd[obj] === value){
        return CredStatCd[obj] as CredStatCd;
      }
    }
  }
}

const CredStatCdHelper = new CredStatCdHelperEnumHelperClass();
export default CredStatCdHelper;
