
export enum RefusedReasonCd {
	ARRIVED_TOO_EARLY = 'ArrivedTooEarly',
	ARRIVED_TOO_LATE = 'ArrivedTooLate',
	CONSIGNEE_REFUSED_COLLED = 'ConsigneeRefusedColled',
	CUSTOMER_WANTED_EARLIER = 'CustomerWantedEarlier',
	DID_NOT_ORDER = 'DidNotOrder',
	DUPLICATE_ORDER = 'DuplicateOrder',
	INSUFFICIENT_DELIVERY_TIME = 'InsufficientDeliveryTime',
	MANUFACTURERS_DEFECT = 'ManufacturersDefect',
	ORDER_CANCELLED = 'OrderCancelled',
	OTHER = 'Other',
	SENT_TO_WRONG_LOCATION = 'SentToWrongLocation',
	UNAUTHORIZED_RETURN = 'UnauthorizedReturn',
	WRONG_ORDER = 'WrongOrder'}

