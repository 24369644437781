import {DockShipmentListTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class DockShipmentListTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DockShipmentListTypeCd ) {
      this._values.push(DockShipmentListTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DockShipmentListTypeCd {

    for ( const obj in DockShipmentListTypeCd ) {
      if (DockShipmentListTypeCd[obj] === value){
        return DockShipmentListTypeCd[obj] as DockShipmentListTypeCd;
      }
    }
  }
}

const DockShipmentListTypeCdHelper = new DockShipmentListTypeCdHelperEnumHelperClass();
export default DockShipmentListTypeCdHelper;
