import {CommodityPackageCd} from '../';
import {EnumHelper} from './enum-helper';

export class CommodityPackageCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CommodityPackageCd ) {
      this._values.push(CommodityPackageCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CommodityPackageCd {

    for ( const obj in CommodityPackageCd ) {
      if (CommodityPackageCd[obj] === value){
        return CommodityPackageCd[obj] as CommodityPackageCd;
      }
    }
  }
}

const CommodityPackageCdHelper = new CommodityPackageCdHelperEnumHelperClass();
export default CommodityPackageCdHelper;
