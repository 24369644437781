import {CorrectionTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class CorrectionTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CorrectionTypeCd ) {
      this._values.push(CorrectionTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CorrectionTypeCd {

    for ( const obj in CorrectionTypeCd ) {
      if (CorrectionTypeCd[obj] === value){
        return CorrectionTypeCd[obj] as CorrectionTypeCd;
      }
    }
  }
}

const CorrectionTypeCdHelper = new CorrectionTypeCdHelperEnumHelperClass();
export default CorrectionTypeCdHelper;
