import {NotificationCd} from '../';
import {EnumHelper} from './enum-helper';

export class NotificationCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in NotificationCd ) {
      this._values.push(NotificationCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): NotificationCd {

    for ( const obj in NotificationCd ) {
      if (NotificationCd[obj] === value){
        return NotificationCd[obj] as NotificationCd;
      }
    }
  }
}

const NotificationCdHelper = new NotificationCdHelperEnumHelperClass();
export default NotificationCdHelper;
