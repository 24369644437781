import {DisputeReassignReasonCd} from '../';
import {EnumHelper} from './enum-helper';

export class DisputeReassignReasonCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in DisputeReassignReasonCd ) {
      this._values.push(DisputeReassignReasonCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): DisputeReassignReasonCd {

    for ( const obj in DisputeReassignReasonCd ) {
      if (DisputeReassignReasonCd[obj] === value){
        return DisputeReassignReasonCd[obj] as DisputeReassignReasonCd;
      }
    }
  }
}

const DisputeReassignReasonCdHelper = new DisputeReassignReasonCdHelperEnumHelperClass();
export default DisputeReassignReasonCdHelper;
