import {ShipmentCreditStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentCreditStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentCreditStatusCd ) {
      this._values.push(ShipmentCreditStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentCreditStatusCd {

    for ( const obj in ShipmentCreditStatusCd ) {
      if (ShipmentCreditStatusCd[obj] === value){
        return ShipmentCreditStatusCd[obj] as ShipmentCreditStatusCd;
      }
    }
  }
}

const ShipmentCreditStatusCdHelper = new ShipmentCreditStatusCdHelperEnumHelperClass();
export default ShipmentCreditStatusCdHelper;
