
export enum InvoicePresentationFormatCd {
	CSV = 'CSV',
	CSV_ACC = 'CSV-ACC',
	INDIVIDUAL = 'Individual',
	MANIFEST = 'Manifest',
	NO_ATTACHMENT = 'NoAttachment',
	PDF = 'PDF',
	PDF_IMAGE = 'PDF-IMAGE',
	STATEMENT = 'Statement',
	XML = 'XML'}

