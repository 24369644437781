import {ServiceStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ServiceStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ServiceStatusCd ) {
      this._values.push(ServiceStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ServiceStatusCd {

    for ( const obj in ServiceStatusCd ) {
      if (ServiceStatusCd[obj] === value){
        return ServiceStatusCd[obj] as ServiceStatusCd;
      }
    }
  }
}

const ServiceStatusCdHelper = new ServiceStatusCdHelperEnumHelperClass();
export default ServiceStatusCdHelper;
